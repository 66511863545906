import * as React from "react";
import Board from "react-trello";
import Theme from "../../../theme";
import { Stack, Paper, Typography, Box } from "@mui/material";
import NewCare from "../../cares/newCare/NewCare";
import CareCard from "../../../models/care/CareCard";
import { SearchBar } from "../../../views/SearchBar";

export default function NewPlanCares({
  cares, caresInPlan, user, onCaresChange
}) {

  const [items, setItems] = React.useState(cares);
  const [searched, setSearched] = React.useState("");

  const [openModal, setChangeOpenState] = React.useState(false);
  const [selectedCare, setSelectedCare] = React.useState(null);

  const [selectedItems, setSelectedItems] = React.useState(caresInPlan);

  const handleSearchedTextChanges = (env) => {
    setSearched(env.target.value);

    const filteredArray = cares.filter((item) => {
      return item.title.toLowerCase().includes(env.target.value.toLowerCase());
    });

    setItems([]);
    setItems(filteredArray == [] ? cares : filteredArray);
  };

  const handleLaneChange = ({ fromLaneId, toLaneId, cardId, index }) => {
    if (toLaneId === "lane1" && fromLaneId != "lane1") {
      let newSelectedItems = selectedItems;
      const selectedCare = items.find((item) => item.id === cardId)
      newSelectedItems.push(selectedCare)
      setSelectedItems(newSelectedItems);
    } else if (toLaneId === "lane2") {
      const restOfCards = selectedItems.splice(index, 1)
      setSelectedItems(restOfCards);
    }

    onCaresChange(selectedItems);
  };

  // MARK: - Care Modal

  const handleModalOpen = (care) => {
    setSelectedCare(care);
    setChangeOpenState(true);
  };

  const handleModalClose = () => {
    setChangeOpenState(false);
    setSelectedCare(null);
  };

  const data = {
    lanes: [
      {
        id: "lane1",
        title: "Cares in Plan",
        label: selectedItems.length == 0 ? "0" : selectedItems.length,
        style: {
          width: 260
        },
        cards: selectedItems.map((care) => new CareCard(care.id, care.title)),
      },
      {
        id: "lane2",
        title: "Available Cares",
        label: items.length == 0 ? "0" : items.length,
        style: {
          width: 260
        },
        cards: items.map((care) => new CareCard(care.id, care.title)),
      },
    ],
  };

  function DraggableCard(care) {
    return (
      <React.Fragment>
        <Box onClick={() => handleModalOpen(care)}>
          <Paper sx={{ my: 1, p: 2 }}>
            <Typography>{care.title}</Typography>
          </Paper>
        </Box>
      </React.Fragment>
    );
  }

  // MARK: - Views

  return (
    <React.Fragment>
      <NewCare
        care={selectedCare}
        open={openModal}
        close={handleModalClose}
        user={user}
      />

      <Stack
        sx={{
          mt: 2,
          py: 2,
        }}
      >
        <Typography variant="h7" fontWeight="medium" sx={{ mb: 2 }}>
          Choose cares for this plan
        </Typography>

        <SearchBar onChange={handleSearchedTextChanges} sx={{ mb: 2 }}/>

        <div className="board-container">
          <Board
            data={data}
            laneDraggable={false}
            style={{
              maxHeight: 500,
              backgroundColor: Theme.palette.background.lightGray,
              color: Theme.palette.primary.main
            }}
            customCardLayout
            components={{ Card: DraggableCard }}
            onCardMoveAcrossLanes={(fromLaneId, toLaneId, cardId, index) =>
              handleLaneChange({ fromLaneId, toLaneId, cardId, index })
            }
          />
        </div>
      </Stack>
    </React.Fragment>
  );
}