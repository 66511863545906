export default class Product {
    constructor(
        id,
        title,
        subtitle = "",
        imageUrl,
        pageUrl
    ) {
      this.id = id;
      this.title = title;
      this.subtitle = subtitle;
      this.imageUrl = imageUrl;
      this.pageUrl = pageUrl;
    }
}