import * as React from "react";
import { Grid, Button } from "@mui/material";
import dayjs from 'dayjs';

export default function List({ items, onRemove }) {
  return (
    <React.Fragment>
        <Grid container spacing={{ xs: 1, md: 2 }}>
            {items.map((value, index) => (
                <Grid item key={index}>
                    <Button
                        type="hour"
                        variant="contained"
                        onClick={() => {
                            onRemove(value)
                        }}
                    >
                        {dayjs(value).format('HH:mm')}
                    </Button>
                </Grid>
            ))}
        </Grid>
    </React.Fragment>
  );
}