import * as React from "react";
import Theme from "../../theme";
import {
  Typography,
  Box,
  Modal,
  InputBase,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Stack
} from "@mui/material";
import { Search } from "@mui/icons-material";
import { auth } from "../../services/firebase";
import { ReactComponent as MaleImage } from "../../img/bgZECbaWftRRgvPmRFbStTNRjtv1.svg";
import { ReactComponent as FemaleImage } from "../../img/039RNfPbODPb4C7KLEfWYNnpPXz2.svg";
import { StatusLabel, StatusLabelType } from "../../views/StatusLabel";
import { SearchBar } from "../SearchBar";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 12,
    borderRadius: "8px",
    overflow: "scroll",
    display: "block",
    p: 3,
    height: 500,
    minWidth: 500,
};

export function UserSearchModal({ users, open, onClose, onChange, onClick }) {

    return (
        <Modal 
            open={open} 
            onClose={onClose}
        >
            <Box sx={style}>
                <SearchBar placeholder="Search user" onChange={onChange}/>

                <TableContainer sx={{ maxHeight: 400 }}>
                <Table stickyHeader>
                    <TableHead>
                    <TableRow>
                        <TableCell sx={{ width: "200px" }}>
                        List of users
                        </TableCell>
                    </TableRow>
                    </TableHead>

                    <TableBody>
                    {users.map((user) => (
                        <TableRow key={user.id}>
                        <TableCell>
                            <Box
                            display="flex"
                            width="100%"
                            alignItems="center"
                            justifyContent="space-between"
                            height="40px"
                            onClick={() => onClick(user)}
                            >
                            <Box
                                display="flex"
                                alignItems="center"
                                height="40px"
                            >
                                {user.name
                                .split(" ")[0]
                                .slice(-1)
                                .toLowerCase() == "a" ? (
                                    <FemaleImage width="35px" height="35px" />
                                ) : (
                                    <MaleImage width="35px" height="35px" />
                                )
                                }

                                <Stack
                                direction="column"
                                alignItems="flex-start"
                                sx={{ ml: "8px" }}
                                >
                                {user.name.length > 0 ? (
                                    <Box>
                                    <Typography
                                        variant="body2"
                                        lineHeight={1}
                                    >
                                        {user.name}
                                    </Typography>

                                    <Typography
                                        variant="caption"
                                        color="gray"
                                    >
                                        {user.email}
                                    </Typography>
                                    </Box>
                                ) : (
                                    <Typography variant="body2">
                                    {user.email}
                                    </Typography>
                                )}
                                </Stack>
                            </Box>

                            <StatusLabel
                                type={
                                    (user.hairCarePlan != undefined && user.hairCarePlan.expertId == auth.currentUser.uid) ? 
                                        StatusLabelType.ASSIGNED : 
                                        StatusLabelType.UNASSIGNED
                                } 
                                marginRight={1.5}
                            />
                            </Box>
                        </TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
                </TableContainer>
            </Box>
        </Modal>
    )
}