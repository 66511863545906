import * as React from "react";
import Link from "@mui/material/Link";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Title from "./Title";
import IconEdit from "@mui/icons-material/Edit";
import IconDelete from "@mui/icons-material/Delete";
import { IconButton, TableContainer } from "@mui/material";
import Theme from "../../theme";

function preventDefault(event) {
  event.preventDefault();
}

export default function List(props) {
  return (
    <React.Fragment>
      <Title>{props.title}</Title>

      <TableContainer sx={{ maxHeight: 400 }}>
        <Table stickyHeader size="medium">
          <TableHead>
            <TableRow>
              <TableCell>Created at</TableCell>
              <TableCell>Title</TableCell>
              <TableCell>Author</TableCell>
              <TableCell align="center">Sections count</TableCell>
              <TableCell>Actions</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.items.map((article) => (
              <TableRow key={article.id}>
                <TableCell>{new Date(article.createdAt).toLocaleDateString()}</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>
                  {article.title}
                </TableCell>
                <TableCell>{article.author}</TableCell>
                <TableCell align="center">{article.sections.length}</TableCell>
                <TableCell>
                  {
                    <IconButton
                      onClick={() => {
                        props.sendOnEdit(article);
                      }}
                    >
                      <IconEdit sx={{ color: Theme.palette.primary.main }} />
                    </IconButton>
                  }
                </TableCell>
                <TableCell>
                  {
                    <IconButton
                      onClick={() => {
                        props.sendOnRemove(article);
                      }}
                    >
                      <IconDelete sx={{ color: Theme.palette.error.main }} />
                    </IconButton>
                  }
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Link color="primary" href="#" onClick={preventDefault} sx={{ mt: 3 }}>
        See more items
      </Link>
    </React.Fragment>
  );
}
