import * as React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Theme from "../../theme";
import {
  TextField,
  IconButton,
  InputBase,
  Button,
  Typography,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  Fade,
  Modal,
  MenuItem,
  FormControl,
  Select,
  Menu,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  Divider,
  TableContainer
} from "@mui/material";
import { Stack } from "@mui/system";
import {
  Search,
  MoreVert,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import { ReactComponent as MaleImage } from "../../img/bgZECbaWftRRgvPmRFbStTNRjtv1.svg";
import { ReactComponent as FemaleImage } from "../../img/039RNfPbODPb4C7KLEfWYNnpPXz2.svg";
import User from "../../models/User";
import AlertDialog from "../../views/AlertDialog";

import { useEffect } from "react";
import {
  db,
  updateUserRole,
  createUser,
  updateUserLockedAccountState,
  removeUser,
} from "../../services/firebase";

export default function SettingsUsersAccess() {
  const [users, setUsers] = React.useState([]);

  const [searchedText, setSearchedText] = React.useState("");
  const [localUsers, setLocalUsers] = React.useState([]);

  const [openModal, setModalOpen] = React.useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedUserId, setSelectedUserId] = React.useState("");
  const openMenu = Boolean(anchorEl);

  const [newUserName, setNewUserName] = React.useState("");
  const [newUserEmail, setNewUserEmail] = React.useState("");
  const [newUserRole, setNewUserRole] = React.useState("");
  const [newUserPassword, setNewUserPassword] = React.useState("");

  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // Searchbar

  const handleTextChange = (evt) => {
    let value = evt.target.value;
    setSearchedText(value);
    let localUsers = users;
    const filteredUsers = localUsers.filter(
      (user) =>
        user.email.toLowerCase().includes(value.toLowerCase()) ||
        user.name.toLowerCase().includes(value.toLowerCase())
    );
    setLocalUsers(filteredUsers);
  };

  // New user modal

  const handleOpen = () => setModalOpen(true);
  const handleClose = () => {
    setModalOpen(false);
    setNewUserName("");
    setNewUserEmail("");
    setNewUserRole("");
    setNewUserPassword("");
  };

  // Menu

  const handleIconClick = (event, value) => {
    setAnchorEl(event.currentTarget);
    setSelectedUserId(value);
  };

  const handleMenuClose = (value) => {
    setAnchorEl(null);
  };

  const handleMenuRemoveClick = () => {
    setAnchorEl(null);
    setOpenDialog(true);
  };

  const handleMenuLockClick = () => {
    setAnchorEl(null);
    updateUserLockedAccountState(
      selectedUserId,
      !(
        users.filter((user) => user.id == selectedUserId)[0].accountLocked ??
        false
      )
    );
  };

  // Create new user

  const handleCreateNewUser = () => {
    let user = new User(null, newUserEmail, newUserName, newUserRole);
    createUser(user, newUserPassword);
    handleClose();
  };

  const handleNewUserNameChange = (event) => {
    setNewUserName(event.target.value);
  };

  const handleNewUserEmailChange = (event) => {
    setNewUserEmail(event.target.value);
  };

  const handleNewUserRoleChange = (event) => {
    setNewUserRole(event.target.value);
  };

  const handleNewUserPasswordChange = (event) => {
    setNewUserPassword(event.target.value);
  };

  // Fetch users

  const fetchUsers = async () => {
    db.ref()
      .child("panelUsers")
      .on("value", (snapshot) => {
        let localUsers = [];
        snapshot.forEach((item) => {
          let user = new User(
            item.key,
            item.val()["email"],
            item.val()["name"],
            item.val()["role"],
            item.val()["accountLocked"]
          );
          localUsers.push(user);
        });
        setUsers(localUsers);
        setLocalUsers(localUsers);
      });
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  // MARK: - Removal Dialog

  const [openDialog, setOpenDialog] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState("");

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleUserDelete = () => {
    setOpenDialog(false);
    removeUser(selectedUserId);
  };

  // Body

  return (
    <div>
      <Modal open={openModal} onClose={handleClose} closeAfterTransition>
        <Fade in={openModal}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 600,
              bgcolor: "white",
              boxShadow: 1,
              borderRadius: 2,
              p: 4,
            }}
          >
            <Typography variant="h6" sx={{ mb: 3 }}>
              New Member
            </Typography>

            <TextField
              label="Full Name"
              variant="outlined"
              fullWidth
              value={newUserName}
              onChange={handleNewUserNameChange}
              sx={{ mb: 2 }}
            />

            <Stack
              direction="row"
              alignItems="flex-start"
              spacing={2}
              sx={{ mb: 3 }}
            >
              <TextField
                label="Email"
                variant="outlined"
                fullWidth
                type="email"
                value={newUserEmail}
                onChange={handleNewUserEmailChange}
              />

              <FormControl sx={{ width: "100%" }} variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">
                  Password
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={showPassword ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                  onChange={handleNewUserPasswordChange}
                />
              </FormControl>
            </Stack>

            <Typography variant="body1" fontWeight="medium" sx={{ mb: 1 }}>
              Role
            </Typography>

            <Divider variant="fullWidth" sx={{ mb: 3 }} />

            <FormControl sx={{ width: "100%", mb: 3 }}>
              <InputLabel id="user-role-select-label">Role</InputLabel>
              <Select
                labelId="user-role-select-label"
                value={newUserRole}
                label="Role"
                onChange={handleNewUserRoleChange}
              >
                <MenuItem value={"Admin"}>Admin</MenuItem>
                <MenuItem value={"Editor"}>Editor</MenuItem>
                <MenuItem value={"Expert"}>Expert</MenuItem>
              </Select>
            </FormControl>

            <Box display="flex" width="100%" flexDirection="row-reverse">
              <Button variant="contained" onClick={handleCreateNewUser}>
                Add
              </Button>

              <Button
                variant="outlined"
                onClick={handleClose}
                sx={{ marginRight: 2 }}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </Fade>
      </Modal>

      <AlertDialog
        selectedValue={selectedValue}
        open={openDialog}
        onClose={handleCloseDialog}
        onAction={handleUserDelete}
        title="Confirm member removal"
        message={`Are you sure, you want to remove member ${selectedUserId}?`}
        buttonTitle="Yes, remove"
      />

      <Box
        display="flex"
        sx={{
          flexDirection: "column",
          width: "100%",
          mb: 4,
          backgroundColor: Theme.palette.background.lightGray,
        }}
      >
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <Stack
            direction="row"
            alignItems="flex-start"
            justifyContent="space-between"
            sx={{ pb: "16px" }}
          >
            <Box
              display="flex"
              alignItems="center"
              borderRadius="4px"
              backgroundColor={Theme.palette.background.lightGray}
              padding="4px"
              width="60%"
              height="38px"
            >
              <IconButton sx={{ p: "10px" }} aria-label="search">
                <Search />
              </IconButton>

              <InputBase
                onChange={handleTextChange}
                sx={{ ml: 1, flex: 1, fontSize: 15 }}
                placeholder="Search members"
                inputProps={{ "aria-label": "search members" }}
              />
            </Box>

            <Button variant="contained" onClick={handleOpen}>
              Add member
            </Button>
          </Stack>

          <TableContainer sx={{ maxHeight: 600 }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: "400px" }}>Member</TableCell>
                  <TableCell sx={{ width: "400px" }}>Roles</TableCell>
                </TableRow>
              </TableHead>

              {localUsers.length > 0 ? (
                <TableBody>
                  {localUsers.map((user) => (
                    <TableRow key={user.id}>
                      <TableCell>
                        <Box display="flex" alignItems="center" height="48px">
                          {user.name.split(" ")[0].slice(-1) == "a" ? (
                            <FemaleImage width="35px" height="35px" />
                          ) : (
                            <MaleImage width="35px" height="35px" />
                          )}

                          <Stack
                            direction="column"
                            alignItems="flex-start"
                            sx={{ ml: "8px" }}
                          >
                            {user.name.length > 0 ? (
                              <Box>
                                <Typography variant="body2" lineHeight={1}>
                                  {user.name}
                                </Typography>

                                <Typography variant="caption" color="gray">
                                  {user.email}
                                </Typography>
                              </Box>
                            ) : (
                              <Typography variant="body2">
                                {user.email}
                              </Typography>
                            )}
                          </Stack>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                          height="48px"
                        >
                          <UserRoleSelector user={user} />

                          <div>
                            <IconButton
                              id="positioned-button"
                              aria-controls={
                                openMenu ? "positioned-menu" : undefined
                              }
                              aria-haspopup="true"
                              aria-expanded={openMenu ? "true" : undefined}
                              onClick={(evt) => handleIconClick(evt, user.id)}
                            >
                              <MoreVert />
                            </IconButton>

                            <Menu
                              elevation={1}
                              anchorEl={anchorEl}
                              open={openMenu}
                              onClose={handleMenuClose}
                              anchorOrigin={{
                                vertical: "top",
                                horizontal: "left",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "left",
                              }}
                            >
                              <MenuItem
                                onClick={handleMenuRemoveClick}
                                sx={{ color: "red" }}
                              >
                                Remove user
                              </MenuItem>

                              <MenuItem onClick={handleMenuLockClick}>
                                <Typography>
                                  {selectedUserId == "" ||
                                  selectedUserId == undefined
                                    ? "Lock account"
                                    : users.find(
                                        (user) =>
                                          user.id == (selectedUserId ?? "")
                                      ).accountLocked
                                    ? "Unlock account"
                                    : "Lock account"}
                                </Typography>
                              </MenuItem>
                            </Menu>
                          </div>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              ) : (
                <TableBody>
                  <TableRow>
                    <TableCell align="right">
                      <Typography
                        variant="body2"
                        color="gray"
                        sx={{ pt: "8px", pb: "8px", mr: "-26px" }}
                      >
                        No results found for
                      </Typography>
                    </TableCell>

                    <TableCell align="left">
                      <Typography
                        variant="body2"
                        fontWeight="bold"
                        color="gray"
                        sx={{ pt: "8px", pb: "8px" }}
                      >
                        {`"${searchedText}"`}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Paper>
      </Box>
    </div>
  );
}

export function UserRoleSelector({ user }) {
  const [role, setRole] = React.useState(user.role);

  const handleChange = (event) => {
    setRole(event.target.value);
    updateUserRole(user.id, event.target.value);
  };

  return (
    <FormControl sx={{ minWidth: 120 }}>
      <Select value={role} onChange={handleChange} sx={{ height: "34px" }}>
        <MenuItem value={"Admin"}>Admin</MenuItem>
        <MenuItem value={"Editor"}>Editor</MenuItem>
        <MenuItem value={"Expert"}>Expert</MenuItem>
        <MenuItem value={"User"}>User</MenuItem>
      </Select>
    </FormControl>
  );
}
