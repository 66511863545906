import * as React from 'react';
import SignIn from '../modules/signin/SignIn';
import { useState } from 'react';
import { LoadingPage } from '../views/LoadingView';
import Theme from '../theme'
import { Box } from '@mui/system';
import SideBar from '../views/SideBar';
import NavigationBar from '../views/NavigationBar';
import Notification from '../models/Notification';
import Notifications from '../modules/notifications/Notifications';
import AppContainer from './AppContainer';
import AlertDialog from '../views/AlertDialog';
import { auth } from '../services/firebase';
import User from '../models/User';

export default function App({ onLogin })  {
  const [user, setUser] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [selection, setSelection] = useState(0);
  const [navBarVisible, setNavBarVisible] = useState(false);
  const [localUser, setLocalUser] = useState(new User()); // User model fetched from database
  const [notificationsOpen, setNotificationsOpen] = useState(false);

  // MARK: - Controls

  const handleIsOpen = () => {
    setOpen(!isOpen)
  }

  const handleSelection = (selectedIndex) => {
    setSelection(selectedIndex)
  }

  const handleScroll = (event) => {
    setNavBarVisible(event.target.scrollTop > 0)
  };

  const handleLocalUserDataUpdate = (user) => {
    setLocalUser(user)
    setSelection(user.role == "Admin" ? 0 : 2)
  }

  const handleNotificationsOpen = () => {
    setNotificationsOpen(true)
  }

  const handleNotificationsClose= () => {
    setNotificationsOpen(false)
  }

  // MARK: - Sign Out Dialog

  const [openDialog, setOpenDialog] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState("");

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleSignOut = () => {
    setOpenDialog(false);
    auth.signOut();
    setUser(null);
    setLocalUser(new User());
  };

  // MARK: - Component

  return (
    <Box 
      display="flex" 
      height="100vh"
      width="100vw"
    >
      {/* Overlays */}

      <AlertDialog
        selectedValue={selectedValue}
        open={openDialog}
        onClose={handleCloseDialog}
        onAction={handleSignOut}
        title="Confirm Sign Out"
        message="Are you sure, that you want to sign out?"
        buttonTitle="Yes, sign out"
      />
      
      {/* Components */}
        <Notifications 
          open={notificationsOpen} 
          onClose={handleNotificationsClose} 
          notifications={[
            new Notification(0, "New", "Last released changes", "See, what changes we included in the last update - it can be pretty interesting 🚀"),
          ]} 
        />

        {(!isLoading && user) ?
          <Box 
              component="sidebar"
              sx={{ backgroundColor: Theme.palette.primary.main, width: (isOpen ? '260px' : '70px'), height: '100%'}}
              style={{ transition: "all 0.15s linear" }}
              zIndex="2"
            >
              <SideBar 
                open={handleIsOpen} 
                isOpen={isOpen} 
                selection={selection} 
                setSelection={handleSelection}
                user={localUser}
                onSignOut={handleOpenDialog}
              />
          </Box>
          :
          null
        }

        <Box 
          display="flex" 
          height="100%" 
          width="100%"
          sx={{ backgroundColor: Theme.palette.background.lightGray, overflow: 'auto' }}
          onScroll={(event) => handleScroll(event)}
          zIndex="0"
        >
          {isLoading ? <LoadingPage /> : 
            (user ? 
              <AppContainer selection={selection} parentCallback = {handleLocalUserDataUpdate} /> : 
              <SignIn setUser={(user) => setUser(user)} setLoading={(isLoading) => setLoading(isLoading)} onLogin={onLogin}/>
            )
          }
        </Box>

        {(!isLoading && user) ?
          <Box
            display="flex"
            position="absolute"
            height="54px"
            width="100%"
            sx={{ 
              flexDirection: 'row-reverse',
              backgroundColor: (navBarVisible ? 'white' : 'null'), 
              pl: (isOpen ? '200px' : '10px'), 
              boxShadow: (navBarVisible ? 1 : 0) 
            }}
            style={{ transition: "all 0.15s linear" }}
            zIndex="1"
          >
            <NavigationBar 
              navBarVisible={navBarVisible} 
              user={localUser ?? user} 
              selection={selection}
              notificationsVisible={handleNotificationsOpen}
            />
          </Box>
          :
          null
        }
    </Box>
  );
}
