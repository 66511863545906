import React, { useState } from 'react';
import Theme from '../theme'
import dayjs from 'dayjs';
import { Box, Stack } from '@mui/material';
import {
  LocalizationProvider,
  CalendarPicker,
  PickersDay,
} from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Circle } from '@mui/icons-material';

export default function Calendar({ availableHours, appointmentDates, onSelectedDateChange }) {

  const [selectedDate, setSelectedDate] = useState(dayjs());

  const handleSelectedDateChange = (date) => {
    setSelectedDate(date)
    onSelectedDateChange(date)
  }

  const renderDayInPicker = (date, selectedDate, dayInCurrentMonth, dayComponent) => {
    const dayjsDate = dayjs(date);
    const isToday = dayjsDate.isSame(dayjs(), 'day');
    const isSameMonth = dayjsDate.isSame(selectedDate, 'month');
    const isSelected = dayjsDate.isSame(selectedDate, 'day');
    const hasAvailableHours = (availableHours ?? [])
        .map((date) => dayjs(date).format('YYYY-MM-DD'))
        .includes(dayjsDate.format('YYYY-MM-DD'));
    const hasAppointments = (appointmentDates ?? [])
        .map((date) => dayjs(date).format('YYYY-MM-DD'))
        .includes(dayjsDate.format('YYYY-MM-DD'));

    return (
      <div style={{ position: 'relative', textAlign: 'center' }}>
        <Box
            display="flex"
            alignItems="self-end"
            justifyContent="center"
            sx={{ opacity: (isSameMonth ? 1 : 0.25) }}
        >
            <PickersDay 
                flex="1"
                day={date} 
                onDaySelect={handleSelectedDateChange}
                sx={{ 
                    fontSize: 13,
                    backgroundColor: (isSelected ? Theme.palette.darkBrown : (isToday ? Theme.palette.cream : 'transparent')),
                    color: (isSelected ? 'white' : 'black')
                }}
            />

            <div style={{position:'absolute'}}>
              <Stack
                direction="row"
                justifyContent="space-between"
              >
                {hasAvailableHours ? 
                  <Circle
                    sx={{ 
                      width:'6px',
                      height: '6px', 
                      color: Theme.palette.darkBrown
                    }}
                  /> : null
                }
                {(hasAppointments && !isSelected && !isToday) ? 
                  <Circle
                    sx={{ 
                      width:'6px', 
                      height: '6px', 
                      color: Theme.palette.creamyBrown
                    }}
                  /> : null
                }
              </Stack>
            </div>  
        </Box>
      </div>
    );
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <CalendarPicker
        date={selectedDate}
        onChange={setSelectedDate}
        renderDay={renderDayInPicker}
      />
    </LocalizationProvider>
  );
};