import * as React from "react";
import Link from "@mui/material/Link";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Title from "../../modules/dashboard/Title";
import Theme from "../../theme";
import IconEdit from "@mui/icons-material/Edit";
import IconDelete from "@mui/icons-material/Delete";
import { IconButton, TableContainer } from "@mui/material";

function preventDefault(event) {
  event.preventDefault();
}

export default function List(props) {
  return (
    <React.Fragment>
      <Title>{props.title}</Title>

      <TableContainer sx={{ maxHeight: 400 }}>
        <Table stickyHeader size="medium">
          <TableHead>
            <TableRow>
              <TableCell>Created at</TableCell>
              <TableCell>Title</TableCell>
              <TableCell>Duration</TableCell>
              <TableCell align="center">Type</TableCell>
              <TableCell align="center">Steps count</TableCell>
              <TableCell>Actions</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.items.map((care) => (
              <TableRow key={care.id}>
                <TableCell>{new Date(care.createdAt).toLocaleDateString()}</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>{care.title}</TableCell>
                <TableCell>{care.duration}</TableCell>
                <TableCell
                  align="center"
                  sx={{
                    color: care.isBasicCare == 0 ? "#68594b" : "#b69b8e",
                    fontWeight: "bold",
                  }}
                >
                  {care.isBasicCare == 0 ? "Premium" : "Base"}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    color: care.steps == null ? "#d10000" : "text.primary",
                    fontWeight: care.steps == null ? "bold" : "regular",
                  }}
                >
                  {care.steps.length ?? 0}
                </TableCell>
                <TableCell>
                  {
                    <IconButton
                      onClick={() => {
                        props.sendOnEdit(care);
                      }}
                    >
                      <IconEdit sx={{ color: Theme.palette.primary.main }} />
                    </IconButton>
                  }
                </TableCell>
                <TableCell>
                  {
                    <IconButton
                      onClick={() => {
                        props.sendOnRemove(care);
                      }}
                    >
                      <IconDelete sx={{ color: Theme.palette.error.main }} />
                    </IconButton>
                  }
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Link color="primary" href="#" onClick={preventDefault} sx={{ mt: 3 }}>
        See more items
      </Link>
    </React.Fragment>
  );
}
