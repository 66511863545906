import * as React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { Box, CssBaseline, Divider } from '@mui/material';
import Theme from '../theme'

import Care from '../models/Care';
import User from '../models/User'

import Dashboard from '../modules/dashboard/Dahsboard'
import Copyrights from '../views/Copyrights'
import Cares from '../modules/cares/Cares';
import Plans from '../modules/plans/Plans';
import Appointments from '../modules/appointments/Appointments';
import Settings from '../modules/settings/Settings';

import NewCare from '../modules/cares/newCare/NewCare'
import NewPlan from '../modules/plans/newPlan/NewPlan'
import Articles from '../modules/articles/Articles';
import NewArticle from '../modules/articles/newArticle/NewArticle';
import Article from '../models/article/Article';

import { db, auth, activateRemoteConfig } from '../services/firebase';

import AlertDialog from '../views/AlertDialog';
import Expert from '../models/Expert';
import HairCarePlan from '../models/plan/HairCarePlan';
import Logs from '../modules/logs/Logs';
import Reports from '../modules/reports/Reports';

export default class AppContainer extends React.Component {

  constructor() {
    super();
    this.state = {
      showAddCare: false,
      showAddPlan: false,
      showAddArticle: false,
      isLocked: false,
      cares: [],
      articles: [],
      plans: [],
      care: new Care,
      article: new Article,
      plan: new HairCarePlan,
      user: new User,
      expert: new Expert
    };

    this.showAddCare = this.showAddCare.bind(this);
    this.hideAddCare = this.hideAddCare.bind(this);

    this.showAddPlan = this.showAddPlan.bind(this);
    this.hideAddPlan = this.hideAddPlan.bind(this);

    this.showAddArticle = this.showAddArticle.bind(this);
    this.hideAddArticle = this.hideAddArticle.bind(this);
  }

  componentDidMount() {
    this.fetchData()
    this.observeAccountLockedState()

    activateRemoteConfig()
  }

  fetchData = () => {
    const userId = auth.currentUser.uid

    db.ref().child("panelUsers").child(userId).once('value', (snapshot) => {
      const dataVal = snapshot.val()
      const user = new User(
        userId, 
        dataVal.email,
        dataVal.name,
        dataVal.role,
        dataVal.accountLocked
      )
      this.setState({ user: user })
      this.props.parentCallback(user)

      if (user.role == "Expert") {
        db.ref().child('experts').child(user.id).once('value', snapshot => {
          const dataVal = snapshot.val()

          const plans = []
          const cares = []

          snapshot.forEach(item => {
            if (item.key == "plans") {
              item.forEach(value => {
                const planVal = value.val()
                const cares = []
                const products = []
                const supplements = []
                const treatments = []

                value.forEach(item => {
                  if (item.key == "cares") {
                    item.forEach(value => {
                      cares.push(value.val())
                    })
                  }
                  if (item.key == "products") {
                    item.forEach(value => {
                      products.push(value.val())
                    })
                  }
                  if (item.key == "supplements") {
                    item.forEach(value => {
                      supplements.push(value.val())
                    })
                  }
                  if (item.key == "treatments") {
                    item.forEach(value => {
                      treatments.push(value.val())
                    })
                  }
                })

                const plan = new HairCarePlan(
                  planVal.id,
                  planVal.name, 
                  planVal.createdAt, 
                  planVal.startsAt, 
                  planVal.endsAt, 
                  planVal.userId,
                  user.id,
                  planVal.notes,
                  cares,
                  planVal.productNotes,
                  products,
                  planVal.supplementNotes,
                  supplements,
                  treatments,
                  planVal.countryCode,
                  planVal.status
                )
                plans.push(plan)
              })
            } else if (item.key == "cares") {
              item.forEach(value => {
                cares.push(value.val())
              })
            }
          })

          const expert = new Expert(
            dataVal.availableHours,
            plans,
            cares
          )
          this.setState({ expert: expert })
          this.setState({ plans: expert.plans })

          const sortedCares = cares.sort((a, b) => a.createdAt < b.createdAt)
          this.setState({ cares: sortedCares })
        })
      } else {
        db.ref("cares/").once('value', snapshot => {
          let cares = []
          snapshot.forEach((item) => {
            if (item.key != "expert") {
              item.forEach(data => {
                const care = data.val();
                cares.push(care)
              })
            }
          })
          const sortedCares = cares.sort((a, b) => a.createdAt < b.createdAt)
          this.setState({ cares: sortedCares })
        })
    
        db.ref().child('articles').once('value', snapshot => {
          let articles = []
          snapshot.forEach((data) => {
            const article = data.val();
            articles.push(article)
          })
          const sortedArticles = articles.sort((a, b) => a.createdAt < b.createdAt)
          this.setState({ articles: sortedArticles })
        })

        db.ref().child('plans').once('value', snapshot => {
          let plans = []
          snapshot.forEach((data) => {
              const planVal = data.val()
              const cares = []
              const products = []
              const supplements = []
              const treatments = []

              data.forEach(item => {
                if (item.key == "cares") {
                  item.forEach(value => {
                    cares.push(value.val())
                  })
                }
                if (item.key == "products") {
                  item.forEach(value => {
                    products.push(value.val())
                  })
                }
                if (item.key == "supplements") {
                  item.forEach(value => {
                    supplements.push(value.val())
                  })
                }
                if (item.key == "treatments") {
                  item.forEach(value => {
                    treatments.push(value.val())
                  })
                }
              })

              const plan = new HairCarePlan(
                planVal.id,
                planVal.name, 
                planVal.createdAt, 
                planVal.startsAt, 
                planVal.endsAt, 
                planVal.userId,
                planVal.expertId,
                planVal.notes,
                cares,
                planVal.productNotes,
                products,
                planVal.supplementNotes,
                supplements,
                treatments,
                planVal.countryCode,
                planVal.status
              )
              plans.push(plan)
          })
          const sortedPlans = plans.sort((a, b) => a.createdAt < b.createdAt)
          this.setState({ plans: sortedPlans })
        })
      }
    })
  }

  observeAccountLockedState() {
    db.ref().child("panelUsers").child(auth.currentUser.uid).on('value', (snapshot) => {
      const dataVal = snapshot.val()
      this.setState({ isLocked: dataVal.accountLocked })
    })
  }

  // MARK: - Modals methods

  showAddCare = (care) => {
    this.setState({ 
      showAddCare: true ,
      care: care ?? new Care()
    })
  };

  hideAddCare = () => {
    this.setState({ showAddCare: false })
  };

  showAddPlan = (plan) => {
    this.setState({ 
      showAddPlan: true, 
      plan: plan ?? new HairCarePlan() 
    })
  };

  hideAddPlan = () => {
    this.setState({ showAddPlan: false })
  };

  showAddArticle = (article) => {
    this.setState({ 
      showAddArticle: true,
      article: article ?? new Article()
    })
  };

  hideAddArticle = () => {
    this.setState({ showAddArticle: false })
  };

  // MARK: - Components

  render() {
    const selectedIndex = this.props.selection

    return (
      <ThemeProvider theme={Theme}>
        <Box 
          component="main" 
          display="flex" 
          width="100%" 
          sx={{ 
            backgroundColor: Theme.palette.background.lightGray
          }}
        >
          <AlertDialog
            open={this.state.isLocked}
            buttonsHidden={true}
            titleColor={"red"}
            title="Account locked"
            message="For safety reasons, your account was locked. If you need more information, please contact the administrator."
          />
          <CssBaseline />
          <NewCare care={this.state.care} open={this.state.showAddCare} close={this.hideAddCare} user={this.state.user} />
          <NewPlan editedPlan={this.state.plan} cares={this.state.cares} user={this.state.user} open={this.state.showAddPlan} close={this.hideAddPlan} />
          <NewArticle article={this.state.article} open={this.state.showAddArticle} close={this.hideAddArticle} />
          <Box
            display="flex"
            width="100%"
            sx={{ flexDirection: "column", backgroundColor: Theme.palette.background.lightGray }}
          >
            {selectedIndex == 0 ?
              <Dashboard 
                cares={this.state.cares} 
                articles={this.state.articles} 
                plans={this.state.plans} 
                user={this.state.user}
              />
              :
              (
              selectedIndex == 1 ?
                <Settings cares={this.state.cares}/>
                :
                (
                selectedIndex == 2 ?
                  <Cares 
                    cares={this.state.cares} 
                    user={this.state.user}
                    onClick={this.showAddCare}
                  />
                  :
                  (
                  selectedIndex == 3 ?
                    <Articles articles={this.state.articles} onClick={this.showAddArticle}/>
                    :
                    (
                      selectedIndex == 4 ?
                        <Plans plans={this.state.plans} onClick={this.showAddPlan}/>
                        :
                        (
                          selectedIndex == 5 ?
                            <Appointments />
                            :
                            (selectedIndex == 6 ?
                              <Logs />
                              :
                              <Reports plans={this.state.plans}/>
                            )
                        )
                    )
                  )
                )
              )
            }
            <Box 
              display="flex" 
              width="100%" 
              sx={{ 
                flexDirection: 'row', 
                justifyContent: 'space-between',
                mt: 7, 
                pb: 4 
              }}
            >
              <Divider/>
              <Copyrights />
              <Divider/>
            </Box>
          </Box>
        </Box>
      </ThemeProvider>
    );
  }

}