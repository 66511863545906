export default class ArticleSection {
    constructor(
        id,
        title,
        content,
        isHighlighted
    ) {
        this.id = id
        this.title = title
        this.content = content
        this.isHighlighted = isHighlighted
    }
}