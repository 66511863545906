import React, { useEffect } from 'react';
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Theme from "../../theme";
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
} from "@mui/material";
import { getUsersCount } from "../../services/firebase";

export default function SettingsGeneral() {
  const [usersCount, setUserCount] = React.useState(0);

  useEffect(() => {
    getUsersCount()
      .then((usersCount) => {
        setUserCount(usersCount)
      })
      .catch((error) => {
        console.debug(error)
      }) 
  }, []);

  return (
    <Box sx={{ backgroundColor: Theme.palette.background.lightGray }}>
      <Box
        display="flex"
        sx={{
          flexDirection: "column",
          width: "100%",
          mt: 4,
          mb: 4,
          backgroundColor: Theme.palette.background.lightGray,
        }}
      >
        <Typography
          variant="subtitle2"
          fontWeight="regular"
          color={Theme.palette.primary.main}
          sx={{ mb: "16px" }}
        >
          Overview
        </Typography>

        <TableContainer component={Paper}>
          <Table size="small" sx={{ minWidth: 650, mt: 2, mb: 2 }}>
            <TableBody>
              <TableRow sx={{ "& td": { border: 0 } }}>
                <TableCell sx={{ width: 300 }}>
                  <Typography fontSize={13} color="gray">
                    Company name
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography fontSize={15}>Hairviour</Typography>
                </TableCell>
              </TableRow>

              <TableRow sx={{ "& td": { border: 0 } }}>
                <TableCell sx={{ width: 300 }}>
                  <Typography fontSize={13} color="gray">
                    Company Identification Number (KRS)
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography fontSize={15}>192 012 93 82</Typography>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell sx={{ width: 300, pb: 3 }}>
                  <Typography fontSize={13} color="gray">
                    Company owners
                  </Typography>
                </TableCell>
                <TableCell sx={{ pb: 3 }}>
                  <Typography fontSize={15}>
                    Krzysztof Łowiec, Agnieszka Zimna
                  </Typography>
                </TableCell>
              </TableRow>

              <TableRow sx={{ "& td": { border: 0 } }}>
                <TableCell sx={{ width: 300, pt: 3 }}>
                  <Typography fontSize={15} fontWeight="medium">
                    Statistics
                  </Typography>
                </TableCell>
              </TableRow>

              <TableRow sx={{ "& td": { border: 0 } }}>
                <TableCell sx={{ width: 300 }}>
                  <Typography fontSize={13} color="gray">
                    Users total
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography fontSize={15}>{usersCount}</Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
}
