import * as React from 'react';
import Theme from '../../theme'
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

import { Stack, Button} from '@mui/material'
import { DoubleArrow, GridView, VerifiedUser, Add } from '@mui/icons-material';

export default function DashboardTitle({ user, lastActivities }) {

    return (
        <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
            sx={{ mt: 2 }}
        >
            <Stack
                direction="column"
                justifyContent="flex-start"
                alignItems="flex-start"
                sx={{ ml: 14, mt: 8 }}
                spacing={1}
            >
                <Stack
                    direction="row"
                    alignItems="center"
                    spacing={2}
                >
                    <Typography variant="h4" fontWeight="medium">
                    Dashboard
                    </Typography>

                    <Typography 
                    variant="body2"
                    fontWeight="medium" 
                    color={Theme.palette.primary.main}
                    sx={{ 
                        borderColor: Theme.palette.primary.main,
                        border: 1.5,
                        borderRadius: '16px',
                        px: 1
                    }}
                    >
                    {user.role}
                    </Typography>
                </Stack>

                <Stack
                    direction="row"
                    alignItems="center"
                    spacing={0.75}
                    sx={{ pt: 1 }}
                >
                    <ButtonItem 
                        variant="contained"
                        startIcon={<GridView />}
                            sx={{
                                height: '32px',
                                borderRadius: '16px',
                            }}
                    >
                        2 modules
                    </ButtonItem>

                    <DoubleArrow sx={{ color: Theme.palette.primary.main, opacity: 0.5 }} />

                    <ButtonItemOutlined 
                        variant="outlined"
                        startIcon={<VerifiedUser sx={{ color: '#4caf50' }} />}
                            sx={{
                                height: '32px',
                                borderRadius: '16px',
                            }}
                    >
                        Security Check
                    </ButtonItemOutlined>

                    <ButtonItem 
                        variant="contained"
                        startIcon={<Add />}
                            sx={{
                                height: '32px',
                                borderRadius: '16px',
                            }}
                    >
                        Add
                    </ButtonItem>
                </Stack>
            </Stack>

            <Stack
                direction="column"
                justifyContent="flex-end"
                alignItems="flex-end"
                sx={{ mr: 14, mt: 8 }}
            >
                <Typography>
                    Activities in last 30 minutes
                </Typography>

                <Typography variant="h3" fontWeight="medium">
                    {lastActivities}
                </Typography>
            </Stack>
        </Stack>
    );
}

const ButtonItem = styled(Button)({
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 14,
    fontWeight: '600',
    lineHeight: 0,
    flexWrap: 'nowrap',
    wordWrap: 'break-word',
    opacity: 0.6,
    display: 'flex',
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
      opacity: 1,
      boxShadow: '0 0 0 0rem rgba(182,155,142,.0)',
      backgroundColor: Theme.palette.primary.main
    },
    '&:active': {
      boxShadow: 'none',
    },
    '&:focus': {
      boxShadow: '0 0 0 0rem rgba(182,155,142,.0)',
    },
  });
  
  const ButtonItemOutlined = styled(Button)({
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 14,
    fontWeight: '600',
    lineHeight: 0,
    flexWrap: 'nowrap',
    wordWrap: 'break-word',
    opacity: 0.75,
    display: 'flex',
    color: Theme.palette.primary.main,
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
      opacity: 1,
      boxShadow: 2,
      backgroundColor: 'white',
      borderColor: 'white'
    },
    '&:active': {
      boxShadow: 'none',
    },
    '&:focus': {
      boxShadow: '0 0 0 0rem rgba(182,155,142,.0)',
    },
  });