import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import NewArticleContainer from './NewArticleContainer'

export default function NewArticle({ article, open, close }) {

    return (
        <Modal
            open={open}
            onClose={close}
        >
            <Box 
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    bgcolor: 'background.paper',
                    boxShadow: 12,
                    borderRadius: '8px',
                    overflow:'scroll',
                    height:'95%',
                    display:'block'
                }}
            >
                <NewArticleContainer 
                    editedArticle={article} 
                    onClose={close}
                />
            </Box>
        </Modal>
    );
    
}