export default class Care {

    constructor(
      id,
      createdAt, 
      title, 
      description,
      shortDescription,
      purpose,
      duration,
      repeats = [], 
      dayTime,
      isBasicCare,
      imageUrl = "",
      products = [],
      notes = "",
      steps = [],
      suggestedNextCare = ""
    ) {
      this.id = id;
      this.createdAt = createdAt
      this.title = title;
      this.description = description;
      this.shortDescription = shortDescription;
      this.purpose = purpose;
      this.duration = duration;
      this.repeats = repeats;
      this.dayTime = dayTime;
      this.isBasicCare = isBasicCare;
      this.imageUrl = imageUrl;
      this.products = products;
      this.notes = notes;
      this.steps = steps;
      this.suggestedNextCare = suggestedNextCare;
    }

}