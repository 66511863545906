import * as React from "react";
import Theme from "../theme";
import {
  Box,
  InputBase,
  IconButton,
  Chip
} from "@mui/material";
import { Search } from "@mui/icons-material";

export function SearchBar({ placeholder = "Search", onChange, sx }) {

    return (
        <Box
            display="flex"
            alignItems="center"
            borderRadius="4px"
            backgroundColor={Theme.palette.background.lightGray}
            padding="4px"
            width="100%"
            height="38px"
            sx={sx}
        >
            <IconButton sx={{ p: "10px" }} aria-label="search">
                <Search />
            </IconButton>

            <InputBase
                onChange={onChange}
                sx={{ ml: 1, flex: 1, fontSize: 15 }}
                placeholder={placeholder}
                inputProps={{ "aria-label": "search" }}
            />
        </Box>
    )
}

export function TagsSearchBar({ placeholder = "Search", onChange, sx }) {

    // MARK: - Stored Properties

    const [tags, setTags] = React.useState([]);
    const [currentTag, setCurrentTag] = React.useState('');

    // MARK: - Methods

    const handleInputChange = (event) => {
        const inputText = event.target.value;
        if (inputText.endsWith(',')) {
            const newTag = inputText.slice(0, -1).trim();
            if (newTag) {
                const newTags = [...tags, newTag];
                setTags(newTags);
                onChange(newTags);
            }
            setCurrentTag('');
        } else {
            setCurrentTag(inputText);
        }
    };

    const handleRemoveTag = (tagToRemove) => {
        const updatedTags = tags.filter(tag => tag !== tagToRemove);
        setTags(updatedTags);
        onChange(updatedTags);
    };

    const handleBackspace = () => {
        if (tags.length > 0) {
          const updatedTags = tags.slice(0, tags.length - 1);
          setTags(updatedTags);
          onChange(updatedTags);
        }
    };

    // MARK: - Views

    return (
        <Box
            display="flex"
            alignItems="center"
            borderRadius="4px"
            backgroundColor={Theme.palette.background.lightGray}
            padding="4px"
            width="100%"
            height="38px"
            sx={sx}
        >
            <IconButton sx={{ p: "10px" }} aria-label="search">
                <Search />
            </IconButton>

            <div sx={{ 
                display: 'flex', 
                flexWrap: 'wrap', 
                gap: '4px', 
                flex: 1, 
                alignItems: 'center' 
                }}
            >
                {tags.map((tag, index) => (
                    <Chip
                        key={index}
                        label={tag}
                        sx={{ 
                            height: '26px', 
                            fontSize: '15px', 
                            fontWeight: 'medium', 
                            backgroundColor: Theme.palette.darkBrown, 
                            color: 'white', 
                            mr: 0.5 
                        }}
                        onDelete={() => handleRemoveTag(tag)}
                    />
                ))}
                <InputBase
                    value={currentTag}
                    onChange={handleInputChange}
                    onKeyDown={(e) => {
                        if (e.key === 'Backspace' && currentTag === '') {
                          e.preventDefault();
                          handleBackspace();
                        }
                      }}
                    sx={{ 
                        ml: 1,
                        fontSize: 15, 
                        flex: 1 
                    }}
                    placeholder={placeholder}
                    inputProps={{ "aria-label": "search" }}
                />
            </div>
        </Box>
    );
}