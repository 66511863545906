import * as React from 'react';
import Drawer from '@mui/material/Drawer';
import { Stack, Typography, List, ListItemButton, ListSubheader, Collapse, ListItemText, IconButton } from '@mui/material';
import BasicCard from '../../views/BasicCard'
import { Close, NotificationsNone } from '@mui/icons-material';
import { Box } from '@mui/system';
import theme from '../../theme';

export default function Notifications({ open, onClose, notifications }) {

    const handleClose = () => {
        onClose()
    }

    return (
        <React.Fragment>
            <Drawer
                anchor="right"
                open={open}
                onClose={handleClose}
            >
                <Box
                    direction="column"
                    display="flex"
                    justifyContent="center"
                    alignItems="flex-start"
                    sx={{ backgroundColor: theme.palette.background.lightGray, height: '100%', width: '400px' }}
                >
                    <List
                        sx={{ width: '400px', backgroundColor: 'clear', alignItems: 'center' }}
                        component="nav"
                        aria-labelledby="nested-list-subheader"
                        subheader={
                            <ListSubheader 
                                sx={{
                                    backgroundColor: theme.palette.primary.main,
                                    mb: 4,
                                    boxShadow: 2
                                }}
                            >
                                <Stack
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    spacing={2}
                                    sx={{ height: '54px' }}
                                >
                                    <Typography fontSize="19px" color="white" fontWeight="medium">
                                        Notifications
                                    </Typography>

                                    <IconButton>
                                        <Close sx={{ color: 'white' }} onClick={handleClose}/> 
                                    </IconButton>
                                </Stack>
                            </ListSubheader>
                        }
                        >
                        {notifications == null ?
                            <Stack
                                direction="column"
                                justifyContent="center"
                                alignItems="center"
                                sx={{ height: '95vh', mt: '-54px' }}
                                spacing={2}
                            >
                                <NotificationsNone sx={{ height: '40px', width: '40px', fontWeight: 'thin' }}/> 

                                <Typography variant="body1">
                                    No notifications yet
                                </Typography>
                            </Stack>
                            : 
                            notifications.map(element => {
                                return <BasicCard 
                                    header={element.category} 
                                    title={element.title} 
                                    message={element.message} 
                                />
                            })
                        }
                    </List>
                </Box>
            </Drawer>
        </React.Fragment>
    );
}