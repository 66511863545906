import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Theme from '../../theme'

import { useEffect } from 'react';
import firebase from 'firebase/compat/app';
import { ThemeProvider } from '@mui/material/styles';

import { signInWithEmailAndPassword } from '../../services/firebase';
import { Stack } from '@mui/material';
import Copyrights from '../../views/Copyrights'
import LogoImage from '../../utils/logo';

export default function SignIn({ setUser, setLoading, onLogin }) {

  const [email, setEmail] = React.useState("")
  const [password, setPassword] = React.useState("")

  // MARK: - User auth state
  useEffect(() => {
    firebase.auth().onAuthStateChanged(user => {
      setUser(user);

      if (user != null) {
        setLoading(true)

        setTimeout(() => { 
            setLoading(false) 
            onLogin()
          }, 1500
        );
      }
    })
  },[])

  const handleEmailChange = (event) => {
    setEmail(event.target.value)
  }

  const handlePasswordChange = (event) => {
    setPassword(event.target.value)
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const email = data.get('email')
    const password = data.get('password')
    signInWithEmailAndPassword(email, password)
  };

  return (
    <ThemeProvider theme={Theme}>
      <Box
        display="flex" 
        height="100vh"
        width="100vw"
      >
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Stack
            height="100vh"
            direction="column"
            justifyContent="space-between"
          >
            <Box
              sx={{ mt: 16 }}
            >
              <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                sx={{ mb: 3 }}
              >
                <LogoImage size={100}/>
                <Typography fontFamily="Copperplate" fontSize="32px" fontWeight="light">
                  Hairviour
                </Typography>
                <Typography fontSize="14px" fontWeight="medium" letterSpacing="6.2px" sx={{ ml: 1 }}>
                  Central Panel
                </Typography>
              </Stack>
              <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                <TextField
                  margin="normal"
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  value={email}
                  onChange={handleEmailChange}
                />
                <TextField
                  margin="normal"
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  value={password}
                  onChange={handlePasswordChange}
                />
                <FormControlLabel
                  control={<Checkbox value="remember" color="primary" />}
                  label="Remember me"
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  size="large"
                  sx={{ mt: 3, mb: 2 }}
                  disabled={email.length == 0 || password.length == 0}
                >
                  Sign In
                </Button>
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing="3.5px"
                >
                  <Typography variant='body2'>
                    Don't have an account? Please 
                  </Typography>
                  <Link 
                      href="#" 
                      variant="body2"
                      onClick={() => window.location = 'mailto:admin@panel.hairviour.com'}
                    >
                      contact the administrator
                    </Link>
                </Stack>
                {/* <Grid container>
                  <Grid item xs>
                    <Link 
                      href="#" 
                      variant="body2"
                    >
                      Forgot password?
                    </Link>
                  </Grid>
                  <Grid item>
                    <Link 
                      href="#" 
                      variant="body2"
                    >
                      Don't have an account?
                    </Link>
                  </Grid>
                </Grid> */}
              </Box>
            </Box>
            <Copyrights sx={{ pb: 4 }} />
          </Stack>
        </Container>
      </Box>
    </ThemeProvider>
  );
}