import { red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: '#68594b',
      dark: '#615447',
      light: '#756454'
    },
    secondary: {
      main: '#b69b8e',
    },
    darkBrown: '#68594B',
    tindedOrange: '#DFC7B5',
    cream: '#DAD0C5',
    creamyGray: '#C0B7B1',
    creamyBrown: '#B69B8E',
    background: {
      lightGray: '#f3f3f3',
    },
    error: {
      main: red.A400,
    },
  },
});

export default theme;
