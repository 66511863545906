import * as React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import PropTypes from 'prop-types';
import Theme from '../../../theme'
import InputAdornment from '@mui/material/InputAdornment';
import { FormControlLabel, MenuItem, TextField, Typography, Grid, Checkbox, Divider, Tab, Tabs, Box, Button } from '@mui/material';
import Care from '../../../models/Care';
import Repeats from '../../../models/Repeats';
import measureText from '../../../utils/textWidth';
import { useState } from 'react';

export default class NewCareBasics extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.care.id ?? "",
            title: this.props.care.title ?? "",
            description: this.props.care.description ?? "",
            shortDescription: this.props.care.shortDescription ?? "",
            purpose: this.props.care.purpose ?? "",
            duration: this.props.care.duration ?? "",
            repeats: this.props.care.repeats ?? [],
            dayTime: this.props.care.dayTime ?? "",
            isBasicCare: this.props.care.isBasicCare ?? 0,
            imageUrl: this.props.care.imageUrl ?? "",
            correctLength: true,
            userRole: this.props.user.role
        };
    }

    handleTitleChange = (evt) => {
        const titleLength = measureText(evt.target.value, 17)
        if (titleLength < 240) {
            this.setState({ correctLength: true });
            this.setState({ title: evt.target.value });
            const care = new Care(
                this.state.id,
                "",
                evt.target.value,
                this.state.description,
                this.state.shortDescription,
                this.state.purpose,
                this.state.duration,
                this.state.repeats,
                this.state.dayTime,
                this.state.isBasicCare,
                this.state.imageUrl
            )
            this.sendDataToParent(care)
        } else {
            this.setState({ correctLength: false });
        }
    }

    handleDescriptionChange = (evt) => {
        this.setState({ description: evt.target.value });
        const care = new Care(
            this.state.id,
            "",
            this.state.title,
            evt.target.value,
            this.state.shortDescription,
            this.state.purpose,
            this.state.duration,
            this.state.repeats,
            this.state.dayTime,
            this.state.isBasicCare,
            this.state.imageUrl
        )
        this.sendDataToParent(care)
    }

    handleShortDescriptionChange = (evt) => {
        this.setState({ shortDescription: evt.target.value });
        const care = new Care(
            this.state.id,
            "",
            this.state.title,
            this.state.description,
            evt.target.value,
            this.state.purpose,
            this.state.duration,
            this.state.repeats,
            this.state.dayTime,
            this.state.isBasicCare,
            this.state.imageUrl
        )
        this.sendDataToParent(care)
    }

    handlePurposeChange = (evt) => {
        this.setState({ purpose: evt.target.value });
        const care = new Care(
            this.state.id,
            "",
            this.state.title,
            this.state.description,
            this.state.shortDescription,
            evt.target.value,
            this.state.duration,
            this.state.repeats,
            this.state.dayTime,
            this.state.isBasicCare,
            this.state.imageUrl
        )
        this.sendDataToParent(care)
    }

    handleDurationChange = (evt) => {
        this.setState({ duration: evt.target.value });
        const care = new Care(
            this.state.id,
            "",
            this.state.title,
            this.state.description,
            this.state.shortDescription,
            this.state.purpose,
            evt.target.value,
            this.state.repeats,
            this.state.dayTime,
            this.state.isBasicCare,
            this.state.imageUrl
        )
        this.sendDataToParent(care)
    }

    handleRepeatsChange(dates) {
        this.setState({ repeats: dates });
        const care = new Care(
            this.state.id,
            "",
            this.state.title,
            this.state.description,
            this.state.shortDescription,
            this.state.purpose,
            this.state.duration,
            dates,
            this.state.dayTime,
            this.state.isBasicCare,
            this.state.imageUrl
        )
        this.sendDataToParent(care)
    }

    handleDayTimeChange = (newValue) => {
        this.setState({ dayTime: newValue });
        const care = new Care(
            this.state.id,
            "",
            this.state.title,
            this.state.description,
            this.state.shortDescription,
            this.state.purpose,
            this.state.duration,
            this.state.repeats,
            newValue,
            this.state.isBasicCare,
            this.state.imageUrl
        )
        this.sendDataToParent(care)
    }

    handleImageUrlChange = (evt) => {
        this.setState({ imageUrl: evt.target.value });
        const care = new Care(
            this.state.id,
            "",
            this.state.title,
            this.state.description,
            this.state.shortDescription,
            this.state.purpose,
            this.state.duration,
            this.state.repeats,
            this.state.dayTime,
            this.state.isBasicCare,
            evt.target.value
        )
        this.sendDataToParent(care)
    }

    handleIsBasicCareChange = (evt) => {
        this.setState({ isBasicCare: evt.target.value });
        const value = this.state.isBasicCare == 0 ? 1 : 0
        const care = new Care(
            this.state.id,
            "",
            this.state.title,
            this.state.description,
            this.state.shortDescription,
            this.state.purpose,
            this.state.duration,
            this.state.repeats,
            this.state.dayTime,
            value,
            this.state.imageUrl
        )
        this.sendDataToParent(care)
    }

    // MARK: - Repeats

    handleTabIndexChange = (newValue) => {
        const repeats = new Repeats(
            this.props.repeats.selectedWeekDays,
            this.props.repeats.selectedWeeksNumber,
            this.props.repeats.selectedDate,
            newValue
        )
        this.props.repeatsCallback(repeats)
    }

    handleWeekDaysChange = (newValue) => {
        const repeats = new Repeats(
            newValue,
            this.props.repeats.selectedWeeksNumber,
            this.props.repeats.selectedDate,
            this.props.repeats.selectedTabIndex
        )
        this.props.repeatsCallback(repeats)
    }

    handleWeeksNumberChange = (newValue) => {
        const repeats = new Repeats(
            this.props.repeats.selectedWeekDays,
            newValue,
            this.props.repeats.selectedDate,
            this.props.repeats.selectedTabIndex
        )
        this.props.repeatsCallback(repeats)
    }

    handleDateChange = (newValue) => {
        const repeats = new Repeats(
            this.props.repeats.selectedWeekDays,
            this.props.repeats.selectedWeeksNumber,
            newValue,
            this.props.repeats.selectedTabIndex
        )
        this.props.repeatsCallback(repeats)
    }

    // MARK: - Helpers

    sendDataToParent = (care) => {
        this.props.parentCallback(care)
    }

    render() {
        const care = this.props.care
        const repeats = this.props.repeats
        
        return (
            <ThemeProvider theme={Theme}>
                <React.Fragment>
                    <Typography variant="h6" gutterBottom>
                        Please provide a basics information
                    </Typography>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <TextField
                                required
                                id="title"
                                name="title"
                                label="Title"
                                fullWidth
                                autoComplete="title"
                                variant="standard"
                                autoFocus="true"
                                error={this.state.correctLength == false}
                                helperText={this.state.correctLength ? "" : "You're reach the max length of the title"}
                                value={(care.title == "" || care.title == null) ? this.state.title : care.title}
                                onChange={this.handleTitleChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                multiline
                                id="description"
                                name="description"
                                label="Description"
                                fullWidth
                                autoComplete="description"
                                value={(care.description == "" || care.description == null) ? this.state.description : care.description}
                                onChange={this.handleDescriptionChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                multiline
                                id="shortDescription"
                                name="short description"
                                label="Short description"
                                fullWidth
                                autoComplete="short description"
                                value={(care.shortDescription == "" || care.shortDescription == null) ? this.state.shortDescription : care.shortDescription}
                                onChange={this.handleShortDescriptionChange}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                id="purpose"
                                select
                                label="Purpose"
                                endAdornment={<InputAdornment position="end">min</InputAdornment>}
                                value={(care.purpose == "" || care.purpose == null) ? this.state.purpose : care.purpose}
                                onChange={this.handlePurposeChange}
                                helperText="Please select purpose"
                                sx={{ mb: -2 }}
                                >   
                                {purposes.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                multiline
                                id="duration"
                                name="duration"
                                label="Duration (ex. 15 min, 1 h)"
                                fullWidth
                                autoComplete="duration"
                                value={(care.duration == "" || care.duration == null) ? this.state.duration : care.duration}
                                onChange={this.handleDurationChange}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Divider color={Theme.palette.secondary.main} />
                        </Grid>

                        <Grid item xs={12}>
                            <Typography variant="h7" fontWeight="medium" sx={{ marginBottom: 1 }}>
                                Time
                            </Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <Stack
                                direction="column"
                                spacing={1}
                            >
                                <Stack
                                    direction="column"
                                    spacing={2}
                                    padding={2}
                                    sx={{ backgroundColor: Theme.palette.background.lightGray }}
                                >
                                    <Typography variant="subtitle2" fontWeight="regular" sx={{ marginBottom: 1 }}>
                                        Day Time
                                    </Typography>

                                    <DayTimePicker 
                                        selectedDayTime={(care.dayTime == "" || care.dayTime == null) ? this.state.dayTime : care.dayTime} 
                                        onChange={this.handleDayTimeChange}
                                    />
                                </Stack>

                                <Stack
                                    direction="column"
                                    spacing={2}
                                    padding={2}
                                    sx={{ backgroundColor: Theme.palette.background.lightGray }}
                                >
                                    <Typography variant="subtitle2" fontWeight="regular" sx={{ marginBottom: 1 }}>
                                        Repeats
                                    </Typography>
                                        
                                    <TimePicker 
                                        selectedDayTime={(care.dayTime == "" || care.dayTime == null) ? this.state.dayTime : care.dayTime} 
                                        onSelectedTabIndexChange={this.handleTabIndexChange}
                                        selectedWeekDays={repeats.selectedWeekDays} 
                                        onWeekDaysChange={this.handleWeekDaysChange}
                                        selectedWeeksNumber={repeats.selectedWeeksNumber} 
                                        onWeeksNumberChange={this.handleWeeksNumberChange}
                                        selectedDate={repeats.selectedDate}
                                        onDateChange={this.handleDateChange}
                                    />
                                </Stack>
                            </Stack>
                        </Grid>

                        <Grid item xs={12}>
                            <Divider color={Theme.palette.secondary.main} />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                multiline
                                id="image_url"
                                name="imageUrl"
                                label="Image url"
                                fullWidth
                                autoComplete="image url"
                                value={(care.imageUrl == "" || care.imageUrl == null) ? this.state.imageUrl : care.imageUrl}
                                onChange={this.handleImageUrlChange}
                            />
                        </Grid>

                        {this.state.userRole !== "Expert" ?
                            <Grid item xs={6}>
                                <FormControlLabel
                                    control={
                                        <Checkbox 
                                            color="default" 
                                            name="setAsBasic" 
                                            checked={care.isBasicCare}
                                            onChange={this.handleIsBasicCareChange}
                                        />
                                    }
                                    label="Set this care as a basic"
                                />
                            </Grid>
                            : null
                        }
                    </Grid>
                </React.Fragment>
            </ThemeProvider>
        );
    }

}

const purposes = [
    {
      value: 'Rebuild',
      label: 'Rebuild'
    },
    {
      value: 'Improve condition',
      label: 'Improve condition'
    },
    {
      value: 'Protect',
      label: 'Protect'
    },
    {
      value: 'Nourish',
      label: 'Nourish'
    },
    {
      value: 'Cleanse',
      label: 'Cleanse'
    },
    {
      value: 'Moisturize',
      label: 'Moisturize'
    },
    {
      value: 'Increase volume',
      label: 'Increase volume'
    }
];

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Stack } from '@mui/system';

export function TimePicker({ selectedDayTime, onSelectedTabIndexChange, selectedWeekDays, onWeekDaysChange, selectedWeeksNumber, onWeeksNumberChange, selectedDate, onDateChange }) {

    const weekdays = ["S", "M", "T", "W", "T", "F", "S"]
    const weekdaysIndexes = [0, 1, 2, 3, 4, 5, 6]
    const [weekDays, setWeekDays] = useState(selectedWeekDays ?? [0, 1, 2, 3, 4, 5, 6]);

    const weeklyIndexes = [0, 1, 2, 3, 4, 5, 6]
    const [weeksNumber, setWeeksNumber] = useState(selectedWeeksNumber ?? 0);

    const [value, setValue] = useState('one');
    

    const handleChange = (event, newValue) => {
        setValue(newValue);
        onSelectedTabIndexChange(newValue)
    };

    const handleWeekDayUpdate = (newValue) => {
        var newWeekdays = []
        if (weekDays.includes(newValue)) {
            newWeekdays = weekDays.filter((item) => item != newValue)
            setWeekDays(newWeekdays)
        } else {
            newWeekdays = [...weekDays, newValue]
            setWeekDays(newWeekdays)
        }
        onWeekDaysChange(newWeekdays)
    };

    const handleWeeksNumberUpdate = (newValue) => {
        setWeeksNumber(newValue)
        onWeeksNumberChange(newValue)
    }

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs 
                    value={value} 
                    onChange={handleChange} 
                    variant="fullWidth"
                    textColor="primary"
                    indicatorColor="primary"
                    centered
                >
                    <Tab label="Daily" {...a11yProps(0)} disabled={(selectedDayTime == "" || selectedDayTime == null || selectedDayTime == undefined)}/>
                    <Tab label="Weekly" {...a11yProps(1)} disabled={(selectedDayTime == "" || selectedDayTime == null || selectedDayTime == undefined)}/>
                    <Tab label="Monthly" {...a11yProps(2)} disabled={(selectedDayTime == "" || selectedDayTime == null || selectedDayTime == undefined)}/>
                </Tabs>
            </Box>

            <TabPanel value={value} index={0}>
                <Stack 
                    direction="row"
                    justifyContent="space-between"
                >
                    <React.Fragment>
                        {weekdaysIndexes
                            .map((index) => (
                                <Button 
                                    variant={weekDays.includes(index) ? "contained" : "outlined"}
                                    onClick={() => handleWeekDayUpdate(index)}
                                >
                                    {weekdays[index]}
                                </Button>
                            )
                        )}
                    </React.Fragment>
                </Stack>
            </TabPanel>

            <TabPanel value={value} index={1}>
                <Stack 
                    direction="column"
                    spacing={1}
                >
                    <Stack 
                        direction="row"
                        justifyContent="space-between"
                    >
                        <React.Fragment>
                            {weeklyIndexes
                                .filter((index) => index != 6)
                                .map((index) => (
                                    <Button 
                                        variant={weeksNumber == index ? "contained" : "outlined"}
                                        onClick={() => handleWeeksNumberUpdate(index)}
                                    >
                                        {index + 1}
                                    </Button>
                                )
                            )}
                        </React.Fragment>
                    </Stack>

                    <Button 
                        variant={weeksNumber == 6 ? "contained" : "outlined"}
                        onClick={() => handleWeeksNumberUpdate(6)}
                    >
                        once every two weeks
                    </Button>
                </Stack>
                
            </TabPanel>
            
            <TabPanel value={value} index={2}>
                <BasicDatePicker 
                    selectedDate={selectedDate} 
                    onChange={(item) => onDateChange(item)}
                />
            </TabPanel>
        </Box>
    );
}

export function DayTimePicker({ selectedDayTime, onChange }) {

    const dayTimes = ["Morning", "Afternoon", "Evening", "All Day"]
    const dayTimeIndexes = [0, 1, 2, 3]
    const [dayTime, setDayTime] = useState(dayTimes.indexOf(selectedDayTime) ?? 0);

    const [value, setValue] = useState('one');
    
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleDayTimesUpdate = (newValue) => {
        setDayTime(newValue)
        onChange(dayTimes[newValue])
    }

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs 
                    value={value} 
                    onChange={handleChange} 
                    variant="fullWidth"
                    textColor="primary"
                    indicatorColor="primary"
                    centered
                >
                    <Tab label="Select Day Time" {...a11yProps(0)} />
                </Tabs>
            </Box>

            <TabPanel value={value} index={0}>
                <Stack 
                    direction="column"
                    spacing={1}
                >
                    <Stack 
                        direction="row"
                        justifyContent="space-between"
                    >
                        <React.Fragment>
                            {dayTimeIndexes
                                .filter((index) => index != 3)
                                .map((index) => (
                                    <Button 
                                        variant={dayTime == index ? "contained" : "outlined"}
                                        onClick={() => handleDayTimesUpdate(index)}
                                    >
                                        {dayTimes[index]}
                                    </Button>
                                )
                            )}
                        </React.Fragment>
                    </Stack>

                    <Button 
                        variant={dayTime == 3 ? "contained" : "outlined"}
                        onClick={() => handleDayTimesUpdate(3)}
                    >
                        {dayTimes[3]}
                    </Button>
                </Stack>
                
            </TabPanel>
        </Box>
    );
}

export function BasicDatePicker({ label, selectedDate, onChange }) {
    
    const [date, setDate] = React.useState(selectedDate ?? new Date());
  
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
            views={['day']}
            label={label ?? "Select date"}
            value={date}
            onChange={(date) => {
                setDate(date);
                onChange(date);
            }}
            renderInput={(params) => <TextField {...params} />}
        />
      </LocalizationProvider>
    );
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`tabpanel-${index}`}
        aria-labelledby={`tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `tab-${index}`,
      'aria-controls': `tabpanel-${index}`,
    };
  }