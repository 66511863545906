export default class Notification {
    constructor(
        id,
        category,
        title,
        message
    ) {
        this.id = id;
        this.category = category;
        this.title = title;
        this.message = message;
    }
}