import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { TableContainer } from '@mui/material';
import Title from './Title';

export default function DashboardArticlesList(props) {

  return (
    <React.Fragment>
      <Title>{props.title}</Title>

      <TableContainer sx={{ maxHeight: 400 }}>
        <Table stickyHeader size="medium">
          <TableHead>
            <TableRow>
              <TableCell>Created at</TableCell>
              <TableCell>Title</TableCell>
              <TableCell>Author</TableCell>
              <TableCell>Sections count</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.items.map((article) => (
              <TableRow key={article.id}>
                <TableCell>{article.createdAt}</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>{article.title}</TableCell>
                <TableCell>{article.author}</TableCell>
                <TableCell>{article.sections.length}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  );
}