import * as React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Theme from '../../../theme'
import { Button, IconButton, Stack, Box, Paper } from '@mui/material';
import TextField from '@mui/material/TextField'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import Care from '../../../models/Care';
import Step from '../../../models/Step';
import Product from '../../../models/Product';

export default class NewCareDetails extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            productTitle: "",
            productSubtitle: "",
            productImageUrl: "",
            productPageUrl: "",
            stepTitle: "",
            stepDescription: "",
            products: this.props.care.products ?? [],
            notes: this.props.care.notes ?? "",
            steps: this.props.care.steps ?? [],
            suggestedNextCare: this.props.care.suggestedNextCare ?? ""
        };
    }

    handleProductTitleChange = (evt) => {
        this.setState({ productTitle: evt.target.value });
    }

    handleProductSubtitleChange = (evt) => {
        this.setState({ productSubtitle: evt.target.value });
    }

    handleProductImageURLChange = (evt) => {
        this.setState({ productImageUrl: evt.target.value });
    }

    handleProductURLChange = (evt) => {
        this.setState({ productPageUrl: evt.target.value });
    }

    handleProductAdd = () => {
        const id = this.state.products.length
        
        const newProducts = this.state.products.filter((item) => item.id !== id);
        alert(`1:${id} 2:${this.state.productTitle} 3:${this.state.productImageUrl} 4:${this.state.productPageUrl}`)
        newProducts.push(
            new Product(
                id, 
                this.state.productTitle, 
                this.state.productSubtitle, 
                this.state.productImageUrl,
                this.state.productPageUrl
            )
        );

        this.setState({ products: newProducts })

        this.state.productTitle = "";
        this.state.productSubtitle = "";
        this.state.productImageUrl = "";
        this.state.productPageUrl = "";

        const care = new Care(
            "",
            "",
            "", 
            "",
            "",
            "",
            "",
            [],
            "",
            0,
            "",
            newProducts,
            this.state.notes,
            this.state.steps,
            this.state.suggestedNextCare
        );
        this.sendDataToParent(care)
      }

    handleProductRemove(id) {
        const newProducts = this.state.products.filter((item) => item.id !== id);
        this.setState({ products: newProducts });
        const care = new Care(
            "",
            "",
            "", 
            "",
            "",
            "",
            "",
            [],
            "",
            0,
            "",
            newProducts,
            this.state.notes,
            this.state.steps,
            this.state.suggestedNextCare
        );
        this.sendDataToParent(care)
    }

    handleNotesChange = (evt) => {
        this.setState({ notes: evt.target.value });

        const care = new Care(
            "",
            "",
            "", 
            "",
            "",
            "",
            "",
            [],
            "",
            0,
            "",
            this.state.products,
            evt.target.value,
            this.state.steps,
            this.state.suggestedNextCare
        );
        this.sendDataToParent(care)
    }

    handleStepTitleChange = (evt) => {
        this.setState({ stepTitle: evt.target.value });
    }

    handleStepDescripitonChange = (evt) => {
        this.setState({ stepDescription: evt.target.value });
    }

    handleStepAdd = () => {
        const { stepTitle, stepDescription, steps } = this.state;
        const id = steps.length;
        
        const newSteps = steps.filter((item) => item.id !== id);
        newSteps.push(new Step(id, stepTitle, stepDescription));

        this.setState({ steps: newSteps });
        this.state.stepTitle = "";
        this.state.stepDescription = "";
        const care = new Care(
            "",
            "",
            "", 
            "",
            "",
            "",
            "",
            [],
            "",
            0,
            "",
            this.state.products,
            this.state.notes,
            newSteps,
            this.state.suggestedNextCare
        );
        this.sendDataToParent(care)
    }

    handleStepRemove(id) {
        const newSteps = this.state.steps.filter((item) => item.id !== id);
        this.setState({ steps: newSteps })
        const care = new Care(
            "",
            "",
            "", 
            "",
            "",
            "",
            "",
            [],
            "",
            0,
            "",
            this.state.products,
            this.state.notes,
            newSteps,
            this.state.suggestedNextCare
        )
        this.sendDataToParent(care)
    }

    handleSuggestedNextCareChange = (evt) => {
        this.setState({ suggestedNextCare: evt.target.value });

        const care = new Care(
            "",
            "",
            "", 
            "",
            "",
            "",
            "",
            [],
            "",
            0,
            "",
            this.state.products,
            this.state.notes,
            this.state.steps,
            evt.target.value
        )
        this.sendDataToParent(care)
    }

    sendDataToParent = (care) => {
        this.props.parentCallback(care)
    }

    render() {
        const care = this.props.care
        
        const Item = ({ item, onRemove }) => (
            <React.Fragment>
                <Grid item xs={12} >
                    <Box sx={{ flexGrow: 1 }} >
                        <Paper 
                            elevation={2}
                            sx={{
                                p: 1,
                                flexGrow: 1
                            }}
                        >
                            <Stack
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <TextField
                                    required
                                    multiline
                                    id="id"
                                    name="id"
                                    label=""
                                    autoComplete="id"
                                    value={item.id}
                                    disabled={true}
                                    // onChange={(value) => this.handleSectionIdChange(item.id, value)}
                                    sx={{ marginRight: 2, width: 40 }}
                                />

                                <Stack
                                    direction="column"
                                    justifyContent="space-between"
                                    alignItems="center"
                                >
                                    <Typography sx={{ width: 400, fontWeight: "medium" }}>
                                        {item.title}
                                    </Typography>

                                    <Typography sx={{ width: 400 }}>
                                        {item.subtitle ?? ""}
                                    </Typography>
                                </Stack>
                                
    
                                <IconButton 
                                    aria-label="delete" 
                                    color="error" 
                                    onClick={() => onRemove(item.id)}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </Stack>
                        </Paper>
                    </Box>
                </Grid>
            </React.Fragment>
        );
    
        return (
            <ThemeProvider theme={Theme}>
            <React.Fragment>
                <Grid container>
                    <Grid item xs={12} sx={{ marginBottom: 1 }}>
                        <Typography variant="h7" fontWeight="medium" sx={{ marginBottom: 1 }}>
                            Recommended products
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sx={{ marginBottom: 3 }}>
                        <Box sx={{ border: 1, p: 2, borderRadius: 1, borderColor: '#c4c4c4', flexGrow: 1 }}>
                            <Grid 
                                container
                                spacing={2}
                                sx={{ mb: 3 }}
                            >
                                <React.Fragment>
                                    {((care.products == [] || care.products == null) ? this.state.products : care.products)
                                        .map((item) => (
                                            <Item 
                                                key={item.id} 
                                                item={item} 
                                                onRemove={() => this.handleProductRemove(item.id)} 
                                            />
                                        ))
                                    }
                                </React.Fragment>
                            </Grid>

                            <Stack
                                direction="column"
                                justifyContent="space-between"
                                alignItems="flex-end"
                            >
                                <TextField
                                    id="product_title"
                                    name="productTitle"
                                    label="Product title"
                                    autoComplete="product title"
                                    variant="standard"
                                    multiline
                                    fullWidth
                                    required
                                    value={this.state.productTitle}
                                    onChange={this.handleProductTitleChange}
                                    sx={{ mb: 2 }}
                                />

                                <TextField
                                    id="product_subtitle"
                                    name="productSubtitle"
                                    label="Product subtitle"
                                    autoComplete="product subtitle"
                                    variant="standard"
                                    multiline
                                    fullWidth
                                    required
                                    value={this.state.productSubtitle}
                                    onChange={this.handleProductSubtitleChange}
                                    sx={{ mb: 2 }}
                                />

                                <TextField
                                    id="product_imageURL"
                                    name="productImageURL"
                                    label="Product image url"
                                    autoComplete="product image url"
                                    variant="standard"
                                    multiline
                                    fullWidth
                                    required
                                    value={this.state.productImageUrl}
                                    onChange={this.handleProductImageURLChange}
                                    sx={{ mb: 2 }}
                                />

                                <TextField
                                    id="product_pageURL"
                                    name="productPageURL"
                                    label="Product page url"
                                    autoComplete="product page url"
                                    variant="standard"
                                    multiline
                                    fullWidth
                                    required
                                    value={this.state.productPageUrl}
                                    onChange={this.handleProductURLChange}
                                />
    
                                <Button 
                                    variant="contained" 
                                    startIcon={<AddIcon />}
                                    disabled={this.state.productTitle == "" || this.state.productPageUrl == "" || this.state.productImageUrl == ""}
                                    onClick={this.handleProductAdd}
                                    sx={{ mt: 2 }}
                                >
                                    Add Product
                                </Button>
                            </Stack>
                        </Box>
                    </Grid>

                    <Grid item xs={12} sx={{ marginBottom: 1 }}>
                        <Typography variant="h7" fontWeight="medium">
                            Important notes
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sx={{ marginBottom: 3 }}>
                        <TextField
                            multiline
                            id="notes"
                            name="notes"
                            label="Type here..."
                            autoComplete="notes"
                            fullWidth
                            value={this.state.notes}
                            onChange={this.handleNotesChange}
                        />
                    </Grid>

                    <Grid item xs={12} sx={{ marginBottom: 1 }}>
                        <Typography variant="h7" fontWeight="medium">
                            Steps
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sx={{ marginBottom: 3 }}>
                        <Box sx={{ border: 1, p: 2, borderRadius: 1, borderColor: '#c4c4c4', flexGrow: 1 }}>
                            <Grid 
                                container
                                spacing={2}
                                sx={{ mb: 3 }}
                            >
                                <React.Fragment>
                                    {((care.steps == [] || care.steps == null) ? this.state.steps : care.steps)
                                        .map((item) => (
                                            <Item 
                                                key={item.id} 
                                                item={item} 
                                                onRemove={() => this.handleStepRemove(item.id)} 
                                            />
                                        ))
                                    }
                                </React.Fragment>
                            </Grid>
                            <Stack
                                direction="column"
                                justifyContent="space-between"
                                alignItems="flex-end"
                            >
                                <TextField
                                    id="step_title"
                                    name="stepTitle"
                                    label="Step title"
                                    autoComplete="step title"
                                    variant="standard"
                                    multiline
                                    fullWidth
                                    required
                                    value={this.state.stepTitle}
                                    onChange={this.handleStepTitleChange}
                                />

                                <TextField
                                    id="step_description"
                                    name="stepDescription"
                                    label="Step description"
                                    autoComplete="step description"
                                    variant="standard"
                                    multiline
                                    fullWidth
                                    value={this.state.stepDescription}
                                    onChange={this.handleStepDescripitonChange}
                                    sx={{ mt: 2 }}
                                />
    
                                <Button 
                                    variant="contained" 
                                    startIcon={<AddIcon />}
                                    disabled={this.state.stepTitle == ""}
                                    onClick={this.handleStepAdd}
                                    sx={{ mt: 2 }}
                                >
                                    Add Step
                                </Button>
                            </Stack>
                        </Box>
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            multiline
                            id="suggested"
                            name="suggested"
                            label="Suggested next care (key tag)"
                            autoComplete="suggested next care"
                            fullWidth
                            value={this.state.suggestedNextCare}
                            onChange={this.handleSuggestedNextCareChange}
                        />
                    </Grid>
                </Grid>
            </React.Fragment>
        </ThemeProvider>
        );
    }

}