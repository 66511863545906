import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';

export default function FilterSelector({ 
    tags, 
    onValueChange, 
    title = "Filter",
    multiple = false, 
    disabled = false
}) {

    const [selectedValue, setValue] = React.useState([]);

    const handleChange = (event) => {
        const { target: { value } } = event;
        if (multiple) {
            setValue(typeof value === 'string' ? value.split(',') : value);
            onValueChange(value)
        } else {
            if (selectedValue.includes(value)) {
                const filteredValues = selectedValue.filter(item => item !== value)
                setValue(filteredValues)
            } else {
                setValue(typeof value === 'string' ? value.split(',') : value);
            }
            onValueChange(value)
        }
    };

    return (
        <FormControl 
            sx={{ minWidth: 105 }} 
            size="small"
        >
            <Select
                multiple={multiple}
                displayEmpty
                value={selectedValue}
                input={<OutlinedInput />}
                onChange={handleChange}
                renderValue={(selected) => {
                    if (selected.length === 0) {
                      return <div>{title}</div>;
                    }
                    return selected.join(', ');
                  }}
                autoWidth
                sx={{ fontSize: '13px' }}
                disabled={disabled}
            >
            {tags.map((tag) => (
                <MenuItem key={tag} value={tag} sx={{ fontSize: '10px' }}>
                <Checkbox checked={selectedValue.indexOf(tag) > -1} sx={{ width: 10, height: 25, mr: 1 }}/>
                <ListItemText primary={tag} primaryTypographyProps={{ style: { fontSize: '13px' } }}/>
                </MenuItem>
            ))}
            </Select>
        </FormControl>
    );
}