import * as React from "react";
import { ThemeProvider } from "@mui/material/styles";
import Theme from "../../../theme";
import {
  TextField,
  Typography,
  Grid,
  Box,
  Button,
  IconButton,
  Stack,
  ButtonGroup,
} from "@mui/material";
import { Add, Delete, InfoOutlined } from "@mui/icons-material";
import User from "../../../models/User";
import { db, auth } from "../../../services/firebase";
import { ReactComponent as MaleImage } from "../../../img/bgZECbaWftRRgvPmRFbStTNRjtv1.svg";
import { ReactComponent as FemaleImage } from "../../../img/039RNfPbODPb4C7KLEfWYNnpPXz2.svg";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { BasicDatePicker } from "../../cares/newCare/NewCareBasics";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { StatusLabel, StatusLabelType } from "../../../views/StatusLabel";
import { HairQuestionaireDetailsModal } from "../../../views/modals/HairQuestionaireDetailsModal";
import { UserSearchModal } from "../../../views/modals/UserSearchModal";
import { getDateMonthLater } from "../../../utils/extensions";
import HairCarePlanInfo from "../../../models/plan/HairCarePlanInfo";
import HairCarePlan from "../../../models/plan/HairCarePlan";
import { getMonthDifference } from "../../../utils/extensions";

export default class NewPlanDetails extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      name: this.props.plan.name ?? "",
      startsAt: this.props.plan.startsAt ?? new Date(),
      endsAt: this.props.plan.endsAt ?? getDateMonthLater(),
      userId: this.props.plan.userId ?? "",
      expertId: this.props.plan.expertId ?? "",
      notes: this.props.plan.notes ?? "",
      user: new User(),
      open: false,
      users: [],
      searchedText: "",
      // localUsers: [],
      availableUserIds: [],
      availableUsers: [],
      openDetails: false,
    };
  }

  componentDidMount() {
    db.ref()
      .child("users")
      .orderByChild("hairCarePlanInfo")
      .startAt(0)
      .once("value", (snapshot) => {
        let users = [];

        snapshot.forEach((data) => {
          const userData = data.val();
          var hairCarePlanInfo = new HairCarePlanInfo;
          var hairCarePlan = new HairCarePlan;

          data.forEach((value) => {
            if (value.key == "hairCarePlanInfo") {
              const planData = value.val();
              const info = new HairCarePlanInfo(
                planData.startDate,
                planData.endDate,
                planData.userId,
                planData.hairData
              );
              hairCarePlanInfo = info;
            }
            if (value.key == "hairCarePlan") {
              const planData = value.val(); // TODO: Check if it should be done with arrays like in AppContainer
              const plan = new HairCarePlan(
                planData.id,
                planData.name, 
                planData.createdAt, 
                planData.startsAt, 
                planData.endsAt, 
                planData.userId,
                planData.expertId,
                planData.notes,
                planData.cares,
                planData.productNotes,
                planData.products,
                planData.supplementNotes,
                planData.supplements,
                planData.treatments,
                planData.countryCode
              )
              hairCarePlan = plan;
            }
          });

          const user = new User(
            data.key,
            userData.email,
            userData.name == undefined ? "No name" : userData.name,
            userData.role == undefined ? "User" : userData.role,
            userData.accountLocked == undefined ? false : userData.accountLocked,
            userData.token,
            hairCarePlanInfo,
            hairCarePlan
          );
          users.push(user);
        });

        const filteredUsers = users.filter(
          (user) => user.hairCarePlanInfo.userId != ""
        );

        const ownedOrUnasignedUsers = filteredUsers.filter(
          (user) => (user.hairCarePlan != undefined || user.hairCarePlan.expertId == auth.currentUser.uid)
        );

        const customer = users.filter((user) => user.id == this.state.userId)[0]
        if (customer != undefined) {
          this.setState({ user: customer })
        }

        this.setState({ 
          users: ownedOrUnasignedUsers,
          // localUsers: ownedOrUnasignedUsers
        });
      });

      db.ref()
        .child("hairCarePlanInfo")
        .on("value", (snapshot) => {
          var hcpInfoUserIds = []
          snapshot.forEach((data) => {
            const id = data.val();
            hcpInfoUserIds.push(id)
          })
          this.setState({ availableUserIds: hcpInfoUserIds })
        })
  }

  handleNameChange = (evt) => {
    this.setState({ name: evt.target.value });
    const plan = new HairCarePlan(
      "",
      evt.target.value,
      "", 
      this.state.startsAt, 
      this.state.endsAt, 
      this.state.userId,
      "",
      this.state.notes,
      [],
      "",
      [],
      "",
      [],
      []
    );
    this.sendDataToParent(plan);
  };

  handleStartsAtChange = (value) => {
    this.setState({ startsAt: value });
    const plan = new HairCarePlan(
      "",
      this.state.name,
      "", 
      value,
      this.state.endsAt, 
      this.state.userId,
      "",
      this.state.notes,
      [],
      "",
      [],
      "",
      [],
      []
    );
    this.sendDataToParent(plan);
  };

  handleEndsAtChange = (value) => {
    this.setState({ endsAt: value });
    const plan = new HairCarePlan(
      "",
      this.state.name,
      "", 
      this.state.startsAt,
      value,
      this.state.userId,
      "",
      this.state.notes,
      [],
      "",
      [],
      "",
      [],
      []
    );
    this.sendDataToParent(plan);
  };

  handleUserIdChange = (value) => {
    this.setState({ userId: value });
    const plan = new HairCarePlan(
      "",
      this.state.name,
      "", 
      this.state.startsAt,
      this.state.endsAt,
      value,
      "",
      this.state.notes,
      [],
      "",
      [],
      "",
      [],
      []
    );
    this.sendDataToParent(plan);
  };

  handleNotesChange = (evt) => {
    this.setState({ notes: evt.target.value });
    const plan = new HairCarePlan(
      "",
      this.state.name,
      "", 
      this.state.startsAt,
      this.state.endsAt,
      this.state.userId,
      "",
      evt.target.value,
      [],
      "",
      [],
      "",
      [],
      []
    );
    this.sendDataToParent(plan);
  };

  sendDataToParent = (plan) => {
    this.props.parentCallback(plan);
  };

  updateSelectedUser = (user) => {
    this.props.onUserUpdate(user);
  };

  handleSelectedDurationChange = (value) => {
    const number = Number(value);
    const date = new Date(Date(this.state.startsAt));
    const newEndDate = new Date(date.setMonth(date.getMonth() + number));
    this.handleEndsAtChange(newEndDate);
  };

  // MARK: - Modal

  handleDetailsOpen = () => {
    this.setState({ openDetails: true });
  };

  handleDetailsClose = (event, reason) => {
    this.setState({ openDetails: false });
  };

  // MARK: - Search Modal

  handleClickOpen = () => {
    let localUsers = this.state.users.filter(user => this.state.availableUserIds.includes(user.id));
    this.setState({ 
      availableUsers: localUsers,
      open: true 
    });
  };

  handleClose = (event, reason) => {
    this.setState({ open: false });
  };

  handleTextChange = (evt) => {
    let value = evt.target.value;
    this.setState({ searchedText: value });
    let localUsers = this.state.users.filter(user => this.state.availableUserIds.includes(user.id));
    const filteredUsers = localUsers.filter(
      (user) =>
        user.email.toLowerCase().includes(value.toLowerCase()) ||
        user.name.toLowerCase().includes(value.toLowerCase())
    );
    this.setState({ availableUsers: filteredUsers });
  };

  handleUserChange = (user) => {
    if (user.id == "") {
      alert(`User id to revert: ${this.state.user.id}`)
      const selectedUserId = this.state.user.id
      db.ref()
        .child(`hairCarePlanInfo/${selectedUserId}`)
        .set(selectedUserId)
    } else {  
      db.ref()
          .child("hairCarePlanInfo")
          .child(user.id)
          .remove()
    }

    this.setState({ user: user });
    this.updateSelectedUser(user)
    this.handleUserIdChange(user.id);
    this.setState({ open: false });
  };

  // MARK: - Views

  render() {
    const plan = this.props.plan;
    const selectedDuration = getMonthDifference(
      plan.startsAt,
      plan.endsAt
    )

    return (
      <ThemeProvider theme={Theme}>
        <React.Fragment>
          <HairQuestionaireDetailsModal
            hcpInfo={this.state.user.hairCarePlanInfo}
            open={this.state.openDetails}
            onClose={this.handleDetailsClose}
          />

          <Grid container spacing={3}>
            <Grid item xs={12} sx={{ mt: 4 }}>
              {(this.state.user.id ?? plan.user.id) == "" ? (
                <Box display="flex" flexDirection="column" justifyContent="flex-start">
                  <Typography variant="h7" fontWeight="medium" sx={{ mb: 2 }}>
                    Select user
                  </Typography>

                  <Button
                    variant="contained"
                    startIcon={<Add />}
                    onClick={this.handleClickOpen}
                  >
                    Add User
                  </Button>

                  <UserSearchModal
                    users={this.state.availableUsers}
                    open={this.state.open}
                    onClose={this.handleClose}
                    onChange={this.handleTextChange}
                    onClick={(user) => this.handleUserChange(user)}
                  />
                </Box>
              ) : (
                <Box display="flex" flexDirection="column">
                  <Typography variant="h7" fontWeight="medium" sx={{ pb: 2 }}>
                    Selected user
                  </Typography>

                  <Box
                    display="flex"
                    alignItems="center"
                    height="40px"
                    justifyContent="space-between"
                  >
                    <Box display="flex" alignItems="center">
                      {this.state.user.name
                        .split(" ")[0]
                        .slice(-1)
                        .toLowerCase() == "a" ? (
                        <FemaleImage width="35px" height="35px" />
                      ) : (
                        <MaleImage width="35px" height="35px" />
                      )}

                      <Stack
                        direction="column"
                        alignItems="flex-start"
                        sx={{ ml: "8px" }}
                      >
                        {this.state.user.name.length > 0 ? (
                          <Box>
                            <Typography variant="body2" lineHeight={1}>
                              {this.state.user.name}
                            </Typography>

                            <Typography variant="caption" color="gray">
                              {this.state.user.email}
                            </Typography>
                          </Box>
                        ) : (
                          <Typography variant="body2">
                            {this.state.user.email}
                          </Typography>
                        )}
                      </Stack>
                    </Box>

                    <Box display="flex" alignItems="center">
                      {this.state.expertId === auth.currentUser.uid ? 
                        <StatusLabel 
                          type={
                            (plan != undefined && plan.expertId == auth.currentUser.uid) ? 
                              StatusLabelType.ASSIGNED : 
                              StatusLabelType.UNASSIGNED
                          } 
                          marginRight={1.5}
                        /> : null
                      }

                      <IconButton onClick={this.handleDetailsOpen}>
                        <InfoOutlined />
                      </IconButton>

                      <IconButton
                        disabled={plan.id != ""}
                        color="error"
                        onClick={() => this.handleUserChange(new User())}
                      >
                        <Delete />
                      </IconButton>
                    </Box>
                  </Box>
                </Box>
              )}
            </Grid>

            <Grid item xs={12} sx={{ mt: 1 }}>
              <Typography variant="h7" fontWeight="medium">
                Basic information
              </Typography>
            </Grid>

            <Grid item xs={12} sx={{ marginBottom: 1 }}>
              <TextField
                required
                id="name"
                name="name"
                label="Name"
                fullWidth
                autoComplete="name"
                variant="standard"
                autoFocus="true"
                value={
                  plan.name == "" || plan.name == null
                    ? this.state.name
                    : plan.name
                }
                onChange={this.handleNameChange}
              />
            </Grid>
            <Grid item xs={12}>
              <BasicDatePicker
                label={"Starts at"}
                selectedDate={
                  plan.startsAt == "" || plan.startsAt == null
                    ? this.state.startsAt
                    : plan.startsAt
                }
                onChange={this.handleStartsAtChange}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2" sx={{ mb: 1 }}>
                Duration
              </Typography>

              <ButtonGroup variant="text" sx={{ mb: 1 }}>
                <Button
                  variant={
                    selectedDuration.includes("1") ? "contained" : "outlined"
                  }
                  sx={{
                    backgroundColor:
                      selectedDuration.includes("1")
                        ? Theme.palette.primary.main
                        : null,
                  }}
                  onClick={() => this.handleSelectedDurationChange(1)}
                >
                  1 Month
                </Button>

                <Button
                  variant={
                    selectedDuration.includes("3") ? "contained" : "outlined"
                  }
                  sx={{
                    backgroundColor:
                      selectedDuration.includes("3")
                        ? Theme.palette.primary.main
                        : null,
                  }}
                  onClick={() => this.handleSelectedDurationChange(3)}
                >
                  3 Months
                </Button>

                <Button
                  variant={
                    selectedDuration.includes("6") ? "contained" : "outlined"
                  }
                  sx={{
                    backgroundColor:
                      selectedDuration.includes("6")
                        ? Theme.palette.primary.main
                        : null,
                  }}
                  onClick={() => this.handleSelectedDurationChange(6)}
                >
                  6 Months
                </Button>
              </ButtonGroup>
            </Grid>
            <Grid item xs={12}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  views={["day"]}
                  label={"Ends at"}
                  value={
                    plan.endsAt == "" || plan.endsAt == null
                      ? this.state.endsAt
                      : plan.endsAt
                  }
                  disabled={true}
                  onChange={(date) => this.handleEndsAtChange(date)}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={12} sx={{ marginBottom: 1 }}>
              <Typography variant="h7" fontWeight="medium">
                Important notes
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <TextField
                multiline
                id="notes"
                name="notes"
                label="Type here..."
                autoComplete="notes"
                fullWidth
                value={
                  plan.notes == "" || plan.notes == null
                      ? this.state.notes
                      : plan.notes
                }
                onChange={this.handleNotesChange}
              />
            </Grid>
          </Grid>
        </React.Fragment>
      </ThemeProvider>
    );
  }
}