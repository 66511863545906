export function getCurrentDate() {

    let newDate = new Date()
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    
    return `${year}${"/"}${month<10?`0${month}`:`${month}`}${"/"}${date}`
}

export function getShortDateString() {

    let newDate = new Date()
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    
    return `${date}${"."}${month<10?`0${month}`:`${month}`}`
}

export function getPastShortDateString() {

    let newDate = new Date(new Date().setDate((new Date()).getDate() - 30))
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    
    return `${date}${"."}${month<10?`0${month}`:`${month}`}`
}