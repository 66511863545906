import * as React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Theme from '../../../theme'
import { Button, IconButton, Stack, Box, Paper } from '@mui/material';
import TextField from '@mui/material/TextField'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import Product from '../../../models/Product';
import Treatment from '../../../models/Treatment';
import HairCarePlan from '../../../models/plan/HairCarePlan';

export default class NewPlanSupplementation extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            productTitle: "",
            productSubtitle: "",
            productImageUrl: "",
            productPageUrl: "",
            supplementTitle: "",
            supplementSubtitle: "",
            supplementImageUrl: "",
            supplementPageUrl: "",
            treatmentTitle: "",
            treatmentSubtitle: "",
            products: this.props.plan.products ?? [],
            productNotes: this.props.plan.productNotes,
            supplements: this.props.plan.supplements ?? [],
            supplementNotes: this.props.plan.supplementNotes,
            treatments: this.props.plan.treatments ?? [],
        };
    }

    // MARK: - Products

    handleProductTitleChange = (evt) => {
        this.setState({ productTitle: evt.target.value });
    }

    handleProductSubtitleChange = (evt) => {
        this.setState({ productSubtitle: evt.target.value });
    }

    handleProductImageURLChange = (evt) => {
        this.setState({ productImageUrl: evt.target.value });
    }

    handleProductURLChange = (evt) => {
        this.setState({ productPageUrl: evt.target.value });
    }

    handleProductAdd = () => {
        const id = this.state.products.length
        
        const newProducts = this.state.products.filter((item) => item.id !== id);
        newProducts.push(
            new Product(
                id, 
                this.state.productTitle,
                this.state.productSubtitle,
                this.state.productImageUrl,
                this.state.productPageUrl
            )
        );

        this.setState({ products: newProducts })

        this.state.productTitle = "";
        this.state.productSubtitle = "";
        this.state.productImageUrl = "";
        this.state.productPageUrl = "";

        const plan = new HairCarePlan(
            "",
            "",
            "", 
            "",
            "",
            "",
            "",
            "",
            [],
            this.state.productNotes,
            newProducts,
            this.state.supplementNotes,
            this.state.supplements,
            this.state.treatments
        );
        this.sendDataToParent(plan)
      }

    handleProductRemove(id) {
        const newProducts = this.state.products.filter((item) => item.id !== id);
        this.setState({ products: newProducts });
        
        const plan = new HairCarePlan(
            "",
            "",
            "", 
            "",
            "",
            "",
            "",
            "",
            [],
            this.state.productNotes,
            newProducts,
            this.state.supplementNotes,
            this.state.supplements,
            this.state.treatments
        );
        this.sendDataToParent(plan)
    }

    handleProductNotesChange = (evt) => {
        this.setState({ productNotes: evt.target.value });

        const plan = new HairCarePlan(
            "",
            "",
            "", 
            "",
            "",
            "",
            "",
            "",
            [],
            evt.target.value,
            this.state.products,
            this.state.supplementNotes,
            this.state.supplements,
            this.state.treatments
        );
        this.sendDataToParent(plan)
    }

    // MARK: - p

    handleSupplementTitleChange = (evt) => {
        this.setState({ supplementTitle: evt.target.value });
    }

    handleSupplementSubtitleChange = (evt) => {
        this.setState({ supplementSubtitle: evt.target.value });
    }

    handleSupplementImageURLChange = (evt) => {
        this.setState({ supplementImageUrl: evt.target.value });
    }

    handleSupplementURLChange = (evt) => {
        this.setState({ supplementPageUrl: evt.target.value });
    }

    handleSupplementAdd = () => {
        const id = this.state.supplements.length
        
        const newProducts = this.state.supplements.filter((item) => item.id !== id);
        newProducts.push(
            new Product(
                id, 
                this.state.supplementTitle, 
                this.state.supplementSubtitle,
                this.state.supplementImageUrl,
                this.state.supplementPageUrl
            )
        );

        this.setState({ supplements: newProducts })

        this.state.supplementTitle = "";
        this.state.supplementSubtitle = "";
        this.state.supplementImageUrl = "";
        this.state.supplementPageUrl = "";

        const plan = new HairCarePlan(
            "",
            "",
            "", 
            "",
            "",
            "",
            "",
            "",
            [],
            this.state.productNotes,
            this.state.products,
            this.state.supplementNotes,
            newProducts,
            this.state.treatments
        );
        this.sendDataToParent(plan)
      }

    handleSupplementRemove(id) {
        const newProducts = this.state.supplements.filter((item) => item.id !== id);
        this.setState({ supplements: newProducts });
        
        const plan = new HairCarePlan(
            "",
            "",
            "", 
            "",
            "",
            "",
            "",
            "",
            [],
            this.state.productNotes,
            this.state.products,
            this.state.supplementNotes,
            newProducts,
            this.state.treatments
        );
        this.sendDataToParent(plan)
    }

    handleSupplementsNotesChange = (evt) => {
        this.setState({ supplementNotes: evt.target.value });

        const plan = new HairCarePlan(
            "",
            "",
            "", 
            "",
            "",
            "",
            "",
            "",
            [],
            this.state.productNotes,
            this.state.products,
            evt.target.value,
            this.state.supplements,
            this.state.treatments
        );
        this.sendDataToParent(plan)
    }

    // MARK: - Treatments

    handleTreatmentTitleChange = (evt) => {
        this.setState({ treatmentTitle: evt.target.value });
    }

    handleTreatmentSubtitleChange = (evt) => {
        this.setState({ treatmentSubtitle: evt.target.value });
    }

    handleTreatmentAdd = () => {
        const { treatmentTitle, treatmentSubtitle, treatments } = this.state;
        const id = treatments.length;
        
        const newTreatments = treatments.filter((item) => item.id !== id);
        newTreatments.push(new Treatment(id, treatmentTitle, treatmentSubtitle));

        this.setState({ treatments: newTreatments });

        this.state.treatmentTitle = "";
        this.state.treatmentSubtitle = "";

        const plan = new HairCarePlan(
            "",
            "",
            "", 
            "",
            "",
            "",
            "",
            "",
            [],
            this.state.productNotes,
            this.state.products,
            this.state.supplementNotes,
            this.state.supplements,
            newTreatments
        );
        this.sendDataToParent(plan)
    }

    handleTreatmentRemove(id) {
        const newTreatments = this.state.steps.filter((item) => item.id !== id);
        this.setState({ treatments: newTreatments })

        const plan = new HairCarePlan(
            "",
            "",
            "", 
            "",
            "",
            "",
            "",
            "",
            [],
            this.state.productNotes,
            this.state.products,
            this.state.supplementNotes,
            this.state.supplements,
            newTreatments
        );
        this.sendDataToParent(plan)
    }

    sendDataToParent = (plan) => {
        this.props.parentCallback(plan)
    }

    // MARK: - Views

    render() {
        const plan = this.props.plan
        
        const Item = ({ item, onRemove }) => (
            <React.Fragment>
                <Grid item xs={12} >
                    <Box sx={{ flexGrow: 1 }} >
                        <Paper 
                            elevation={2}
                            sx={{
                                p: 1,
                                flexGrow: 1
                            }}
                        >
                            <Stack
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <TextField
                                    required
                                    multiline
                                    id="id"
                                    name="id"
                                    label=""
                                    autoComplete="id"
                                    value={item.id}
                                    disabled={true}
                                    // onChange={(value) => this.handleSectionIdChange(item.id, value)}
                                    sx={{ marginRight: 2, width: 40 }}
                                />

                                <Stack
                                    direction="column"
                                    justifyContent="space-between"
                                    alignItems="center"
                                >
                                    <Typography sx={{ width: 400, fontWeight: "medium" }}>
                                        {item.title}
                                    </Typography>

                                    <Typography sx={{ width: 400 }}>
                                        {item.subtitle ?? ""}
                                    </Typography>
                                </Stack>
                                
    
                                <IconButton 
                                    aria-label="delete" 
                                    color="error" 
                                    onClick={() => onRemove(item.id)}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </Stack>
                        </Paper>
                    </Box>
                </Grid>
            </React.Fragment>
        );
    
        return (
            <ThemeProvider theme={Theme}>
            <React.Fragment>
                <Grid container>
                    <Grid item xs={12} sx={{ mb: 1, mt: 4 }}>
                        <Typography variant="h7" fontWeight="medium" sx={{ marginBottom: 1 }}>
                            Recommended products
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sx={{ marginBottom: 3 }}>
                        <Box sx={{ border: 1, p: 2, borderRadius: 1, borderColor: '#c4c4c4', flexGrow: 1 }}>
                            <Grid 
                                container
                                spacing={2}
                                sx={{ mb: 3 }}
                            >
                                <React.Fragment>
                                    {((plan.products == [] || plan.products == null) ? this.state.products : plan.products)
                                        .map((item) => (
                                            <Item 
                                                key={item.id} 
                                                item={item} 
                                                onRemove={() => this.handleProductRemove(item.id)} 
                                            />
                                        ))
                                    }
                                </React.Fragment>
                            </Grid>

                            <Stack
                                direction="column"
                                justifyContent="space-between"
                                alignItems="flex-end"
                            >
                                <TextField
                                    id="product_title"
                                    name="productTitle"
                                    label="Product title"
                                    autoComplete="product title"
                                    variant="standard"
                                    multiline
                                    fullWidth
                                    required
                                    value={this.state.productTitle}
                                    onChange={this.handleProductTitleChange}
                                    sx={{ mb: 2 }}
                                />

                                <TextField
                                    id="product_subtitle"
                                    name="productSubtitle"
                                    label="Product subtitle"
                                    autoComplete="product subtitle"
                                    variant="standard"
                                    multiline
                                    fullWidth
                                    required
                                    value={this.state.productSubtitle}
                                    onChange={this.handleProductSubtitleChange}
                                    sx={{ mb: 2 }}
                                />

                                <TextField
                                    id="product_imageURL"
                                    name="productImageURL"
                                    label="Product image url"
                                    autoComplete="product image url"
                                    variant="standard"
                                    multiline
                                    fullWidth
                                    required
                                    value={this.state.productImageUrl}
                                    onChange={this.handleProductImageURLChange}
                                    sx={{ mb: 2 }}
                                />

                                <TextField
                                    id="product_pageURL"
                                    name="productPageURL"
                                    label="Product page url"
                                    autoComplete="product page url"
                                    variant="standard"
                                    multiline
                                    fullWidth
                                    required
                                    value={this.state.productPageUrl}
                                    onChange={this.handleProductURLChange}
                                />
    
                                <Button 
                                    variant="contained" 
                                    startIcon={<AddIcon />}
                                    disabled={this.state.productTitle == "" || this.state.productSubtitle == "" || this.state.productPageUrl == "" || this.state.productImageUrl == ""}
                                    onClick={this.handleProductAdd}
                                    sx={{ mt: 2 }}
                                >
                                    Add Product
                                </Button>
                            </Stack>
                        </Box>
                    </Grid>

                    <Grid item xs={12} sx={{ marginBottom: 1 }}>
                        <Typography variant="h7">
                            Important notes
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sx={{ marginBottom: 5 }}>
                        <TextField
                            multiline
                            id="product_notes"
                            name="notes"
                            label="Type here..."
                            autoComplete="notes"
                            fullWidth
                            value={this.state.productNotes}
                            onChange={this.handleProductNotesChange}
                        />
                    </Grid>

                    <Grid item xs={12} sx={{ marginBottom: 1 }}>
                        <Typography variant="h7" fontWeight="medium" sx={{ marginBottom: 1 }}>
                            Recommended supplements
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sx={{ marginBottom: 3 }}>
                        <Box sx={{ border: 1, p: 2, borderRadius: 1, borderColor: '#c4c4c4', flexGrow: 1 }}>
                            <Grid 
                                container
                                spacing={2}
                                sx={{ mb: 3 }}
                            >
                                <React.Fragment>
                                    {((plan.supplements == [] || plan.supplements == null) ? this.state.supplements : plan.supplements)
                                        .map((item) => (
                                            <Item 
                                                key={item.id} 
                                                item={item} 
                                                onRemove={() => this.handleSupplementRemove(item.id)} 
                                            />
                                        ))
                                    }
                                </React.Fragment>
                            </Grid>

                            <Stack
                                direction="column"
                                justifyContent="space-between"
                                alignItems="flex-end"
                            >
                                <TextField
                                    id="supplement_title"
                                    name="supplementTitle"
                                    label="Supplement title"
                                    autoComplete="supplement title"
                                    variant="standard"
                                    multiline
                                    fullWidth
                                    required
                                    value={this.state.supplementTitle}
                                    onChange={this.handleSupplementTitleChange}
                                    sx={{ mb: 2 }}
                                />

                                <TextField
                                    id="supplement_subtitle"
                                    name="supplementSubtitle"
                                    label="Supplement subtitle"
                                    autoComplete="supplement subtitle"
                                    variant="standard"
                                    multiline
                                    fullWidth
                                    required
                                    value={this.state.supplementSubtitle}
                                    onChange={this.handleSupplementSubtitleChange}
                                    sx={{ mb: 2 }}
                                />

                                <TextField
                                    id="supplement_imageURL"
                                    name="supplementImageURL"
                                    label="Supplement image url"
                                    autoComplete="supplement image url"
                                    variant="standard"
                                    multiline
                                    fullWidth
                                    required
                                    value={this.state.supplementImageUrl}
                                    onChange={this.handleSupplementImageURLChange}
                                    sx={{ mb: 2 }}
                                />

                                <TextField
                                    id="supplement_pageURL"
                                    name="supplementPageURL"
                                    label="Supplement page url"
                                    autoComplete="supplement page url"
                                    variant="standard"
                                    multiline
                                    fullWidth
                                    required
                                    value={this.state.supplementPageUrl}
                                    onChange={this.handleSupplementURLChange}
                                />
    
                                <Button 
                                    variant="contained" 
                                    startIcon={<AddIcon />}
                                    disabled={this.state.supplementTitle == "" || this.state.supplementSubtitle == "" || this.state.supplementPageUrl == "" || this.state.supplementImageUrl == ""}
                                    onClick={this.handleSupplementAdd}
                                    sx={{ mt: 2 }}
                                >
                                    Add Supplement
                                </Button>
                            </Stack>
                        </Box>
                    </Grid>

                    <Grid item xs={12} sx={{ marginBottom: 1 }}>
                        <Typography variant="h7">
                            Important notes
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sx={{ marginBottom: 5 }}>
                        <TextField
                            multiline
                            id="supplement_notes"
                            name="notes"
                            label="Type here..."
                            autoComplete="notes"
                            fullWidth
                            value={this.state.supplementNotes}
                            onChange={this.handleSupplementsNotesChange}
                        />
                    </Grid>

                    <Grid item xs={12} sx={{ marginBottom: 1 }}>
                        <Typography variant="h7" fontWeight="medium">
                            Treatments
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sx={{ marginBottom: 3 }}>
                        <Box sx={{ border: 1, p: 2, borderRadius: 1, borderColor: '#c4c4c4', flexGrow: 1 }}>
                            <Grid 
                                container
                                spacing={2}
                                sx={{ mb: 3 }}
                            >
                                <React.Fragment>
                                    {((plan.treatments == [] || plan.treatments == null) ? this.state.treatments : plan.treatments)
                                        .map((item) => (
                                            <Item 
                                                key={item.id} 
                                                item={item} 
                                                onRemove={() => this.handleTreatmentRemove(item.id)} 
                                            />
                                        ))
                                    }
                                </React.Fragment>
                            </Grid>
                            <Stack
                                direction="column"
                                justifyContent="space-between"
                                alignItems="flex-end"
                            >
                                <TextField
                                    id="treatment_title"
                                    name="treatmentTitle"
                                    label="Treatment title"
                                    autoComplete="treatment title"
                                    variant="standard"
                                    multiline
                                    fullWidth
                                    required
                                    value={this.state.treatmentTitle}
                                    onChange={this.handleTreatmentTitleChange}
                                />

                                <TextField
                                    id="treatment_subtitle"
                                    name="treatmentSubtitle"
                                    label="Treatment subtitle"
                                    autoComplete="treatment subtitle"
                                    variant="standard"
                                    multiline
                                    fullWidth
                                    value={this.state.treatmentSubtitle}
                                    onChange={this.handleTreatmentSubtitleChange}
                                    sx={{ mt: 2 }}
                                />
    
                                <Button 
                                    variant="contained" 
                                    startIcon={<AddIcon />}
                                    disabled={this.state.treatmentTitle == ""}
                                    onClick={this.handleTreatmentAdd}
                                    sx={{ mt: 2 }}
                                >
                                    Add Treatment
                                </Button>
                            </Stack>
                        </Box>
                    </Grid>
                </Grid>
            </React.Fragment>
        </ThemeProvider>
        );
    }
}