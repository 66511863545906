import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import RecentPlans from './RecentPlans';
import Stats from '../dashboard/Stats';
import PlansList from './PlansList';
import Theme from '../../theme'

import FileOpenOutlined from '@mui/icons-material/FileOpenOutlined';
import { Stack, Typography, Button } from '@mui/material';

import AlertDialog from '../../views/AlertDialog';
import { db, auth, logEvent } from '../../services/firebase';
import dayjs from 'dayjs';
import { StatusLabelType } from '../../views/StatusLabel';

export default function Plans({ plans, onClick }) {

  // MARK: - Remove Plan Dialog

  const [openDialog, setOpenDialog] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState("");
  const [allowEditing, setAllowEditingValue] = React.useState(false);

  const isAdmin = ["bgZECbaWftRRgvPmRFbStTNRjtv1", "gOQWiLWMO0bVeahm3iKmI0Vhvny1"].includes(auth.currentUser.uid)

  React.useEffect(() => {
    setAllowEditingValue(isAdmin);
    plans.forEach((plan) => updatePlanStatusIfNeeded(plan));
  }, []);

  const updatePlanStatusIfNeeded = (plan) => {
    if (dayjs(plan.endsAt).format() < dayjs(new Date()).format() && plan.status !== StatusLabelType.ENDED.value.toLowerCase()) {
      const value = { status: StatusLabelType.ENDED.value.toLowerCase() }

      if (plan.expertId !== undefined && plan.expertId !== null) {
        db.ref().child('experts')
          .child(plan.expertId)
          .child('plans')
          .child(plan.id)
          .update(value)

        db.ref().child('plans')
          .child(plan.id)
          .update(value)
      }
    }
  }

  const handleOpenDialog = (plan) => {
    setSelectedValue(plan);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleRemovePlan = () => {
    setOpenDialog(false);
    removePlan();
  };

  const removePlan = () => {
    db.ref().child('experts').child(selectedValue.expertId).child('plans').child(selectedValue.id).remove()

    logEvent(`Plan with identifier: ${selectedValue.id} was removed for expert with identifier: ${selectedValue.expertId}`)
    
    if (isAdmin) {
      db.ref().child('plans').child(selectedValue.id).remove()
      db.ref().child('users').child(selectedValue.userId).child('hairCarePlan').remove()

      logEvent(`Plan with identifier: ${selectedValue.id} was removed from plans and from user with identifier: ${selectedValue.userId}`)
    }
  }

  return (
    <Box sx={{ backgroundColor: Theme.palette.background.lightGray }}>
        <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ mx: 14, mt: 10 }}
        >
            <Typography variant="h4" fontWeight="medium">
                Plans
            </Typography>

            <Button
                type="create"
                variant="contained"
                startIcon={<FileOpenOutlined />}
                onClick={() => {
                    onClick()
                }}
            >
                New Plan
            </Button>
        </Stack>

        <Box 
          display="flex" 
          sx={{
            flexWrap: 'wrap',
            mb: 4, 
            mt: 6,
            mx: 14,
            backgroundColor: Theme.palette.background.lightGray
          }}
        >
          <AlertDialog
            selectedValue={selectedValue}
            open={openDialog}
            onClose={handleCloseDialog}
            onAction={handleRemovePlan}
            title="Confirm plan deletion"
            message={`Are you sure you want to delete plan "${selectedValue.name}"?`}
            buttonTitle="Yes, delete"
            isDestructive={true}
          />

          <Grid container spacing={3}>
            <Grid item xs={12} md={8} lg={9}>
              <Paper
                sx={{
                  p: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  height: '100%'
                }}
              >
                <RecentPlans plans={plans} title={'Recent Plans'}/>
              </Paper>
            </Grid>

            <Grid item xs={12} md={4} lg={3}>
              <Paper
                sx={{
                  p: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  height: '100%'
                }}
              >
                <Stats items={plans}/>
              </Paper>
            </Grid>

            <Grid item xs={12}>
              <Paper 
                sx={{ 
                  p: 2, 
                  display: 'flex', 
                  flexDirection: 'column' 
                }}
              >
                <PlansList 
                  plans={plans} 
                  title={'Plans list'}
                  allowEditing={allowEditing}
                  sendOnEdit={ onClick } 
                  sendOnRemove={ handleOpenDialog }
                />
              </Paper>
            </Grid>
        </Grid>
      </Box>
    </Box>
  );

}