import HairData from "./HairData";

export default class HairCarePlanInfo {
    constructor(
      startDate = "",
      endDate = "",
      userId = "",
      hairData = new HairData
    ) {
      this.startDate = startDate;
      this.endDate = endDate;
      this.userId = userId;
      this.hairData = hairData;
    }
}