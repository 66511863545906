import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import RecentItems from './RecentItems';
import Stats from './Stats';
import DashboardArticlesList from './DashboardArticlesList';
import Theme from '../../theme'

import DashboardTitle from './DashboardTitle';
import { ButtonGroup, Button } from '@mui/material';

export default function Dashboard({ cares, articles, plans, user }) {

  const [selectedValue, setSelectedValue] = React.useState(30)

  const handleChange = (value) => {
    setSelectedValue(value)
  }

  return (
    <Box sx={{ backgroundColor: Theme.palette.background.lightGray }}>
        <DashboardTitle 
          user={user} 
          lastActivities={
            itemsDaysBack(cares, 0).length +
            itemsDaysBack(articles, 0).length +
            itemsDaysBack(plans, 0).length
          }
        />

        <Box
          display="flex"
          sx={{ 
            flexWrap: 'wrap',
            flexDirection: 'row-reverse',
            mt: 5,
            mr: 14
          }}
        >
          <ButtonGroup>
            <Button 
              variant={selectedValue == 30 ? "contained" : "outlined"} 
              onClick={() => handleChange(30)}
            >
              30 days
            </Button>
            <Button 
              variant={selectedValue == 60 ? "contained" : "outlined"} 
              onClick={() => handleChange(60)}
            >
              60 days
            </Button>
            <Button 
              variant={selectedValue == 90 ? "contained" : "outlined"} 
              onClick={() => handleChange(90)}
            >
              90 days
            </Button>
          </ButtonGroup>
        </Box>

        <Box 
          display="flex" 
          sx={{
            flexWrap: 'wrap',
            mb: 4, 
            mt: 3,
            mx: 14,
            backgroundColor: Theme.palette.background.lightGray
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12} md={8} lg={9}>
              <Paper
                sx={{
                  p: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  height: '100%',
                }}
              >
                <RecentItems items={itemsDaysBack(cares, selectedValue)} title={'Recent Cares'}/>
              </Paper>
            </Grid>

            <Grid item xs={12} md={4} lg={3}>
              <Paper
                sx={{
                  p: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  height: '100%',
                }}
              >
                <Stats items={itemsDaysBack(plans, selectedValue)}/>
              </Paper>
            </Grid>

            <Grid item xs={12}>
              <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                <DashboardArticlesList
                  items={ itemsDaysBack(articles, selectedValue) } 
                  title={'Recent Articles'} 
                />
              </Paper>
            </Grid>
        </Grid>
      </Box>
    </Box>
  );

}

export function itemsDaysBack(items, daysBack) {
  return items.filter(
    item => (new Date(item.createdAt) <= new Date()) &&
    new Date(item.createdAt) >= (new Date(new Date().setDate((new Date()).getDate() - daysBack)))
  )
}