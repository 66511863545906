import * as React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import NewCareBasics from './NewCareBasics';
import Theme from '../../../theme'
import NewCareDetails from './NewCareDetails';
import { useState } from 'react';
import { saveCare } from '../../../services/firebase';
import Repeats from '../../../models/Repeats';
import { getCurrentDate } from '../../../utils/currentDate'
import NewCarePreview from './NewCarePreview';
import LoadingButton from '@mui/lab/LoadingButton';
import UploadIcon from '@mui/icons-material/Upload'
import { Stack } from '@mui/material';
import { format } from 'date-fns';

const steps = ['Basics', 'Steps'];

function getStepContent(user, step, care, basicCallback, stepsCallback, repeats, repeatsCallback) {
  switch (step) {
    case 0:
      return <NewCareBasics  user={user} care={care} parentCallback = {basicCallback} repeats={repeats} repeatsCallback = {repeatsCallback}/>;
    case 1:
      return <NewCareDetails care={care} parentCallback = {stepsCallback}/>;
    default:
      throw new Error('Unknown step');
  }
}

export default function NewCareContainer({ editedCare, user, onClose }) {
  const [activeStep, setActiveStep] = useState(0)
  const [care, setCare] = useState(editedCare)
  const [show, setShow] = useState(false)
  const [uploading, setUploading] = useState(false)
  const [repeats, setRepeats] = useState(new Repeats())

  function showModal() {
    setShow(true)
  };

  function hideModal() {
    setShow(false)
  };
  
  function careBasicsCallback(childData) {
    const newCare = childData

    newCare.products = care.products
    newCare.notes = care.notes
    newCare.steps = care.steps
    newCare.suggestedNextCare = care.suggestedNextCare

    setCare(newCare)
  }

  function careStepsCallback(childData) {
    const newCare = childData

    newCare.id = care.id
    newCare.title = care.title
    newCare.description = care.description
    newCare.shortDescription = care.shortDescription
    newCare.purpose = care.purpose
    newCare.duration = care.duration
    newCare.repeats = care.repeats
    newCare.dayTime = care.dayTime
    newCare.isBasicCare = care.isBasicCare
    newCare.imageUrl = care.imageUrl

    setCare(newCare)
  }

  function repeatsCallback(childData) {
    setRepeats(childData)
  }

  const handleNext = () => {
    if (activeStep === steps.length - 1) {
      if (care.steps.length > 0) {
        setUploading(true)
        care.createdAt = getCurrentDate()
        
        // Calculate care repeats
        if (repeats.selectedTabIndex == 0) {
          const datesString = []
          repeats.selectedWeekDays.forEach((item) => 
            datesString.push(getAllDaysInMonth(item, care.dayTime))
          )
          care.repeats = datesString
        } else if (repeats.selectedTabIndex == 1) {
          const datesString = []
          if (repeats.selectedWeeksNumber < 6) {
            Array.apply(null, {length: repeats.selectedWeeksNumber + 1}).map(Number.call, Number).forEach((index) => 
              datesString.push(getAllDaysInMonth(index + 1, care.dayTime))
            )
          } else {
            const mondays = getAllDaysInMonth(1, care.dayTime)
            const filteredMondays = mondays.filter((element, index) => index % 2 == 0)
            datesString.push(filteredMondays)
          }
          care.repeats = datesString
        } else {
          care.repeats = [repeats.selectedDate.toISOString()]
        }

        // Save care
        setTimeout(() => { 
            setActiveStep(activeStep + 1);
            saveCare(care, user)
          }, 10
        );
      } else {
        alert("You should add at least one step to upload a care.")
      }
    } else {
      setActiveStep(activeStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  return (
    <ThemeProvider theme={Theme}>
      <CssBaseline />
      <Container component="main" maxWidth="sm" sx={{ mt: 4, mb: 4 }}>
        <Typography component="h2" variant="h5" align="center" fontWeight="bold">
            NEW CARE
        </Typography>
          <Stepper activeStep={activeStep} sx={{ mt: 3, mb: 5, mx: 12 }}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <React.Fragment>
            {activeStep === steps.length ? (
              <React.Fragment>
                <Typography variant="h6" gutterBottom fontWeight="bold">
                  Care was uploaded succesfully!
                </Typography>
                <Typography variant="subtitle1">
                  You can check its status in the main panel
                  and preview all the statistics there.
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center'
                  }}
                >
                  <Button
                    variant="contained"
                    sx={{ mt: 4 }}
                    onClick={() => {
                      onClose()
                    }}
                  >
                    Close
                  </Button>
                </Box>
              </React.Fragment>
            ) : (
              <React.Fragment>
                {getStepContent(user, activeStep, care, careBasicsCallback, careStepsCallback, repeats, repeatsCallback)}
                <NewCarePreview open={show} close={hideModal} care={care} />
                <Stack 
                  display="flex"
                  direction="row"
                  justifyContent="flex-end"
                >
                  <Stack
                    display="flex"
                    direction="row"
                  >
                    {activeStep !== 0 && (
                      <Button variant="outlined" onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                        Back
                      </Button>
                    )}

                    {activeStep < steps.length - 1 ?
                      <Button
                        variant="contained"
                        onClick={handleNext}
                        sx={{ mt: 3, ml: 1 }}
                      >
                        Next
                      </Button>
                      :
                      <LoadingButton
                        variant="contained"
                        onClick={handleNext}
                        sx={{ mt: 3, ml: 1 }}
                        disabled={care.title == "" || care.title == undefined || care.description == "" || care.description == undefined || care.shortDescription == "" || care.shortDescription == undefined}
                        loading={uploading}
                        loadingPosition="start"
                        startIcon={<UploadIcon />}
                      >
                        Upload
                      </LoadingButton>
                    }
                  </Stack>
                </Stack>
              </React.Fragment>
            )}
          </React.Fragment>
      </Container>
    </ThemeProvider>
  );
  
}

export function getAllDaysInMonth(weekDay, dayTime) {
  var currentDate = new Date(), month = currentDate.getMonth(), days = [];

  currentDate.setDate(1);

  while (currentDate.getDay() !== weekDay) {
    currentDate.setDate(currentDate.getDate() + 1);
  }

  while (currentDate.getMonth() === month) {
      var localDate = new Date(currentDate.getTime());
      const time = dayTime == "Morning" ? "06:00:00" : (dayTime == "Evening" ? "18:00:00" : "12:00:00")
      const dateFormatted = format(localDate, `yyyy-MM-dd'T'${time}'Z'`)
      days.push(dateFormatted);
      currentDate.setDate(currentDate.getDate() + 7);
  }

  return days
}