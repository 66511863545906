export default class Environment {
    constructor(
        serverKey = "", 
        serverKeyDebug = "",
        googleApiKey = ""
    ) {
        this.serverKey = serverKey;
        this.serverKeyDebug = serverKeyDebug;
        this.googleApiKey = googleApiKey;
    }
}