import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Title from './Title';
import Label from '@mui/material/StepLabel'
import { Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import { getShortDateString, getPastShortDateString } from '../../utils/currentDate'

export default function RecentArticles({ items, title }) {
  const theme = useTheme()

  return (
    <React.Fragment>
      <Title>{title}</Title>
      <Label
            angle={270}
            position="left"
            style={{
            textAnchor: 'middle',
            fill: theme.palette.text.primary,
            ...theme.typography.body1,
            }}
        >
            from {getPastShortDateString()} to {getShortDateString()}
        </Label>
        <Table size="small">
          <TableHead>
            <TableRow>
                <TableCell>Created at</TableCell>
                <TableCell>Title</TableCell>
                <TableCell>Author</TableCell>
                <TableCell>Sections count</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.slice(0, 3).map((row) => (
            <TableRow key={row.id}>
                <TableCell>{new Date(row.createdAt).toLocaleDateString()}</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>{row.title}</TableCell>
                <TableCell>{row.author}</TableCell>
                <TableCell>{row.sections.length}</TableCell>
            </TableRow>
            ))}
          </TableBody>
        </Table>
    </React.Fragment>
  );
}