export default class Treatment {
    constructor(
        id,
        title,
        subtitle
    ) {
      this.id = id;
      this.title = title;
      this.subtitle = subtitle;
    }
}