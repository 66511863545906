import * as React from 'react';
import { styled } from '@mui/material/styles';
import { Box, ThemeProvider } from '@mui/system';
import CssBaseline from '@mui/material/CssBaseline';
import { Button, Divider, Grid, IconButton, Stack, Typography } from '@mui/material';
import ChevronIcon from '@mui/icons-material/ChevronRight'
import Theme from '../theme';
import CaresIcon from '@mui/icons-material/AddOutlined'
import PlansIcon from '@mui/icons-material/FileOpenOutlined'
import AppointmentsIcon from '@mui/icons-material/CalendarMonthOutlined'
import LogsIcon from '@mui/icons-material/MenuOpenOutlined'
import ReportsIcon from '@mui/icons-material/BarChartSharp'
import BackgroundImage from '../img/sidebarBgSmall.svg';
import HomeIcon from '@mui/icons-material/Home'
import SettingsIcon from '@mui/icons-material/Settings';

import SignOutIcon from '@mui/icons-material/LoginOutlined'
import ArticleIcon from '@mui/icons-material/Article'
import useMeasure from 'react-use-measure';
import LogoImage from '../utils/logo';

export default function SideBar({ open, isOpen, selection, setSelection, user, onSignOut }) {
    const rotation = isOpen ? "rotate(180deg)" : "rotate(0)"
    const [ref, bounds] = useMeasure()

    const styles = {
        paperContainer: {
            backgroundImage: `url(${BackgroundImage})`,
            backgroundRepeat: 'no-repeat',
            height: '100vh'
        }
    };

    const handleSelection = (index) => {
        setSelection(index)
    }

    return (
        <ThemeProvider theme={Theme}>
        <CssBaseline />
            <Box 
                sx={{ height: '100%', width: '100%' }}
                ref={ref}
            >
                <Stack
                    direction="column"
                    justifyContent="space-between"
                    sx={{ height: '100%' }}
                >
                    <Box
                        style={styles.paperContainer}
                    >
                        <Stack
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                            spacing={isOpen ? 1 : 0}
                            sx={{ 
                                pt: 1, 
                                pl: 1.8, 
                                width: '100%', 
                                height: '54px', 
                                backgroundColor: Theme.palette.primary.dark
                            }}
                        >
                            <LogoImage size={36}/>
                            <Box>
                                <Typography 
                                    variant="h5" 
                                    color="white" 
                                    fontFamily="Copperplate" 
                                    fontWeight="light" 
                                    sx={{ height: '38px', width: '260px' }}
                                >
                                    {isOpen ? "Hairviour" : ""}
                                </Typography>
                            </Box>
                        </Stack>

                        <Divider color={Theme.palette.secondary.main} />
                        
                        <Box>
                            {user.role == "Admin" ? 
                            <Box>
                                <Stack
                                    direction={isOpen ? "row" : "column"}
                                    justifyContent={isOpen ? "space-between" : "flex-start"}
                                    alignItems="center"
                                    sx={{ 
                                        width: '100%', 
                                        height: (isOpen ? '54px' : '80px') ,
                                        backgroundColor: Theme.palette.primary.dark
                                    }}
                                >
                                    <Box >
                                        <BootstrapButton
                                            startIcon={<HomeIcon />}
                                                sx={{
                                                    color: (selection == 0 ? '#b69b8e' : 'white'),
                                                    height: (isOpen ? '54px' : '38px'),
                                                    width: (isOpen ? '195px' : '70px'),
                                                    pl: 3.4,
                                                    justifyContent: 'flex-start'
                                                }}
                                            onClick={() => handleSelection(0)}
                                        >
                                            {bounds.width == 260 ? "Project Overview" : ""}
                                        </BootstrapButton>
                                    </Box>
                                    
                                    <Box>
                                        <BootstrapButton
                                                sx={{
                                                    color: (selection == 1 ? '#b69b8e' : 'white'),
                                                    height: (isOpen ? '54px' : '38px'),
                                                    width: (isOpen ? '65px' : '70px'),
                                                    pl: 2.6,
                                                    justifyContent: 'flex-start',
                                                    opacity: (bounds.width == 260 || bounds.width == 70) ? 1 : 0
                                                }}
                                                onClick={() => handleSelection(1)}
                                            >
                                            <SettingsIcon sx={{ height: '20px'}}/>
                                        </BootstrapButton>
                                    </Box>
                                </Stack>

                                <Divider color={Theme.palette.secondary.main} />
                            </Box>
                            : 
                            <Box />
                            }
                        </Box>
                        
                        <Box>
                            {["Editor", "Admin"].includes(user.role) ? 
                                <Box>
                                    <BootstrapButton 
                                        variant="contained"
                                        startIcon={<CaresIcon />}
                                            sx={{ 
                                                color: (selection == 2 ? '#b69b8e' : 'white'),
                                                width: '100%',
                                                justifyContent: 'flex-start',
                                                pl: 3.4,
                                                mt: 1,
                                                height: '38px'
                                            }}
                                            onClick={() => handleSelection(2)}
                                    >
                                        {isOpen == true ? "Cares" : ""}
                                    </BootstrapButton>

                                    <BootstrapButton 
                                        variant="contained"
                                        startIcon={<ArticleIcon />}
                                            sx={{ 
                                                color: (selection == 3 ? '#b69b8e' : 'white'),
                                                width: '100%',
                                                justifyContent: 'flex-start',
                                                pl: 3.4,
                                                height: '38px'
                                            }}
                                            onClick={() => handleSelection(3)}
                                    >
                                        {isOpen == true ? "Articles" : ""}
                                    </BootstrapButton>
                                </Box>
                                :
                                <Box>
                                    <BootstrapButton 
                                        variant="contained"
                                        startIcon={<CaresIcon />}
                                            sx={{ 
                                                color: (selection == 2 ? '#b69b8e' : 'white'),
                                                width: '100%',
                                                justifyContent: 'flex-start',
                                                pl: 3.4,
                                                mt: 1,
                                                height: '38px'
                                            }}
                                            onClick={() => handleSelection(2)}
                                    >
                                        {isOpen == true ? "Cares" : ""}
                                    </BootstrapButton>
                                    
                                    <BootstrapButton 
                                        variant="contained"
                                        startIcon={<PlansIcon />}
                                            sx={{ 
                                                color: (selection == 4 ? '#b69b8e' : 'white'),
                                                width: '100%',
                                                justifyContent: 'flex-start',
                                                pl: 3.4,
                                                height: '38px'
                                            }}
                                            onClick={() => handleSelection(4)}
                                    >
                                        {isOpen == true ? "Plans" : ""}
                                    </BootstrapButton>

                                    <BootstrapButton 
                                        variant="contained"
                                        startIcon={<AppointmentsIcon />}
                                            sx={{ 
                                                color: (selection == 5 ? '#b69b8e' : 'white'),
                                                width: '100%',
                                                justifyContent: 'flex-start',
                                                pl: 3.4,
                                                height: '38px'
                                            }}
                                            onClick={() => handleSelection(5)}
                                    >
                                        {isOpen == true ? "Appointments" : ""}
                                    </BootstrapButton>
                                </Box>
                            }
                        </Box>

                        <Box>
                            {user.role == "Admin" ? 
                                <Box>
                                    <BootstrapButton 
                                        variant="contained"
                                        startIcon={<PlansIcon />}
                                            sx={{ 
                                                color: (selection == 4 ? '#b69b8e' : 'white'),
                                                width: '100%',
                                                justifyContent: 'flex-start',
                                                pl: 3.4,
                                                height: '38px'
                                            }}
                                            onClick={() => handleSelection(4)}
                                    >
                                        {isOpen == true ? "Plans" : ""}
                                    </BootstrapButton>

                                    <BootstrapButton 
                                        variant="contained"
                                        startIcon={<LogsIcon />}
                                            sx={{ 
                                                color: (selection == 6 ? '#b69b8e' : 'white'),
                                                width: '100%',
                                                justifyContent: 'flex-start',
                                                pl: 3.4,
                                                height: '38px'
                                            }}
                                            onClick={() => handleSelection(6)}
                                    >
                                        {isOpen == true ? "Logs" : ""}
                                    </BootstrapButton>

                                    <BootstrapButton 
                                        variant="contained"
                                        startIcon={<ReportsIcon />}
                                            sx={{ 
                                                color: (selection == 7 ? '#b69b8e' : 'white'),
                                                width: '100%',
                                                justifyContent: 'flex-start',
                                                pl: 3.4,
                                                height: '38px'
                                            }}
                                            onClick={() => handleSelection(7)}
                                    >
                                        {isOpen == true ? "Reports" : ""}
                                    </BootstrapButton>
                                </Box>
                                :
                                <Box/>
                            }
                        </Box>
                    </Box>

                    <Box>
                        <BootstrapButton 
                            type="signout"
                            variant="contained"
                            startIcon={<SignOutIcon />}
                                sx={{ 
                                    color: 'white',
                                    width: '100%',
                                    justifyContent: 'flex-start',
                                    pl: 3,
                                    height: '44px',
                                    backgroundColor: Theme.palette.primary.dark
                                }}
                                onClick={() => {
                                    onSignOut()
                                }}
                        >
                            {isOpen == true ? "Sign Out" : ""}
                        </BootstrapButton>

                        <Divider color={Theme.palette.secondary.main} />

                        <Stack
                            direction="row"
                            justifyContent="flex-end"
                            sx={{ 
                                pr: 1.7, 
                                backgroundColor: Theme.palette.primary.dark
                             }}
                        >
                            <IconButton onClick={open} sx={{ color: 'white' }}>
                                <ChevronIcon style={{ transform: rotation, transition: "all 0.2s linear" }}/>
                            </IconButton>
                        </Stack>
                    </Box>
                </Stack>
            </Box>
        </ThemeProvider>
    );
}

const BootstrapButton = styled(Button)({
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 14,
    fontWeight: '600',
    lineHeight: 0,
    flexWrap: 'nowrap',
    wordWrap: 'break-word',
    display: 'flex',
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
      boxShadow: '0 0 0 0rem rgba(182,155,142,.0)',
      backgroundColor: Theme.palette.primary.light
    },
    '&:active': {
      boxShadow: 'none',
    },
    '&:focus': {
      boxShadow: '0 0 0 0rem rgba(182,155,142,.0)',
    },
  });