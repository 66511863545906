import * as React from 'react';
import { Typography } from '@mui/material';

export default function Copyrights(props) {
    return (
        <Typography 
            variant="body2" 
            color="text.secondary" 
            align="center" {...props}
        >
            Copyright © 2023 Hairviour Inc. All rights reserved.
        </Typography>
    );
}