import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Title from '../dashboard/Title';
import Label from '@mui/material/StepLabel'
import { Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';

import { getShortDateString, getPastShortDateString } from '../../utils/currentDate'

export default function RecentPlans({ plans, title }) {
  const theme = useTheme()

  return (
    <React.Fragment>
      <Title>{title}</Title>
      <Label
          angle={270}
          position="left"
          style={{
          textAnchor: 'middle',
          fill: theme.palette.text.primary,
          ...theme.typography.body1,
          }}
      >
          from {getPastShortDateString()} to {getShortDateString()}
      </Label>

      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Created at</TableCell>
            <TableCell>Title</TableCell>
            <TableCell>Starts At</TableCell>
            <TableCell>Ends At</TableCell>
            <TableCell align="center">Cares count</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {plans.slice(0, 3).map((row) => (
            <TableRow key={row.id}>
              <TableCell>{new Date(row.createdAt).toLocaleDateString()}</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>{row.name}</TableCell>
              <TableCell>{new Date(row.startsAt).toLocaleDateString()}</TableCell>
              <TableCell>{new Date(row.endsAt).toLocaleDateString()}</TableCell>
              <TableCell 
                align="center"
                sx={{ 
                  color: row.cares == null ? '#d10000' : 'text.primary', 
                  fontWeight: row.cares == null ? 'bold' : 'regular' 
                }}
              >
                {row.cares.length ?? 0}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </React.Fragment>
  );
}