import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

export default function BasicCard({ header, title, message, buttonTitle = "Learn more" }) {
    return (
        <Card sx={{ mb: 2, mx: 3, boxShadow: 2 }}>
        <CardContent>
            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            {header ?? null}
            </Typography>
            <Typography variant="h6" component="div">
            {title ?? null}
            </Typography>
            <Typography variant="body2">
            {message ?? null}
            </Typography>
        </CardContent>
        <CardActions>
            <Button size="small">{buttonTitle ?? null}</Button>
        </CardActions>
        </Card>
    );
}