import * as React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Theme from '../../../theme'
import { useState, useEffect } from 'react';
import { saveArticle } from '../../../services/firebase';
import { getCurrentDate } from '../../../utils/currentDate'
import LoadingButton from '@mui/lab/LoadingButton';
import UploadIcon from '@mui/icons-material/Upload'
import { Stack } from '@mui/material';
import NewArticleBasics from '../newArticle/NewArticleBasics';
import NewArticleSections from '../newArticle/NewArticleSections'

const steps = ['Basics', 'Sections'];

function getStepContent(step, article, basicCallback, sectionsCallback) {
  switch (step) {
    case 0:
      return <NewArticleBasics article={article} parentCallback={basicCallback}/>
    case 1:
      return <NewArticleSections article={article} parentCallback={sectionsCallback}/>
    default:
      throw new Error('Unknown step');
  }
}

export default function NewArticleContainer({ editedArticle, onClose }) {
  const [activeStep, setActiveStep] = useState(0)
  const [article, setArticle] = useState(editedArticle)
  const [uploading, setUploading] = useState(false)
  const [uploadable, setUploadable] = useState(false)
  
  function articleSectionsCallback(childData) {
    const newArticle = article
    newArticle.sections = childData.sections
    setArticle(newArticle)
    checkUploadable()
  }

  function articleBasicsCallback(childData) {
    const newArticle = article
    newArticle.title = childData.title
    newArticle.shortContent = childData.shortContent
    newArticle.author = childData.author
    newArticle.tag = childData.tag
    newArticle.imageUrl = childData.imageUrl
    newArticle.readingTime = childData.readingTime
    newArticle.createdAt = getCurrentDate();
    setArticle(newArticle)
    checkUploadable()
  }

  const checkUploadable = () => {
    const isUploadable = (
      article.title != "" &&
      article.shortContent != "" &&
      (article.sections ?? []).length > 0 &&
      article.author != "" &&
      article.tag != "" &&
      article.imageUrl != "" &&
      article.readingTime != ""
    )
    setUploadable(isUploadable)
  }

  const handleNext = () => {
    if (activeStep === steps.length - 1) {
      setUploading(true);

      setTimeout(() => { 
          setActiveStep(activeStep + 1);
          saveArticle(article)
        }, 10
      );
    } else {
      setActiveStep(activeStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  useEffect(() => {
    checkUploadable()
  }, []);

  return (
    <ThemeProvider theme={Theme}>
      <CssBaseline />
      <Container component="main" maxWidth="sm" sx={{ mt: 4, mb: 4 }}>
        <Typography component="h2" variant="h5" align="center" fontWeight="bold">
            NEW ARTICLE
        </Typography>
          <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5, mx: 12 }}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <React.Fragment>
            {activeStep === steps.length ? (
              <React.Fragment>
                <Typography variant="h6" gutterBottom fontWeight="bold">
                    Article was uploaded succesfully!
                </Typography>
                <Typography variant="subtitle1">
                  You can check its status in the main panel
                  and preview all the statistics there.
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center'
                  }}
                >
                  <Button
                    variant="contained"
                    sx={{ mt: 4 }}
                    onClick={() => {
                      onClose()
                    }}
                  >
                    Close
                  </Button>
                </Box>
              </React.Fragment>
            ) : (
              <React.Fragment>
                {getStepContent(activeStep, article, articleBasicsCallback, articleSectionsCallback)}
                <Stack 
                  display="flex"
                  direction="row"
                  justifyContent="flex-end"
                >
                  {activeStep !== 0 && (
                      <Button variant="outlined" onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                        Back
                      </Button>
                    )}

                    {activeStep < steps.length - 1 ?
                      <Button
                        variant="contained"
                        onClick={handleNext}
                        sx={{ mt: 3, ml: 1 }}
                      >
                        Next
                      </Button>
                      :
                      <LoadingButton
                        variant="contained"
                        onClick={handleNext}
                        sx={{ mt: 3, ml: 1 }}
                        disabled={!uploadable}
                        loading={uploading}
                        loadingPosition="start"
                        startIcon={<UploadIcon />}
                      >
                        Upload
                      </LoadingButton>
                    }
                </Stack>
              </React.Fragment>
            )}
          </React.Fragment>
      </Container>
    </ThemeProvider>
  );
  
}