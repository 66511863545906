import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Theme from '../../theme'
import { Stack, Typography, Button } from '@mui/material';

import ArticleList from '../dashboard/ArticleList';
import Stats from '../dashboard/Stats';
import IconArticle from '@mui/icons-material/Article';

import { db } from '../../services/firebase';
import RecentArticles from '../dashboard/RecentArticles';

import AlertDialog from '../../views/AlertDialog';

export default function Articles({ articles, onClick }) {

    // MARK: - Remove Article Dialog

    const [openDialog, setOpenDialog] = React.useState(false);
    const [selectedValue, setSelectedValue] = React.useState("");

    const handleOpenDialog = (article) => {
      setSelectedValue(article);
      setOpenDialog(true);
    };

    const handleCloseDialog = () => {
      setOpenDialog(false);
    };

    const handleRemoveArticle = () => {
      setOpenDialog(false);
      removeArticle();
    };

    const removeArticle = () => {
      db.ref().child('articles').child(selectedValue.id).remove()
    }

    return (
        <Box sx={{ backgroundColor: Theme.palette.background.lightGray }}>
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ mx: 14, mt: 10 }}
            >
                <Typography variant="h4" fontWeight="medium">
                    Articles
                </Typography>
    
                <Button
                    type="create"
                    variant="contained"
                    startIcon={<IconArticle />}
                    onClick={onClick}
                >
                    New Article
                </Button>
            </Stack>
    
            <Box 
              display="flex" 
              sx={{
                flexWrap: 'wrap',
                mb: 4, 
                mt: 6,
                mx: 14,
                backgroundColor: Theme.palette.background.lightGray
              }}
            >
              <AlertDialog
                selectedValue={selectedValue}
                open={openDialog}
                onClose={handleCloseDialog}
                onAction={handleRemoveArticle}
                title="Confirm article deletion"
                message={`Are you sure you want to delete aritcle "${selectedValue.title}"?`}
                buttonTitle="Yes, delete"
                isDestructive={true}
              />

              <Grid container spacing={3}>
                <Grid item xs={12} md={8} lg={9}>
                  <Paper
                    sx={{
                      p: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      height: '100%'
                    }}
                  >
                    <RecentArticles 
                      items={ articles.filter(
                        article => (new Date(article.createdAt) <= new Date()) &&
                        new Date(article.createdAt) >= (new Date(new Date().setDate((new Date()).getDate() - 30)))
                      )}  
                      title={'Recent Articles'}
                    />
                  </Paper>
                </Grid>
    
                <Grid item xs={12} md={4} lg={3}>
                  <Paper
                    sx={{
                      p: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      height: '100%'
                    }}
                  >
                    <Stats 
                      items={ articles } 
                      title="Summary" 
                      description="articles in database"
                    />
                  </Paper>
                </Grid>
    
                <Grid item xs={12}>
                  <Paper 
                    sx={{ 
                      p: 2, 
                      display: 'flex',
                      flexDirection: 'column' 
                    }}
                  >
                    <ArticleList 
                      items={ articles } 
                      title={'Articles list'} 
                      sendOnEdit={ onClick } 
                      sendOnRemove={ handleOpenDialog }
                    />
                  </Paper>
                </Grid>
            </Grid>
          </Box>
        </Box>
      );

}