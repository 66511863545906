import React, { useEffect } from 'react';
import Theme from '../../theme'
import { Box, Stack, Typography, Grid, Paper, Divider, colors} from '@mui/material';
import { db } from '../../services/firebase';
import dayjs from 'dayjs';
import { TagsSearchBar } from '../../views/SearchBar';

export default function Logs() {

    // MARK: - Stored Properties

    const [logs, setLogs] = React.useState(null);
    const [searchedTags, setSearchedTags] = React.useState([]);

    const filteredLogs = (logs ?? []).filter(log =>
        searchedTags.every(term =>
          log.toLowerCase().includes(term.toLowerCase())
        )
    );

    // MARK: - Effects

    useEffect(() => {
        fetchLogs()
    }, []);

    // MARK: - Methods

    const fetchLogs = () => {
        db.ref().child('logs')
            .on('value', (snapshot) => {
                const logs = snapshot.val()

                if (logs !== undefined) {
                    setLogs(Object.values(logs).filter(item => item !== null))
                }
            }
        )
    }

    const handleSearch = (tags) => {
        setSearchedTags(tags)
    }

    // MARK: - Views

    return (
        <div>
            <Box sx={{ backgroundColor: Theme.palette.background.lightGray }}>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ mx: 14, mt: 10 }}
                >
                    <Typography variant="h4" fontWeight="medium">
                        Logs
                    </Typography>
                </Stack>

                <Box 
                    display="flex" 
                    sx={{
                        flexWrap: 'wrap',
                        mb: 4, 
                        mt: 6,
                        mx: 14,
                        backgroundColor: Theme.palette.background.lightGray
                    }}
                >
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Paper
                                sx={{
                                    p: 2,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    height: '100%',
                                    mb: 1
                                }}
                            >
                                <TagsSearchBar onChange={handleSearch} sx={{ mb: 4 }}/>

                                {filteredLogs.length > 0 ?
                                    filteredLogs
                                        .sort((a, b) => {
                                            const firstDateMatch = a.match(/\[DATE\](.*?)\[/);
                                            const secondDateMatch = b.match(/\[DATE\](.*?)\[/);
                                            const aDate = firstDateMatch ? dayjs(firstDateMatch[1]) : dayjs()
                                            const bDate = secondDateMatch ? dayjs(secondDateMatch[1]) : dayjs()
                                            return bDate - aDate
                                        })
                                        .map((log, index) => {
                                            const dateMatch = log.match(/\[DATE\](.*?)\[/);
                                            const userMatch = log.match(/\[USER\](.*?)\[/);
                                            const messageMatch = log.match(/\[MESSAGE\](.*)$/);

                                            const date = dateMatch ? dateMatch[1] : '';
                                            const user = userMatch ? userMatch[1] : '';
                                            const message = messageMatch ? messageMatch[1] : '';

                                            return (
                                                <Box key={index} sx={{ mb: 1 }}>
                                                    <Typography variant="body2">
                                                        <span style={{ fontWeight: 'bold' }}>{`[DATE]`}</span> {date} <span style={{ fontWeight: 'bold' }}>{`[USER]`}</span> {user} <span style={{ fontWeight: 'bold' }}>{`[MESSAGE]`}</span> {message} 
                                                    </Typography>
                                                </Box>
                                            );
                                    })
                                    :
                                    <Box sx={{ width: '100%' }}>
                                        <Typography
                                            variant="body2"
                                            color="gray"
                                            sx={{ textAlign: 'center' }}
                                        >
                                            No results found
                                        </Typography>
                                    </Box>
                                }
                            </Paper>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </div>
    );
}