export default class Expert {
  constructor(
    availableHours = [],
    plans = [],
    cares = [],
    appointments = []
  ) {
    this.availableHours = availableHours;
    this.plans = plans;
    this.cares = cares;
    this.appointments = appointments;
  }
}
