import * as React from 'react';
import { useState, useEffect } from 'react';
import Theme from '../../theme'
import { Box, Stack, Typography, Grid, Paper, Table, TableBody, TableCell, TableHead, TableRow, Button, colors, CircularProgress } from '@mui/material';
import FilterSelector from '../../views/FilterSelector';

import { db } from '../../services/firebase';
import { getMonthDifference, monthIndexString, monthNames } from '../../utils/extensions';
import { StatusLabelType } from '../../views/StatusLabel';

import * as XLSX from 'xlsx';

export default function Reports({ plans }) {

    const [data, setData] = useState([]);
    const [expertDatas, setExpertDatas] = useState([]);
    const [selectedYear, setSelectedYear] = useState("");
    const [selectedMonth, setSelectedMonth] = useState("");
    const [selectedExpert, setSelectedExpert] = useState("");
    const [selectedStatus, setSelectedStatus] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const filteredData = data.filter((data) => {
        const containsAllTerms = [selectedYear, monthIndexString(selectedMonth), selectedExpert, selectedStatus].every(term =>
            data.toLowerCase().includes(String(term).toLowerCase())
        );
    
        if (containsAllTerms && selectedYear !== "" && selectedMonth !== "") {
            const pattern = new RegExp(`${selectedYear}-${monthIndexString(selectedMonth)}`);
            return pattern.test(data);
        }
    
        return containsAllTerms;
    });

    const filteredDataFormatted = filteredData.map(item => String(item).split('|'))

    // MARK: - Effects

    useEffect(() => {
        fetchData()
    }, []);

    // MARK: - Methods

    const fetchData = async () => {
        setIsLoading(true);
        const localExpertDatas = []
        const values = []

        for (const plan of plans) {
            const expertSnapshot = await db.ref()
                .child("experts")
                .child(plan.expertId)
                .child("email")
                .get();
        
            const expertEmail = expertSnapshot.val();

            const datas = { id: plan.expertId, email: expertEmail }
            if (!localExpertDatas.map(item => item.email).includes(expertEmail)) {
                localExpertDatas.push(datas)
            }

            const userSnapshot = await db.ref()
                .child("users")
                .child(plan.userId)
                .child("email")
                .get();
        
            const userEmail = userSnapshot.val();
        
            const value = `${plan.id}|${plan.name}|${plan.createdAt}|${getMonthDifference(plan.startsAt, plan.endsAt)}|${plan.expertId}|${expertEmail}|${plan.userId}|${userEmail}|${plan.status}`;
            values.push(value)
        }

        await db.ref()
            .child('appointments')
            .once('value')
            .then(snapshot => {
                const userData = snapshot.val();

                for (const userId in userData) {
                    const userAppointments = userData[userId];

                    for (const appointmentId in userAppointments) {
                        const appointment = userAppointments[appointmentId];
                        
                        const expertEmail = localExpertDatas.find(item => item.id === appointment.expertId).email;
                        const status = ((appointment.status == StatusLabelType.COMPLETED.value.toLowerCase()) && appointment.userApproved && appointment.expertApproved) ? 
                            StatusLabelType.APPROVED.value.toLowerCase() : 
                            appointment.status;
                        const localValue = `${appointment.id}|${appointment.title}|${appointment.date}|${appointment.duration}|${appointment.expertId}|${expertEmail}|${userId}|${appointment.email}|${status}`;
                        values.push(localValue)
                    }
                }
            })
            .catch(error => {
                console.error('Error fetching appointments:', error);
            });

        setExpertDatas(localExpertDatas);
        setData(values);
        setIsLoading(false);
    }

    const onYearChange = (value) => {
        if (value === selectedYear) {
            setSelectedMonth("")
        }
        setSelectedYear(value === selectedYear ? "" : value)
    }

    const onMonthChange = (value) => {
        setSelectedMonth(value === selectedMonth ? "" : value)
    }

    const onExpertChange = (value) => {
        setSelectedExpert(value === selectedExpert ? "" : value)
    }

    const onStatusChange = (value) => {
        setSelectedStatus(value === selectedStatus ? "" : value)
    }

    const exportToExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(filteredDataFormatted);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Table Data');
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        const url = URL.createObjectURL(data);
        const element = document.createElement('a');
        element.href = url;
        element.download = `hairviour_report${selectedYear === "" ? "" : `_${selectedYear}`}${selectedMonth === "" ? "" : `-${monthIndexString(selectedMonth)}`}.xlsx`;
        element.click();
        URL.revokeObjectURL(url);
    };

    return (
        <div>
            <Box sx={{ backgroundColor: Theme.palette.background.lightGray }}>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ mx: 14, mt: 10 }}
                >
                    <Typography variant="h4" fontWeight="medium">
                        Reports
                    </Typography>
                </Stack>

                <Box 
                    display="flex" 
                    flexDirection='column'
                    sx={{
                        flexWrap: 'wrap',
                        mb: 4, 
                        mt: 6,
                        mx: 14,
                        backgroundColor: Theme.palette.background.lightGray
                    }}
                >
                    <Stack
                        flex={1}
                        flexWrap="nowrap"
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{ mb: 4 }}
                    >
                        <Stack
                            direction="row"
                            justifyContent="self-start"
                            alignItems="center"
                            spacing={1}
                        >
                            <FilterSelector 
                                tags={["2022", "2023", "2024", "2025", "2026", "2027", "2028", "2029", "2030"]}
                                onValueChange={onYearChange}
                                title='Year'
                            />

                            <FilterSelector 
                                tags={monthNames}
                                onValueChange={onMonthChange}
                                title='Month'
                                disabled={selectedYear.length == 0}
                            />

                            <FilterSelector 
                                tags={expertDatas.map(value => value.email === "" ? value.id : value.email)}
                                onValueChange={onExpertChange}
                                title='Expert'
                            />

                            <FilterSelector 
                                tags={Object.values(StatusLabelType).map(status => status.value)}
                                onValueChange={onStatusChange}
                                title='Status'
                            />
                        </Stack>

                        <Button
                            variant='contained'
                            onClick={exportToExcel}
                        >
                            Export
                        </Button>
                    </Stack>

                    <Stack
                        direction="row"
                        alignItems="center"
                        spacing={1}
                        sx={{ mb: 2 }}
                    >
                        <Typography variant="body2" color={colors.grey[700]}>Results count:</Typography>
                        <Typography variant="body2" fontWeight="bold" color={colors.grey[700]}>
                            {filteredData.length}
                        </Typography>
                    </Stack>

                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Paper
                                sx={{
                                    p: 2,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    height: '100%',
                                    mb: 1,
                                    overflowX: 'auto'
                                }}
                            >
                                {isLoading ? (
                                    <Box sx={{ width: '100%', textAlign: 'center', m: 4 }}>
                                        <CircularProgress />
                                    </Box>
                                ) : filteredData.length > 0 ? (
                                    <Table size="medium">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Identifier</TableCell>
                                                <TableCell>Title</TableCell>
                                                <TableCell>Created At</TableCell>
                                                <TableCell>Duration</TableCell>
                                                <TableCell>Expert Id</TableCell>
                                                <TableCell>Expert Email</TableCell>
                                                <TableCell>User Id</TableCell>
                                                <TableCell>User Email</TableCell>
                                                <TableCell>Status</TableCell>
                                            </TableRow>
                                        </TableHead>

                                        <TableBody>
                                            {filteredData.map((data) => (
                                                <TableRow key={data.id}>
                                                    {String(data).split('|').map((item, index) => (
                                                        <TableCell key={index}>{item}</TableCell>
                                                    ))}
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                ) : (
                                    <Box sx={{ width: '100%', textAlign: 'center' }}>
                                        <Typography variant="body2" color="gray">
                                            No results found
                                        </Typography>
                                    </Box>
                                )};                            
                            </Paper>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </div>
    );
}