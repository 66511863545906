export const firebaseConfigProd = {
  apiKey: "AIzaSyA5FxNIMT4cIR3NZeIAkoFn9wpYk-M3wo0",
  authDomain: "hairviour.firebaseapp.com",
  databaseURL: "https://hairviour-default-rtdb.firebaseio.com/",
  projectId: "hairviour",
  storageBucket: "hairviour.appspot.com",
  messagingSenderId: "171406768343",
  appId: "1:171406768343:web:47d7340ab94a41b2401892"
};

export const firebaseConfigDebug = {
  apiKey: "AIzaSyCkCo-fABQDmqCT6M29MHfdZi1NfhS41Fw",
  authDomain: "hairviour-staging.firebaseapp.com",
  databaseURL: "https://hairviour-staging-default-rtdb.firebaseio.com",
  projectId: "hairviour-staging",
  storageBucket: "hairviour-staging.appspot.com",
  messagingSenderId: "61903460804",
  appId: "1:61903460804:web:8f32a7972632ffd2722c91",
  measurementId: "G-QSCFCV2RMP"
};