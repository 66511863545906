import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import NewPlanContainer from './NewPlanContainer'
import { creatorModalStyle } from '../../../utils/styles';

export default function NewPlan({ 
  editedPlan, cares, user, open, close 
}) {

  return (
      <Modal
        open={open}
      >
        <Box sx={creatorModalStyle}>
          <NewPlanContainer 
            cares={cares} 
            editedPlan={editedPlan} 
            user={user} 
            onClose={close}
          />
        </Box>
      </Modal>
  );
}