import { Paper, Typography, colors } from "@mui/material";

export function StatusLabel({ type = StatusLabelType.UNASSIGNED, marginRight = 0 }) {
    return ( 
        <Paper
            elevation={0}
            sx={{
                backgroundColor: type.backgroundColor,
                paddingX: 1,
                paddingY: 0,
                marginRight: marginRight
            }}
        >
            <Typography 
                variant="caption" 
                color={type.color}
                style={{ textTransform: 'uppercase', fontWeight: 'bold' }}
            >
                {type.value}
            </Typography>
        </Paper>
    );
}

export const StatusLabelType = {
    APPROVED: {
        value: "Approved",
        color: colors.orange[700],
        backgroundColor: colors.orange[100]
    },
    ASSIGNED: {
        value: "Assigned",
        color: colors.green[700],
        backgroundColor: colors.green[100]
    },
    UNASSIGNED: {
        value: "Unassigned",
        color: colors.grey[700],
        backgroundColor: colors.grey[100]
    },
    ACTIVE: {
        value: "Active",
        color: colors.green[700],
        backgroundColor: colors.green[100]
    },
    ENDED: {
        value: "Ended",
        color: colors.grey[700],
        backgroundColor: colors.grey[100]
    },
    PENDING: {
        value: "Pending",
        color: colors.grey[700],
        backgroundColor: colors.grey[100]
    },
    CONFIRMED: {
        value: "Confirmed",
        color: colors.green[700],
        backgroundColor: colors.green[100]
    },
    COMPLETED: {
        value: "Completed",
        color: colors.grey[700],
        backgroundColor: colors.grey[100]
    },
    CANCELED: {
        value: "Canceled",
        color: colors.red[700],
        backgroundColor: colors.red[100]
    },
    PENDING_APPROVAL: {
        value: "Pending Approval",
        color: colors.grey[700],
        backgroundColor: colors.grey[100]
    },
};