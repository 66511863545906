import * as React from 'react';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import { Button } from '@mui/material';
import { Stack } from '@mui/material';
import AddIcon from '@mui/icons-material/Add'
import FileIcon from '@mui/icons-material/FileOpenOutlined'
import { ReactComponent as MaleImage } from '../img/bgZECbaWftRRgvPmRFbStTNRjtv1.svg';
import { ReactComponent as FemaleImage } from '../img/039RNfPbODPb4C7KLEfWYNnpPXz2.svg';
import { Notifications } from '@mui/icons-material';
import Theme from '../theme'
import IconButton from '@mui/material/IconButton'

export default function NavigationBar({ navBarVisible, user, selection, notificationsVisible }) {

    const navBarTitle = (selection == 0 ? "Dashboard" : (selection == 1 ? "Settings" : (selection == 2 ? "Cares" : (selection == 3 ? "Articles" : (selection == 4 ? "Plans" : (selection == 5 ? "Appointments" : (selection == 6 ? "Logs" : "Reports")))))))

    return (
        <Stack
            display="flex"
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ width: '100%', pl: 12, pr: 4 }}
        >
            <Typography variant="subtitle1" color="text.primary" fontWeight="medium">
                {navBarVisible ? navBarTitle : ""}
            </Typography>

            <Stack 
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={1}
            >
                <IconButton sx={{ mr: 1, color: Theme.palette.primary.main }} onClick={notificationsVisible}>
                    <Notifications/>
                </IconButton>

                <Stack 
                    direction="column"
                    justifyContent="flex-end"
                    alignItems="end"
                    spacing={-0.5}
                >
                    <Typography variant="subtitle1" color="text.primary" fontWeight="medium">
                        {user.name ?? ""}
                    </Typography>

                    <Typography variant="body2" color="text.secondary" sx={{ pr: 0.5 }}>
                        {user.email ?? ""}
                    </Typography>
                </Stack>

                {`${user.name}`.split(" ")[0].slice(-1).toLowerCase() == "a" ? 
                    <FemaleImage width="35px" height="35px"/> :
                    <MaleImage width="35px" height="35px"/>
                }
            </Stack>

            {/* <Stack 
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                spacing={2}
            >
                <Button
                    type="create"
                    variant="contained"
                    startIcon={<FileIcon />}
                    onClick={() => {
                        onCreate()
                    }}
                >
                    New Plan
                </Button>

                <Button
                    type="add"
                    variant="contained"
                    startIcon={<AddIcon />}
                    onClick={() => {
                        onAdd()
                    }}
                >
                    New Care
                </Button>
            </Stack> */}
        </Stack>
    );
}