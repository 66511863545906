import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { DialogContentText, DialogContent, DialogActions } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import theme from '../theme';
import { Lock } from '@mui/icons-material';
import { Box } from '@mui/system';

export default function AlertDialog(props) {
  const { onClose, onAction, open, title, message, buttonTitle, isDestructive, buttonsHidden, titleColor } = props;
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleClose = () => {
    onClose();
  };

  const handleAction = () => {
    onAction();
  };

  return (
    <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullScreen={fullScreen}
    >
        <DialogTitle id="alert-dialog-title" color={titleColor ?? "black"}>
            { 
              buttonsHidden ?
                <Box display='flex' alignItems="center">
                  <Lock sx={{ pb: "2px", marginRight: "6px" }}/>
                  {title}
                </Box> :
                <Box>{title}</Box>
            }
        </DialogTitle>

        <DialogContent>
            <DialogContentText id="alert-dialog-description">
            {message}
            </DialogContentText>
        </DialogContent>
        
        {!buttonsHidden ? <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>

            <Button 
              variant="contained"
              onClick={handleAction}
              sx={{ backgroundColor: isDestructive ? theme.palette.error.main : theme.palette.primary.main }}
            >
              {buttonTitle}
            </Button>
        </DialogActions> : null}
    </Dialog>
  );

}

AlertDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};