import * as React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import Theme from '../../../theme'
import { TextField, Typography, Grid } from '@mui/material';
import Article from '../../../models/article/Article';

export default class NewArticleBasics extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            title: this.props.article.title ?? "",
            shortContent: this.props.article.shortContent ?? "",
            author: this.props.article.author ?? "",
            tag: this.props.article.tag ?? "",
            imageUrl: this.props.article.imageUrl ?? "",
            readingTime: this.props.article.readingTime ?? ""
        };
    }

    handleTitleChange = (evt) => {
        this.setState({ title: evt.target.value });
        const article = new Article(
            "",
            evt.target.value,
            this.state.shortContent,
            [],
            this.state.author,
            this.state.tag,
            this.state.imageUrl,
            this.state.readingTime,
            ""
        )
        this.sendDataToParent(article)
    }

    handleShortContentChange = (evt) => {
        this.setState({ shortContent: evt.target.value });
        const article = new Article(
            "",
            this.state.title,
            evt.target.value,
            [],
            this.state.author,
            this.state.tag,
            this.state.imageUrl,
            this.state.readingTime,
            ""
        )
        this.sendDataToParent(article)
    }

    handleAuthorChange = (evt) => {
        this.setState({ author: evt.target.value });
        const article = new Article(
            "",
            this.state.title,
            this.state.shortContent,
            [],
            evt.target.value,
            this.state.tag,
            this.state.imageUrl,
            this.state.readingTime,
            ""
        )
        this.sendDataToParent(article)
    }

    handleTagChange = (evt) => {
        this.setState({ tag: evt.target.value });
        const article = new Article(
            "",
            this.state.title,
            this.state.shortContent,
            [],
            this.state.author,
            evt.target.value,
            this.state.imageUrl,
            this.state.readingTime,
            ""
        )
        this.sendDataToParent(article)
    }

    handleImageUrlChange = (evt) => {
        this.setState({ imageUrl: evt.target.value });
        const article = new Article(
            "",
            this.state.title,
            this.state.shortContent,
            [],
            this.state.author,
            this.state.tag,
            evt.target.value,
            this.state.readingTime,
            ""
        )
        this.sendDataToParent(article)
    }

    handleReadingTimelChange = (evt) => {
        this.setState({ readingTime: evt.target.value });
        const article = new Article(
            "",
            this.state.title,
            this.state.shortContent,
            [],
            this.state.author,
            this.state.tag,
            this.state.imageUrl,
            evt.target.value,
            ""
        )
        this.sendDataToParent(article)
    }

    sendDataToParent = (article) => {
        this.props.parentCallback(article)
    }

    render() {
        const article = this.props.article
        
        return (
            <ThemeProvider theme={Theme}>
                <React.Fragment>
                    <Typography variant="h6" gutterBottom>
                        Please provide a basics information
                    </Typography>
                    <Grid container spacing={3} sx={{ mt: 2 }}>
                        <Grid item xs={12}>
                        <TextField
                            required
                            multiline
                            id="title"
                            name="title"
                            label="Title"
                            fullWidth
                            autoComplete="title"
                            autoFocus="true"
                            value={(article.title == "" || article.title == null) ? this.state.title : article.title}
                            onChange={this.handleTitleChange}
                        />
                        </Grid>

                        <Grid item xs={12}>
                        <TextField
                            required
                            multiline
                            id="short_content"
                            name="shortContent"
                            label="Short content"
                            fullWidth
                            autoComplete="short content"
                            value={(article.shortContent == "" || article.shortContent == null) ? this.state.shortContent : article.shortContent}
                            onChange={this.handleShortContentChange}
                        />
                        </Grid>

                        <Grid item xs={12}>
                        <TextField
                            required
                            multiline
                            id="author"
                            name="author"
                            label="Author"
                            fullWidth
                            autoComplete="author"
                            value={(article.author == "" || article.author == null) ? this.state.author : article.author}
                            onChange={this.handleAuthorChange}
                        />
                        </Grid>

                        <Grid item xs={6}>
                        <TextField
                            required
                            id="tag"
                            name="tag"
                            label="Tag"
                            fullWidth
                            autoComplete="tag"
                            value={(article.tag == "" || article.tag == null) ? this.state.tag : article.tag}
                            onChange={this.handleTagChange}
                        />
                        </Grid>

                        <Grid item xs={12}>
                        <TextField
                            required
                            multiline
                            id="image_url"
                            name="imageUrl"
                            label="Image url"
                            fullWidth
                            autoComplete="image url"
                            value={(article.imageUrl == "" || article.imageUrl == null) ? this.state.imageUrl : article.imageUrl}
                            onChange={this.handleImageUrlChange}
                        />
                        </Grid>

                        <Grid item xs={12}>
                        <TextField
                            required
                            multiline
                            id="reading_time"
                            name="readingTime"
                            label="Reading time (ex. 3 min)"
                            fullWidth
                            autoComplete="reading time"
                            value={(article.readingTime == "" || article.readingTime == null) ? this.state.readingTime : article.readingTime}
                            onChange={this.handleReadingTimelChange}
                        />
                        </Grid>
                    </Grid>
                </React.Fragment>
            </ThemeProvider>
        );
    }

}