import * as React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Theme from '../../../theme'
import { Button, IconButton, Stack, Box, Paper, TextField } from '@mui/material';
import Modal from '@mui/material/Modal';
import LogoImage from '../../../utils/logo';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 0,
    borderRadius: '8px',
    overflow:'scroll',
    height:'85%',
    width:'30%',
    display:'flex'
  };

export default function NewCarePreview({ open, close, care }) {

    function handleClose() {
      close()
    };
  
    return (
        <Modal
          open={open}
          onClose={handleClose}
        >
          <Box sx={style}>
            <Stack
                direction="column"
                justifyContent="space-between"
                alignItems="flex-start"
                width="100%"
            >
                <NewCarePreviewContent care={care}/>
                <Stack
                    direction="column"
                    alignItems="flex-end"
                    width="100%"
                >
                    <Button variant="contained" sx={{ mb: 4, mr: 4 }} onClick={handleClose}>
                        Close
                    </Button>
                </Stack>
            </Stack>
          </Box>
        </Modal>
    );
  }

function NewCarePreviewContent({care}) {

    return (
        <ThemeProvider theme={Theme}>
            <Box 
                display="inline"
                width="100%"
            >
                <Grid 
                    container 
                    display="flex" 
                    direction="column" 
                    spacing={2} 
                    sx={{ p: 3 }}
                >
                    <Grid item xs={12} sx={{ ml: '-4px', mb: 2 }}>
                        <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                        >
                            <LogoImage />
                            <Typography variant="h6">
                                New Care Preview
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="title"
                            name="title"
                            label={(care.title == "" || care.title == null) ? "Title" : ""}
                            fullWidth
                            multiline
                            value={care.title}
                            error
                            disabled
                            size="small"
                            inputProps={{
                                style: {
                                    fontWeight: care.title == null ? 'regular' : 'bold',
                                    fontSize: care.title == null ? '15px' : '17px'
                                }
                            }}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            id="description"
                            name="description"
                            label={(care.description == "" || care.description == null) ? "Description" : ""}
                            fullWidth
                            error={care.description == null ? 0 : 1}
                            value={care.description}
                            disabled
                            size="small"
                            inputProps={{
                                style: {
                                    fontWeight: care.description == null ? 'regular' : 'bold',
                                    fontSize: care.description == null ? '15px' : '17px'
                                }
                            }}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            id="shortContent"
                            name="shortContent"
                            label={(care.shortContent == "" || care.shortContent == null) ? "Short Content" : ""}
                            fullWidth
                            value={care.shortContent}
                            error={care.shortContent == null ? 0 : 1}
                            disabled
                            size="small"
                            inputProps={{
                                style: {
                                    fontWeight: care.shortContent == null ? 'regular' : 'bold',
                                    fontSize: care.shortContent == null ? '15px' : '17px'
                                }
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} >
                        <TextField
                            id="executionTIme"
                            name="executionTIme"
                            label={(care.executionTime == "" || care.executionTime == null) ? "Execution Time" : ""}
                            fullWidth
                            value={(care.executionTime ?? 0) + " minutes"}
                            error={care.executionTime == null ? 0 : 1}
                            disabled
                            size="small"
                            inputProps={{
                                style: {
                                    fontWeight: care.executionTime == null ? 'regular' : 'bold',
                                    fontSize: care.executionTime == null ? '15px' : '17px'
                                }
                            }}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            id="fullContent"
                            name="fullContent"
                            label={(care.fullContent == "" || care.fullContent == null) ? "Full Content" : ""}
                            fullWidth
                            value={care.fullContent}
                            error={care.fullContent == null ? 0 : 1}
                            disabled
                            size="small"
                            inputProps={{
                                style: {
                                    fontWeight: care.fullContent == null ? 'regular' : 'bold',
                                    fontSize: care.fullContent == null ? '15px' : '17px'
                                }
                            }}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            id="type"
                            name="type"
                            label={(care.type == "" || care.type == null) ? "Type" : ""}
                            fullWidth
                            value={care.isBasicCare ? "Basic Care" : "Premium Care"}
                            disabled
                            size="small"
                            inputProps={{
                                style: {
                                    fontWeight: care.isBasicCare == null ? 'regular' : 'bold',
                                    fontSize: care.isBasicCare == null ? '15px' : '17px'
                                }
                            }}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            id="stepsTitle"
                            name="stepsTitle"
                            label={(care.stepsTitle == "" || care.stepsTitle == null) ? "Steps Title" : ""}
                            fullWidth
                            value={care.stepsTitle}
                            disabled
                            size="small"
                            inputProps={{
                                style: {
                                    fontWeight: care.stepsTitle == null ? 'regular' : 'bold',
                                    fontSize: care.stepsTitle == null ? '15px' : '17px'
                                }
                            }}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            id="stepsDescription"
                            name="stepsDescription"
                            label={(care.stepsDescription == "" || care.stepsDescription == null) ? "Steps Description" : ""}
                            fullWidth
                            value={care.stepsDescription}
                            disabled
                            size="small"
                            inputProps={{
                                style: {
                                    fontWeight: care.stepsDescription == null ? 'regular' : 'bold',
                                    fontSize: care.stepsDescription == null ? '15px' : '17px'
                                }
                            }}
                        />
                    </Grid>

                    <Grid
                        item
                        sx={{ mb: 3 }}
                    >
                        <React.Fragment>
                            {(care.steps ?? []).map((item) => (
                                <Grid item xs={12}>
                                    <TextField
                                        id={"step " + item.id}
                                        name={"step " + item.id}
                                        label={"Step " + item.id}
                                        fullWidth
                                        value={item.title}
                                        disabled
                                        size="small"
                                        inputProps={{
                                            style: {
                                                fontWeight: 'bold',
                                                fontSize: '17px'
                                            }
                                        }}
                                        sx={{ mt: 2 }}
                                    />
                                </Grid>
                                )
                            )}
                        </React.Fragment>
                    </Grid>
                </Grid>
            </Box>
        </ThemeProvider>
    );

}