import * as React from 'react';
import { styled } from '@mui/material/styles';
import Theme from '../../theme'
import { Typography, Grid, Paper } from '@mui/material';
import { Box } from '@mui/system';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabPanel from '../../views/TabPanel';
import SettingsUsersAccess from '../settings/SettingsUsersAccess'
import SettingsGeneral from '../settings/SettingsGeneral'

export default function Settings({ cares }) {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    function a11yProps(index) {
        return {
          id: `simple-tab-${index}`,
          'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    return (
        <Box display="flex" width="100%" sx={{ flexDirection: 'column', backgroundColor: Theme.palette.background.lightGray }} >
            <Typography variant="h4" fontWeight="medium" sx={{ ml: 14, mt: 10 }}>
                Settings
            </Typography>

            <Box 
                display="flex"
                sx={{ 
                    flexDirection: "row",
                    borderBottom: 1, 
                    borderColor: 'divider',
                    mt: 1,
                    ml: 14,
                    mr: 14,
                    backgroundColor: Theme.palette.background.lightGray
                }}
            >
                <CustomTabs 
                  value={value} 
                  onChange={handleChange}
                >
                    <CustomTab label="General" />
                    {/* <CustomTab label="Integrations" /> */}
                    <CustomTab label="Users and permissions" />
                </CustomTabs>
            </Box>

            <Box
                display="flex"
                width="100%"
                sx={{
                    justifyContent: 'center',
                    pl: 11,
                    pr: 11,
                    backgroundColor: Theme.palette.background.lightGray
                }}      
            >
                <TabPanel value={value} index={0}>
                    <SettingsGeneral />
                </TabPanel>

                {/* <TabPanel value={value} index={1}>
                    <SettingsGeneral />
                </TabPanel> */}

                <TabPanel value={value} index={1}>
                    <SettingsUsersAccess />
                </TabPanel>
            </Box>
        </Box>
    );

}

const CustomTabs = styled(Tabs)({
    borderBottom: '1px solid #e8e8e8',
    '& .MuiTabs-indicator': {
      backgroundColor: '#68594b',
    },
  });
  
  const CustomTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
    textTransform: 'none',
    minWidth: 0,
    [theme.breakpoints.up('sm')]: {
      minWidth: 0,
    },
    fontWeight: theme.typography.fontWeightMedium,
    marginRight: theme.spacing(1),
    color: 'rgba(0, 0, 0, 0.5)',
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
      color: '#756454',
      opacity: 1,
    },
    '&.Mui-selected': {
      color: '#68594b',
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&.Mui-focusVisible': {
      backgroundColor: '#756454',
    },
  }));