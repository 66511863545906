export default class Article {
    constructor(
        id = "",
        title = "",
        shortContent = "",
        sections = [],
        author = "",
        tag = "",
        imageUrl = "",
        readingTime = "",
        createdAt = ""
    ) {
        this.id = id
        this.title = title
        this.shortContent = shortContent
        this.sections = sections
        this.author = author
        this.tag = tag
        this.imageUrl = imageUrl
        this.readingTime = readingTime
        this.createdAt = createdAt
    }
}