import * as React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import Theme from '../../../theme'
import { TextField, Typography, Grid, IconButton, Stack, Button, Paper, Link, FormControlLabel, Checkbox } from '@mui/material';
import Article from '../../../models/article/Article';
import { Box } from '@mui/system';
import AddIcon from '@mui/icons-material/Add';
import ArticleSection from '../../../models/article/ArticleSection'
import { Delete, Edit } from '@mui/icons-material';

export default class NewArticleSections extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sections: this.props.article.sections ?? [],
            section: new ArticleSection(0, "", "", false),
            sectionsSubtitle: "",
            sectionsCount: 0,
            isEditing: false,
            isHighlighted: false
        };
    }

    componentDidMount() {
        this.setState({ sectionsCount: this.state.sections.length })
    }

    handleSectionTitleChange = (evt) => {
        var newSection = this.state.section;
        newSection.title = evt.target.value;
        this.setState({ section: newSection })
    }

    handleSectionContentChange = (evt) => {
        var newSection = this.state.section;
        newSection.content = evt.target.value;
        this.setState({ section: newSection })
    }

    handleSectionIdChange = (sectionId, value) => {
        const newId = parseInt(value.target.value)
        
        if (newId > (this.state.sections.length - 1)) {
            return
        }

        if (isNaN(parseInt(newId)) === true) {
            return
        }

        if (newId.length > 1) {
            return
        }

        var existingSectionWithProvidedId = this.state.sections.filter((item) => item.id == newId)[0]
        var modifingSection = this.state.sections.filter((item) => item.id == sectionId)[0]

        modifingSection.id = newId
        existingSectionWithProvidedId.id = sectionId

        var newSections = this.state.sections.filter((item) => item.id != newId).filter((item) => item.id != sectionId)
        
        newSections.push(existingSectionWithProvidedId)
        newSections.push(modifingSection)

        this.setState({ sections: newSections })
    }

    handleHighlightStateChange = () => {
        this.setState({ isHighlighted: !this.state.isHighlighted })
    }

    handleAdd = () => {
        var newSection = this.state.section;
        const isEditing = this.state.sections.filter((item) => item.content == newSection.content).length > 0;

        if (isEditing == false) {
            newSection.id = this.state.sectionsCount;
        }

        newSection.isHighlighted = this.state.isHighlighted

        var newSections = this.state.sections.filter((item) => item.content != newSection.content);

        newSections.push(newSection);

        this.setState({ sections: newSections });
        this.setState({ sectionsCount: newSections.length });

        const article = new Article(
            "",
            "",
            "",
            newSections,
            "",
            "",
            "",
            "",
            ""
        )

        this.sendDataToParent(article);
        this.setState({ section: new ArticleSection(0, "", "", false) });
        this.setState({ isEditing: false })
        this.setState({ isHighlighted: false })
    }

    handleRemove(id) {
        const newSections = this.state.sections.filter((item) => item.id !== id);

        this.setState({ sections: newSections });
        this.setState({ sectionsCount: (this.state.sectionsCount -= 1) });

        const article = new Article(
            "",
            "",
            "",
            newSections,
            "",
            "",
            "",
            "",
            ""
        )
        
        this.sendDataToParent(article)
    }

    handleEdit(id) {
        const editedSection = this.state.sections.filter((item) => item.id == id)[0];
        this.setState({ section: editedSection });
        this.setState({ isEditing: true });
    }

    sendDataToParent = (article) => {
        this.props.parentCallback(article)
    }

    render() {
        const article = this.props.article

        const Item = ({ item, onEdit, onRemove }) => (
            <React.Fragment>
                <Grid item xs={12} >
                    <Box sx={{ flexGrow: 1 }} >
                        <Paper 
                            elevation={2}
                            sx={{
                                p: 1,
                                flexGrow: 1
                            }}
                        >
                            <Stack
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <Stack
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="center"
                                >
                                    <TextField
                                        required
                                        multiline
                                        id="id"
                                        name="id"
                                        label=""
                                        autoComplete="id"
                                        value={item.id}
                                        onChange={(value) => this.handleSectionIdChange(item.id, value)}
                                        sx={{ marginRight: 2, width: 50 }}
                                    />

                                    <Stack
                                        direction="column"
                                        justifyContent="felx-start"
                                        alignItems="felx-start"
                                        sx={{ width: 350 }}
                                    >
                                        <Typography variant="body" fontWeight="bold">
                                            {item.title}
                                        </Typography>

                                        <Typography variant="body2">
                                            {item.content}
                                        </Typography>
                                    </Stack>
                                </Stack>
                                
                                <Stack
                                    direction="row"
                                    justifyContent="felx-end"
                                    alignItems="felx-end"
                                >
                                    <IconButton 
                                        aria-label="edit" 
                                        color="primary" 
                                        onClick={() => onEdit(item.id)} 
                                        sx={{ marginLeft: 2 }}
                                    >
                                        <Edit/>
                                    </IconButton>

                                    <IconButton 
                                        aria-label="delete" 
                                        color="error" 
                                        onClick={() => onRemove(item.id)}
                                    >
                                        <Delete/>
                                    </IconButton>
                                </Stack>
                            </Stack>
                        </Paper>
                    </Box>
                </Grid>
            </React.Fragment>
        );
        
        return (
            <ThemeProvider theme={Theme}>
                <React.Fragment>
                    <Grid container spacing={3}>
                    <Grid item xs={12}>
                    <TextField
                        id="subtitle"
                        name="subtitle"
                        label="Sections subtitle (disabled)"
                        fullWidth
                        autoComplete="subtitle"
                        disabled
                    />
                    </Grid>
                        <Grid item xs={12}>
                            <Box 
                                sx={{ 
                                    p: (this.state.sectionsCount == 0 ? 0 : 2),
                                    borderRadius: 1, 
                                    flexGrow: 1, 
                                    backgroundColor: Theme.palette.background.lightGray,
                                    mb: 3
                                }}
                            >
                                <Grid 
                                    container
                                    spacing={2}
                                >
                                    <React.Fragment>
                                        {((article.sections == [] || article.sections == null) ? this.state.sections : article.sections)
                                            .sort((a, b) => a.id > b.id)
                                            .map((item) => (
                                                <Item 
                                                    key={item.id} 
                                                    item={item} 
                                                    onEdit={() => this.handleEdit(item.id)}
                                                    onRemove={() => this.handleRemove(item.id)} 
                                                />
                                            ))
                                        }
                                    </React.Fragment>
                                </Grid>
                            </Box>
                            <Box sx={{ border: 1, p: 2, borderRadius: 1, borderColor: '#c4c4c4', flexGrow: 1 }}>
                                <Stack
                                    direction="column"
                                    justifyContent="felx-start"
                                    alignItems="stretch"
                                >
                                    <Grid item xs={12}>
                                        <TextField
                                            required
                                            multiline
                                            id="content"
                                            name="content"
                                            label="Content"
                                            fullWidth
                                            autoComplete="content"
                                            autoFocus="true"
                                            value={this.state.section.content}
                                            onChange={this.handleSectionContentChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sx={{ mt: 2 }}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox 
                                                    color="default" 
                                                    name="isHighlighted"
                                                    checked={this.state.isHighlighted}
                                                    onChange={this.handleHighlightStateChange}
                                                />
                                            }
                                            label="Mark as highlighted"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sx={{ mt: 2 }}>
                                        <Stack
                                            direction="row"
                                            justifyContent="space-between"
                                            alignItems="flex-end"
                                        >
                                            <TextField
                                                id="article_title"
                                                name="articleTitle"
                                                label="Article title"
                                                autoComplete="article title"
                                                multiline
                                                value={this.state.section.title}
                                                onChange={this.handleSectionTitleChange}
                                                sx={{ width: 0.65 }}
                                            />
                
                                            <Button 
                                                variant="contained" 
                                                startIcon={<AddIcon />}
                                                disabled={!this.state.section.content}
                                                onClick={this.handleAdd}
                                            >
                                                {this.state.isEditing ? "Update Section" : "Add Section"}
                                            </Button>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12} sx={{ mt: 2 }}>
                                        <Typography>
                                            <Link 
                                                href="https://www.hackingwithswift.com/quick-start/swiftui/how-to-render-markdown-content-in-text"
                                            >
                                                How to use markdowns in text?
                                            </Link>
                                        </Typography>
                                    </Grid>
                                </Stack>
                            </Box>
                        </Grid>
                    </Grid>
                </React.Fragment>
            </ThemeProvider>
        );
    }

}