import * as React from 'react';
import Theme from '../../theme'
import { Box, Stack, Typography, Grid, Paper, TextField, Button, Dialog,
    DialogActions,
    DialogContent,
    DialogTitle } from '@mui/material';
import Add from '@mui/icons-material/Add';
import { db, auth, logEvent } from '../../services/firebase';
import Calendar from '../../views/Calendar';
import Title from '../dashboard/Title';
import dayjs from 'dayjs';
import List from './AppointmentSlotsList';
import AppointmentsList from './AppointmentsList';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import Appointment from '../../models/Appointment';

// import { initGoogleSDK } from '../../services/google'

const utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

export default function Appointments() {

    // MARK: - Stored Properties

    const [selectedDate, setSelectedDate] = React.useState(dayjs());
    const [availableHours, setAvailableHours] = React.useState([]);
    const [appointments, setAppointments] = React.useState([]);

    const [newSlotTime, setNewSlotDate] = React.useState(null);
    const [isNewSlotDialogOpen, setIsNewSlotDialogOpen] = React.useState(false);

    // MARK: - Computed Properties

    const appointmentsCount = (appointments === undefined || appointments.length === 0) ? 
        0 : 
        appointments.filter((appointment) =>
            dayjs(appointment.date).format('YYYY-MM-DD') == dayjs(selectedDate).format('YYYY-MM-DD')
        ).length

    const availableHoursCount = (availableHours == undefined || availableHours.length == 0) ? 
        0 : 
        availableHours.filter((date) => 
            dayjs(date).format('YYYY-MM-DD') == dayjs(selectedDate).format('YYYY-MM-DD')
        ).length

    // MARK: - Effects

    React.useEffect(() => {
        observeAvailableHours();
        observeAppointments();
        // initGoogleSDK();
    }, []);

    // MARK: - Methods

    const observeAvailableHours = () => {
        db.ref().child('experts')
            .child(auth.currentUser.uid)
            .child('availableHours')
            .on('value', (snapshot) => {
                const availableHours = snapshot.val()

                if (availableHours !== undefined && availableHours !== null) {
                    setAvailableHours(availableHours)
                }
            }
        )
    }

    const observeAppointments = () => {
        db.ref().child('experts')
            .child(auth.currentUser.uid)
            .child('appointments')
            .on('value', (snapshot) => {
                const appointments = snapshot.val()
                
                if (appointments !== undefined && appointments !== null) {
                    const localAppointments = Object.values(appointments).map((value) => 
                        new Appointment(
                            value.id,
                            value.title,
                            value.date,
                            value.duration,
                            value.email, 
                            value.expertId,
                            value.expertName,
                            value.status,
                            value.userId,
                            value.userName,
                            value.userApproved,
                            value.expertApproved
                        )
                    )
                    setAppointments(localAppointments)
                }
            }
        )
    }

    const handleSelectedDateChange = (date) => {
        setSelectedDate(date)
    }

    const handleTimeSlotRemove = (date) => {
        const newAvailableHours = availableHours.filter((item) => item !== date)
        logEvent(`Available hour was deleted: ${date}`)
        updateAvailableHours(newAvailableHours)
    }

    // MARK: - New slot

    const handleNewSlotDateChange = (date) => {
        setNewSlotDate(date)
    }

    const handleOpenDialog = () => {
        setIsNewSlotDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setIsNewSlotDialogOpen(false);
        if (newSlotTime != null) {
            const localSlotTime = dayjs(newSlotTime)
            const date = selectedDate
            const newSlotTimeHour = localSlotTime.get('hour')
            const newSlotTimeMinute = localSlotTime.get('minute')
            const newSlotDateTime = dayjs(date)
                .set('hour', newSlotTimeHour)
                .set('minute', newSlotTimeMinute)

            const dateISO = newSlotDateTime.format();
            availableHours.push(dateISO)

            logEvent(`New available hour was added: ${dateISO}`)

            setNewSlotDate(null)
            updateAvailableHours(availableHours)
        }
    };

    const updateAvailableHours = (availableHours) => {
        const localAvailableHours = {};
        for (let i = 0; i < availableHours.length; i++) {
            if (availableHours[i] != undefined) {
                localAvailableHours[i] = availableHours[i];
            }
        }

        db.ref()
            .child('experts')
            .child(auth.currentUser.uid)
            .child('availableHours')
            .set(localAvailableHours)
    }

    // MARK: - Appointment details modal

    const handleOpen = () => setModalOpen(true);
    const handleClose = () => {
        setModalOpen(false);
    };

    // MARK: - Views

    return (
        <div>
            <Box sx={{ backgroundColor: Theme.palette.background.lightGray }}>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ mx: 14, mt: 10 }}
                >
                    <Typography variant="h4" fontWeight="medium">
                        Appointments
                    </Typography>
                </Stack>

                <Box 
                display="flex" 
                sx={{
                    flexWrap: 'wrap',
                    mb: 4, 
                    mt: 6,
                    mx: 14,
                    backgroundColor: Theme.palette.background.lightGray
                }}
                >
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={4} lg={4}>
                            <Paper
                                sx={{
                                    p: 2,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    height: '100%'
                                }}
                            >
                                <Calendar
                                    availableHours={availableHours}
                                    appointmentDates={appointments.map(value => value.date)}
                                    onSelectedDateChange={handleSelectedDateChange}
                                />
                            </Paper>
                        </Grid>

                        <Grid item xs={12} md={8} lg={8}>
                            <Paper
                                sx={{
                                    p: 4,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    height: '100%'
                                }}
                            >
                                <Stack
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="self-start"
                                    sx={{ mb: 4 }}
                                >
                                    <Stack
                                        direction="column"
                                    >
                                        <Title>{"Summary"}</Title>
                                        <Typography component="p" variant="h3">
                                            {availableHoursCount}
                                        </Typography>
                                        <Typography color="text.secondary" sx={{ flex: 1 }}>
                                            {`available ${availableHoursCount == 0 ? "slots" : (availableHoursCount == 1 ? "slot" : "slots")} for ${dayjs(selectedDate).format('DD.MM.YYYY')}`}
                                        </Typography>
                                    </Stack>

                                    <Button
                                        type="new slot"
                                        variant="contained"
                                        startIcon={<Add />}
                                        onClick={handleOpenDialog}
                                    >
                                        New Slot
                                    </Button>

                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <Dialog open={isNewSlotDialogOpen}>
                                            <DialogTitle>Select time slot</DialogTitle>
                                            <DialogContent>
                                                <TimePicker
                                                    ampm={false}
                                                    value={newSlotTime}
                                                    onChange={handleNewSlotDateChange}
                                                    renderInput={(params) => <TextField {...params} />}
                                                />
                                            </DialogContent>
                                            <DialogActions>
                                                <Button onClick={handleCloseDialog} color="primary">
                                                    Confirm
                                                </Button>
                                            </DialogActions>
                                        </Dialog>
                                    </LocalizationProvider>
                                </Stack>

                                <List 
                                    items={
                                        (availableHours ?? [])
                                            .filter((date) => 
                                                dayjs(date).format('YYYY-MM-DD') == dayjs(selectedDate).format('YYYY-MM-DD')
                                            )
                                    }
                                    onRemove={handleTimeSlotRemove}
                                />
                            </Paper>
                        </Grid>

                        <Grid item xs={12} md={12} lg={12}>
                            <Paper
                                sx={{
                                    p: 4,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    height: '100%'
                                }}
                            >
                                <Title>{"Summary"}</Title>
                                <Typography component="p" variant="h3">
                                    {appointmentsCount}
                                </Typography>
                                <Typography color="text.secondary" sx={{ flex: 1, mb: 4 }}>
                                    {`${appointmentsCount == 0 ? "appointments" : (appointmentsCount == 1 ? "appointment" : "appointments")} for ${dayjs(selectedDate).format('DD.MM.YYYY')}`}
                                </Typography>

                                <AppointmentsList 
                                    items={
                                        (appointments ?? [])
                                            .filter((appointment) => 
                                                dayjs(appointment.date).format('YYYY-MM-DD') == dayjs(selectedDate).format('YYYY-MM-DD')
                                            )
                                    }
                                />
                            </Paper>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </div>
    );
}