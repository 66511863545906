import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import NewCareContainer from './NewCareContainer'
import { creatorModalStyle } from '../../../utils/styles';

export default function AddNewCare({ 
  care, open, close, user 
}) {

  return (
      <Modal
        open={open}
        onClose={close}
      >
        <Box sx={creatorModalStyle}>
          <NewCareContainer editedCare={care} user={user} onClose={close}/>
        </Box>
      </Modal>
  );
}