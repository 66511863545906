import HairCarePlan from './plan/HairCarePlan';
import HairCarePlanInfo from './plan/HairCarePlanInfo';

export default class User {
  constructor(
    id = "",
    email = "",
    name = "",
    role = "",
    accountLocked = false,
    deviceToken = "",
    hairCarePlanInfo = new HairCarePlanInfo,
    hairCarePlan = new HairCarePlan
  ) {
    this.id = id;
    this.email = email;
    this.name = name;
    this.role = role;
    this.accountLocked = accountLocked;
    this.deviceToken = deviceToken;
    this.hairCarePlanInfo = hairCarePlanInfo;
    this.hairCarePlan = hairCarePlan;
  }
}