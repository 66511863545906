import * as React from 'react';
import { LinearProgress } from '@mui/material';
import lottie from "lottie-web/build/player/lottie_light";
import loading from "../utils/loadingDots.json";
import { useEffect } from 'react';
import { Box } from '@mui/system';

export const LoadingPage = () => {
    useEffect(() => {
        lottie.loadAnimation({
            container: document.querySelector("#loading"),
            animationData: loading,
            loop: true,
            autoplay: true
        });
      }, []);

    return (
        <React.Fragment>
            <Box
                display="flex"
                height="100vh"
                width="100vw"
                justifyContent="center"
                alignItems="center"
            >
                <Box
                    id="loading" 
                    sx={{ 
                        width: 1080, 
                        height: 1080, 
                        zoom: 0.3 
                    }}
                />
            </Box>
        </React.Fragment>
    );
};