import { getDateMonthLater } from "../../utils/extensions";
import { StatusLabelType } from "../../views/StatusLabel";

export default class HairCarePlan {
  constructor(
    id = "",
    name = "",
    createdAt = "",
    startsAt = new Date(),
    endsAt = getDateMonthLater(),
    userId = "",
    expertId = "",
    notes = "",
    cares = [],
    productNotes = "",
    products = [],
    supplementNotes = "",
    supplements = [],
    treatments = [],
    countryCode = "PL",
    status = StatusLabelType.PENDING.value.toLowerCase()
) {
    this.cares = cares;
    this.createdAt = createdAt;
    this.endsAt = endsAt;
    this.expertId = expertId;
    this.id = id;
    this.name = name;
    this.notes = notes;
    this.productNotes = productNotes;
    this.products = products;
    this.startsAt = startsAt;
    this.supplementNotes = supplementNotes;
    this.supplements = supplements;
    this.treatments = treatments;
    this.userId = userId;
    this.endsAt = endsAt;
    this.countryCode = countryCode;
    this.status = status;
  }
}