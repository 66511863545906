import * as React from "react";
import {
  Typography,
  Box,
  Modal,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell
} from "@mui/material";
import { getMonthDifference } from "../../utils/extensions";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 12,
    borderRadius: "8px",
    overflow: "scroll",
    display: "block",
    p: 3,
    height: 500,
    minWidth: 500,
  };

export function HairQuestionaireDetailsModal({ hcpInfo, open, onClose }) {
  
    return (
      <Modal
        open={open}
        onClose={onClose}
      >
        <Box sx={style}>
          <Typography variant="h6" sx={{ ml: 2, mb: 2 }}>
            HAIR PLAN DETAILS
          </Typography>
          <TableContainer>
            <Table size="small">
              <TableBody>
                <TableRow sx={{ "& td": { border: 0 } }}>
                  <TableCell
                    sx={{ fontSize: "13px", color: "gray", width: 150 }}
                  >
                    Duration
                  </TableCell>
                  <TableCell sx={{ fontSize: "15px", fontWeight: "bold" }}>
                    {getMonthDifference(
                      hcpInfo.startDate,
                      hcpInfo.endDate
                    )}
                  </TableCell>
                </TableRow>
  
                <TableRow>
                  <TableCell
                    sx={{ fontSize: "13px", color: "gray", width: 150 }}
                  >
                    User Identifier
                  </TableCell>
                  <TableCell sx={{ fontSize: "15px" }}>
                    {hcpInfo.userId}
                  </TableCell>
                </TableRow>
  
                <TableRow sx={{ "& td": { border: 0 } }}>
                  <TableCell
                    sx={{
                      fontSize: "15px",
                      fontWeight: "medium",
                      width: 150,
                      pt: 3,
                    }}
                  >
                    Hair Data
                  </TableCell>
                </TableRow>
  
                <TableRow sx={{ "& td": { border: 0 } }}>
                  <TableCell
                    sx={{ fontSize: "13px", color: "gray", width: 150 }}
                  >
                    Allergies
                  </TableCell>
                  <TableCell sx={{ fontSize: "15px" }}>
                    {hcpInfo.hairData.allergies}
                  </TableCell>
                </TableRow>
  
                <TableRow sx={{ "& td": { border: 0 } }}>
                  <TableCell
                    sx={{ fontSize: "13px", color: "gray", width: 150 }}
                  >
                    Budget
                  </TableCell>
                  <TableCell sx={{ fontSize: "15px" }}>
                    {hcpInfo.hairData.budget}
                  </TableCell>
                </TableRow>
  
                <TableRow sx={{ "& td": { border: 0 } }}>
                  <TableCell
                    sx={{ fontSize: "13px", color: "gray", width: 150 }}
                  >
                    Coloring
                  </TableCell>
                  <TableCell sx={{ fontSize: "15px" }}>
                    {hcpInfo.hairData.coloring}
                  </TableCell>
                </TableRow>
  
                <TableRow sx={{ "& td": { border: 0 } }}>
                  <TableCell
                    sx={{ fontSize: "13px", color: "gray", width: 150 }}
                  >
                    Curl extracting
                  </TableCell>
                  <TableCell sx={{ fontSize: "15px" }}>
                    {hcpInfo.hairData.curlExtracting}
                  </TableCell>
                </TableRow>
  
                <TableRow sx={{ "& td": { border: 0 } }}>
                  <TableCell
                    sx={{ fontSize: "13px", color: "gray", width: 150 }}
                  >
                    Curliness
                  </TableCell>
                  <TableCell sx={{ fontSize: "15px" }}>
                    {hcpInfo.hairData.curliness}
                  </TableCell>
                </TableRow>
  
                <TableRow sx={{ "& td": { border: 0 } }}>
                  <TableCell
                    sx={{ fontSize: "13px", color: "gray", width: 150 }}
                  >
                    Current color
                  </TableCell>
                  <TableCell sx={{ fontSize: "15px" }}>
                    {hcpInfo.hairData.currentColor}
                  </TableCell>
                </TableRow>
  
                <TableRow sx={{ "& td": { border: 0 } }}>
                  <TableCell
                    sx={{ fontSize: "13px", color: "gray", width: 150 }}
                  >
                    Diseases
                  </TableCell>
                  <TableCell sx={{ fontSize: "15px" }}>
                    {hcpInfo.hairData.diseases}
                  </TableCell>
                </TableRow>
  
                <TableRow sx={{ "& td": { border: 0 } }}>
                  <TableCell
                    sx={{ fontSize: "13px", color: "gray", width: 150 }}
                  >
                    Durability
                  </TableCell>
                  <TableCell sx={{ fontSize: "15px" }}>
                    {hcpInfo.hairData.durability}
                  </TableCell>
                </TableRow>
  
                <TableRow sx={{ "& td": { border: 0 } }}>
                  <TableCell
                    sx={{ fontSize: "13px", color: "gray", width: 150 }}
                  >
                    Goals
                  </TableCell>
                  <TableCell sx={{ fontSize: "15px" }}>
                    {hcpInfo.hairData.goals != undefined ? hcpInfo.hairData.goals.join(", ") : ""}
                  </TableCell>
                </TableRow>
  
                <TableRow sx={{ "& td": { border: 0 } }}>
                  <TableCell
                    sx={{ fontSize: "13px", color: "gray", width: 150 }}
                  >
                    Length
                  </TableCell>
                  <TableCell sx={{ fontSize: "15px" }}>
                    {hcpInfo.hairData.length}
                  </TableCell>
                </TableRow>
  
                <TableRow sx={{ "& td": { border: 0 } }}>
                  <TableCell
                    sx={{ fontSize: "13px", color: "gray", width: 150 }}
                  >
                    Medical check
                  </TableCell>
                  <TableCell sx={{ fontSize: "15px" }}>
                    {hcpInfo.hairData.medicalCheck}
                  </TableCell>
                </TableRow>
  
                <TableRow sx={{ "& td": { border: 0 } }}>
                  <TableCell
                    sx={{ fontSize: "13px", color: "gray", width: 150 }}
                  >
                    Porosity
                  </TableCell>
                  <TableCell sx={{ fontSize: "15px" }}>
                    {hcpInfo.hairData.porosity}
                  </TableCell>
                </TableRow>
  
                <TableRow sx={{ "& td": { border: 0 } }}>
                  <TableCell
                    sx={{ fontSize: "13px", color: "gray", width: 150 }}
                  >
                    Problems
                  </TableCell>
                  <TableCell sx={{ fontSize: "15px" }}>
                    {hcpInfo.hairData.problems}
                  </TableCell>
                </TableRow>
  
                <TableRow sx={{ "& td": { border: 0 } }}>
                  <TableCell
                    sx={{ fontSize: "13px", color: "gray", width: 150 }}
                  >
                    Rituals
                  </TableCell>
                  <TableCell sx={{ fontSize: "15px" }}>
                    {hcpInfo.hairData.rituals}
                  </TableCell>
                </TableRow>
  
                <TableRow sx={{ "& td": { border: 0 } }}>
                  <TableCell
                    sx={{ fontSize: "13px", color: "gray", width: 150 }}
                  >
                    Softness
                  </TableCell>
                  <TableCell sx={{ fontSize: "15px" }}>
                    {hcpInfo.hairData.softness}
                  </TableCell>
                </TableRow>
  
                <TableRow sx={{ "& td": { border: 0 } }}>
                  <TableCell
                    sx={{ fontSize: "13px", color: "gray", width: 150 }}
                  >
                    Styling
                  </TableCell>
                  <TableCell sx={{ fontSize: "15px" }}>
                    {hcpInfo.hairData.styling}
                  </TableCell>
                </TableRow>
  
                <TableRow sx={{ "& td": { border: 0 } }}>
                  <TableCell
                    sx={{ fontSize: "13px", color: "gray", width: 150 }}
                  >
                    Supplements
                  </TableCell>
                  <TableCell sx={{ fontSize: "15px" }}>
                    {hcpInfo.hairData.supplements}
                  </TableCell>
                </TableRow>
  
                <TableRow sx={{ "& td": { border: 0 } }}>
                  <TableCell
                    sx={{ fontSize: "13px", color: "gray", width: 150 }}
                  >
                    Thickness
                  </TableCell>
                  <TableCell sx={{ fontSize: "15px" }}>
                    {hcpInfo.hairData.thickness}
                  </TableCell>
                </TableRow>
  
                <TableRow sx={{ "& td": { border: 0 } }}>
                  <TableCell
                    sx={{ fontSize: "13px", color: "gray", width: 150 }}
                  >
                    Washing days
                  </TableCell>
                  <TableCell sx={{ fontSize: "15px" }}>
                    {hcpInfo.hairData.washingDays != undefined ? hcpInfo.hairData.washingDays.join(", ") : ""}
                  </TableCell>
                </TableRow>
  
                <TableRow sx={{ "& td": { border: 0 } }}>
                  <TableCell
                    sx={{ fontSize: "13px", color: "gray", width: 150 }}
                  >
                    Washing time
                  </TableCell>
                  <TableCell sx={{ fontSize: "15px" }}>
                    {hcpInfo.hairData.washingTime}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Modal>
    )
  }