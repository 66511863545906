import * as React from 'react';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from '../dashboard/Title';
import Theme from '../../theme';
import IconEdit from '@mui/icons-material/Edit';
import IconDelete from '@mui/icons-material/Delete';
import { IconButton, Stack } from '@mui/material';
import { StatusLabel, StatusLabelType } from '../../views/StatusLabel';
import { Box } from '@mui/system';
import FilterSelector from '../../views/FilterSelector';
import { useState } from 'react';
import { statusLabelType } from '../../utils/extensions';


function preventDefault(event) {
  event.preventDefault();
}

export default function PlansList(props) {

  const [selectedFilter, setFilter] = useState('ALL');

  const onValueChange = (value) => {
    setFilter(value)
  }

  return (
    <React.Fragment>
      <Stack
        display="flex"
        direction="row"
        justifyContent="space-between"
      >
        <Title>{props.title}</Title>
        <FilterSelector 
          tags={['ALL', 'ACTIVE', 'ENDED']}
          onValueChange={onValueChange}
        />
      </Stack>
      <Table size="medium">
      <TableHead>
          <TableRow>
            <TableCell>Created at</TableCell>
            <TableCell>Title</TableCell>
            <TableCell>Starts At</TableCell>
            <TableCell>Ends At</TableCell>
            <TableCell align="center">Status</TableCell>
            {
              props.allowEditing ? 
              <TableCell>Actions</TableCell> :
                null
            }
          </TableRow>
        </TableHead>
        <TableBody>
          {props.plans.filter((item) =>
            {
              if (selectedFilter == 'ACTIVE') {
                return new Date(item.endsAt) > new Date()
              } else if (selectedFilter == 'ENDED') {
                return new Date(item.endsAt) < new Date()
              } else {
                return true
              }
            }
          ).map((plan) => (
            <TableRow key={plan.id}>
              <TableCell>{new Date(plan.createdAt).toLocaleDateString()}</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>{plan.name}</TableCell>
              <TableCell>{new Date(plan.startsAt).toLocaleDateString()}</TableCell>
              <TableCell>{new Date(plan.endsAt).toLocaleDateString()}</TableCell>
              <TableCell align="center" sx={{ width: 150, paddingX: 5 }}>
                <StatusLabel 
                  type={statusLabelType(plan.status)}
                />
              </TableCell>
              {
                props.allowEditing ?
                <TableCell>
                    <Stack direction='row' flex={1} spacing={6} sx={{ width: 150 }}>
                      <IconButton onClick={() => { props.sendOnEdit(plan) }}>
                          <IconEdit sx={{ color: Theme.palette.primary.main }}/>
                        </IconButton>

                        <IconButton 
                        onClick={() => { props.sendOnRemove(plan) }}
                      >
                        <IconDelete sx={{ color: Theme.palette.error.main }} />
                      </IconButton>
                    </Stack>
                    </TableCell>
                  : null
              }
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Link color="primary" href="#" onClick={preventDefault} sx={{ mt: 3 }}>
        See more items
      </Link>
    </React.Fragment>
  );
}