export default class Repeats {

    constructor(
      selectedWeekDays = [0, 1, 2, 3, 4, 5, 6],
      selectedWeeksNumber = 0, 
      selectedDate = new Date(),
      selectedTabIndex = 0
    ) {
      this.selectedWeekDays = selectedWeekDays;
      this.selectedWeeksNumber = selectedWeeksNumber;
      this.selectedDate = selectedDate;
      this.selectedTabIndex = selectedTabIndex;
    }
  
  }