import { StatusLabelType } from "../views/StatusLabel";

export function getDateMonthLater() {
    var date = new Date()
    return new Date(date.setMonth(date.getMonth() + 1));
}

export const getMonthDifference = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
  
    const diff = Math.abs(end - start);
    const approxMonths = Math.floor(diff / (1000 * 60 * 60 * 24 * 30));
  
    return `${approxMonths} ${approxMonths == 1 ? "month" : "months"}`
};

export function translatedStatusPL(string) {
    if (string == StatusLabelType.PENDING.value.toLowerCase()) {
        return "Oczekująca"
    } else if (string == StatusLabelType.CONFIRMED.value.toLowerCase()) {
        return "Potwierdzona"
    } else if (string == StatusLabelType.COMPLETED.value.toLowerCase()) {
        return "Zakończona"
    } else if (string == StatusLabelType.CANCELED.value.toLowerCase()) {
        return "Anulowana"
    } else {
        return "Nieznana"
    }
}

export function statusLabelType(string) {
    if (string.toLowerCase() == StatusLabelType.ACTIVE.value.toLowerCase()) {
        return StatusLabelType.ACTIVE
    } else if (string.toLowerCase() == StatusLabelType.ASSIGNED.value.toLowerCase()) {
        return StatusLabelType.ASSIGNED
    } else if (string.toLowerCase() == StatusLabelType.PENDING.value.toLowerCase()) {
        return StatusLabelType.PENDING
    } else if (string.toLowerCase() == StatusLabelType.CONFIRMED.value.toLowerCase()) {
        return StatusLabelType.CONFIRMED
    } else if (string.toLowerCase() == StatusLabelType.COMPLETED.value.toLowerCase()) {
        return StatusLabelType.COMPLETED
    } else if (string.toLowerCase() == StatusLabelType.CANCELED.value.toLowerCase()) {
        return StatusLabelType.CANCELED
    }   else if (string.toLowerCase() == StatusLabelType.ENDED.value.toLowerCase()) {
        return StatusLabelType.ENDED
    } else {
        return StatusLabelType.UNASSIGNED
    }
} 

export const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

export function monthIndexString(string) {
    if (string === "") {
        return ""
    }
    const monthIndex = monthNames.indexOf(string) + 1
    return (monthIndex < 10 ? `0${monthIndex}` : String(monthIndex))
}