import * as React from 'react';
import ReactDOM from 'react-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import App from './app/App';
import Theme from './theme';
import { Box } from '@mui/system';

class AppContainter extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn: true
    };
  }

  toggleShowHide = () => {
    this.setState(state => ({ isLoggedIn: !state.isLoggedIn }));
  };

  render() {
    return (
      <React.Fragment>
      <ThemeProvider theme={Theme}>
        <Box display="flex" height="100%" sx={{ backgroundColor: Theme.palette.background.lightGray }} >
          <CssBaseline />
            <App onLogin={this.toggleShowHide} />
        </Box>
      </ThemeProvider>
    </React.Fragment>
    )
  }

}

ReactDOM.render(
  <AppContainter />,
  document.getElementById('root'),
);

