export default class HairData {
    constructor(
        allergies = "", 
        budget = "", 
        coloring = "",
        curlExtracting = "",
        curliness = "",
        currentColor = "",
        diseases = "", 
        durability = "", 
        goals = [],
        length = "",
        medicalCheck = "",
        porosity = "",
        problems = "", 
        rituals = "", 
        softness = "",
        styling = "",
        supplements = "",
        thickness = "",
        washingDays = [],
        washingTime = ""
    ) {
        this.allergies = allergies;
        this.budget = budget;
        this.coloring = coloring;
        this.curlExtracting = curlExtracting;
        this.curliness = curliness;
        this.currentColor = currentColor;
        this.diseases = diseases;
        this.durability = durability;
        this.goals = goals;
        this.length = length;
        this.medicalCheck = medicalCheck;
        this.porosity = porosity;
        this.problems = problems;
        this.rituals = rituals;
        this.softness = softness;
        this.styling = styling;
        this.supplements = supplements;
        this.thickness = thickness;
        this.washingDays = washingDays;
        this.washingTime = washingTime;
      }
}