export default class Appointment {

    constructor(
      id = "",
      title = "",
      date = "", 
      duration = "", 
      email = "",
      expertId = "",
      expertName = "",
      status = "",
      userId = "",
      userName = "",
      userApproved = false,
      expertApproved = false
    ) {
      this.id = id;
      this.title = title
      this.date = date;
      this.duration = duration;
      this.email = email;
      this.expertId = expertId;
      this.expertName = expertName;
      this.status = status;
      this.userId = userId;
      this.userName = userName;
      this.userApproved = userApproved;
      this.expertApproved = expertApproved;
    }
}