import { Box } from '@mui/material';
import { ReactComponent as Logo } from '../img/ic_logo.svg';
import { ReactComponent as LogoDebug } from '../img/ic_logo_debug.svg';

export default function LogoImage({ size = 60 }) {
    const sizeString = size.toString() + "px"
    return (
        <Box>
            {process.env.NODE_ENV === "development" ?
                <LogoDebug width={sizeString} height={sizeString}/> :
                <Logo width={sizeString} height={sizeString}/>
            }
        </Box>
    )
}