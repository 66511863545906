export const creatorModalStyle = {
    display:'block',
    alignItems: 'center',
    justifyContent: 'center',
    width: '50%',
    height: '90%',
    margin: '3% auto 5% auto',
    bgcolor: 'background.paper',
    boxShadow: '12',
    borderRadius: '8px',
    overflow:'scroll',
};

export const boardStyle = {
    position: 'relative',
    height:'100%',
    width:'100%'
};