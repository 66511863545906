import firebase from 'firebase/compat/app'
import 'firebase/compat/auth';
import 'firebase/compat/database';
import "firebase/compat/remote-config";
import { firebaseConfigDebug, firebaseConfigProd } from "../configs/config.js";
import Article from '../models/article/Article.js';
import Care from '../models/Care.js';
import HairCarePlan from '../models/plan/HairCarePlan.js';
import axios from 'axios';

import { getRemoteConfig } from "firebase/remote-config";
import { fetchAndActivate } from "firebase/remote-config";
import { getValue } from "firebase/remote-config";
import Environment from '../models/Environment.js';
import { translatedStatusPL } from '../utils/extensions.js';

// MARK: - Initialization

const app = firebase.initializeApp((process.env.NODE_ENV === "production") ? firebaseConfigProd : firebaseConfigDebug);

const remoteConfig = getRemoteConfig(app);
remoteConfig.settings.minimumFetchIntervalMillis = (process.env.NODE_ENV === "production") ? 43200 : 0;

const activateRemoteConfig = async () => {
  try {
    await fetchAndActivate(remoteConfig)
      .then(() => {
        console.debug("Remote config activated")
      })
      .catch((err) => {
        console.error(err);
      });
  } catch (error) {
    console.error(err);
  }
};

// MARK: - Stored Properties

const objectType = { Care: 'Care', Article: 'Article', Plan: 'Plan' };

export const auth = firebase.auth();
export const db = firebase.database();

// Firebase Auth Email & Password

const signInWithEmailAndPassword = async (email, password) => {
    try {
      await auth.signInWithEmailAndPassword(email, password);
      logEvent(`User with email: ${email} was signed in`);
    } catch (err) {
      console.error(err);
      logEvent(`Unabled to sign in user with email: ${email}`)
      alert(err.message);
    }
};

const registerWithEmailAndPassword = async (name, email, password) => {
    try {
      const res = await auth.createUserWithEmailAndPassword(email, password);
      const user = res.user;
      await db.collection("panelUsers").add({
        uid: user.uid,
        name,
        authProvider: "local",
        email,
      });
    } catch (err) {
      console.error(err);
      logEvent(`Unabled to register user with email: ${email}`)
      alert(err.message);
    }
};

const sendPasswordResetEmail = async (email) => {
    try {
      await auth.sendPasswordResetEmail(email);
      alert("Password reset link sent!");
    } catch (err) {
      console.error(err);
      alert(err.message);
    }
};

// User

const createUser = async (user, password) => {
  try {
    const res = await auth.createUserWithEmailAndPassword(user.email, password);
    const userId = res.user.uid;
    let createdAt = new Date().toISOString()

    await firebase.database().ref()
      .child("panelUsers")
      .child(userId)
      .set({
        name: user.name,
        email: user.email,
        role: user.role,
        createdAt: createdAt,
        accountLocked: false,
        token: "",
      });
    logEvent(`User with email: ${user.email} was created`)
  } catch (err) {
    console.error(err);
    logEvent(`Unabled to create user with email: ${user.email}`)
    alert(err.message);
  }
}

const getUsersCount = async () => {
  return await firebase.database().ref()
    .child("users")
    .get()
    .then((snapshot) => {
      return snapshot.numChildren()
    })
}

const updateUserRole = async (userId, newRole) => {
  await firebase.database().ref()
    .child("panelUsers")
    .child(userId)
    .update({role: newRole})
}

const updateUserLockedAccountState = async (userId, isLocked) => {
  await firebase.database().ref()
    .child("panelUsers")
    .child(userId)
    .update({accountLocked: isLocked})
}

const removeUser = async (userId) => {
  await firebase.database().ref()
    .child("panelUsers")
    .child(userId)
    .remove()
}

const getUserToken = async (userId) => {
  return await firebase.database().ref()
    .child("users")
    .child(userId)
    .child("token")
    .get()
    .then((snapshot) => {
      return snapshot.val()
    })
}

// Firebase Database

const saveCare = async (care, user) => {
  const reference = firebase.database().ref()

  if (user.role == "Expert") {
    const ref = reference.child("cares").child("expert")
    uploadObject(care, ref, objectType.Care)

    const personalRef = reference.child("experts").child(user.id).child("cares")
    uploadObject(care, personalRef, objectType.Care)
  } else {
    const ref = reference.child("cares").child((care.isBasicCare ? "basic" : "premium"))
    uploadObject(care, ref, objectType.Care)
  }
}

const sendPlanToVerification = async (plan, user) => {
  const reference = firebase.database().ref()

  const ref = reference.child("plans")
  uploadObject(plan, ref, objectType.Plan)

  const personalRef = reference.child("experts").child(user.id).child("plans")
  uploadObject(plan, personalRef, objectType.Plan)
}

const sendPlanToUser = async (plan, deviceToken) => {
  const reference = firebase.database().ref()

  const ref = reference.child("plans")
  uploadObject(plan, ref, objectType.Plan)

  const personalRef = reference.child("experts").child(plan.expertId).child("plans")
  uploadObject(plan, personalRef, objectType.Plan)

  const clientRef = reference.child("users").child(plan.userId).child("hairCarePlan")
  uploadUserPlan(plan, clientRef, deviceToken)
}

const saveArticle = async (article) => {
  const ref = firebase.database().ref().child("articles")
  uploadObject(article, ref, objectType.Article)
}

const uploadObject = async (object, ref, type) => {
  const newObject = object

  if (type == objectType.Article) {
    const article = new Article(
      newObject.id, 
      newObject.title, 
      newObject.shortContent, 
      newObject.sections, 
      newObject.author, 
      newObject.tag, 
      newObject.imageUrl, 
      newObject.readingTime, 
      newObject.createdAt
    )

    try {
      await ref.child(article.id).once("value", async snapshot => {
        if (snapshot.exists()) {
          console.log("exists!");

          logEvent(`Article ${article.title} with identifier: ${article.id} was uploaded`)
          
          await ref.child(article.id)
            .update(article);
        }
        else {
          const uniqueId = ref.push().key
          article.id = uniqueId

          logEvent(`Article ${article.title} with identifier: ${article.id} was uploaded`)
  
          await ref.child(article.id)
            .set(article);
        }
      });
    } catch (error) {
      const uniqueId = ref.push().key
      article.id = uniqueId

      logEvent(`Article ${article.title} with identifier: ${article.id} was uploaded`)
      
      await ref.child(article.id)
        .set(article);
    }
  } else if (type == objectType.Plan) {
    const plan = new HairCarePlan(
      newObject.id,
      newObject.name, 
      newObject.createdAt, 
      newObject.startsAt, 
      newObject.endsAt, 
      newObject.userId,
      newObject.expertId,
      newObject.notes,
      newObject.cares,
      newObject.productNotes,
      newObject.products,
      newObject.supplementNotes,
      newObject.supplements,
      newObject.treatments,
      newObject.countryCode,
      newObject.status
    )

    try {
      await ref.child(plan.id).once("value", async snapshot => {
        if (snapshot.exists()) {
          console.log("exists!");

          logEvent(`Hair Care Plan ${plan.name} with identifier: ${plan.id} for user with identifier: ${plan.userId} was uploaded with status: ${plan.status}`)
          
          await ref.child(plan.id)
            .update(plan);
        }
        else {
          logEvent(`Hair Care Plan ${plan.name} with identifier: ${plan.id} for user with identifier: ${plan.userId} was uploaded with status: ${plan.status}`)
  
          await ref.child(plan.id)
            .set(plan);
        }
      });
    } catch (error) {
      const uniqueId = ref.push().key
      plan.id = uniqueId

      logEvent(`Hair Care Plan ${plan.name} with identifier: ${plan.id} for user with identifier: ${plan.userId} was uploaded with status: ${plan.status}`)
      
      await ref.child(plan.id)
        .set(plan);
    }
  } else {
    const care = new Care(
      newObject.id, 
      newObject.createdAt, 
      newObject.title,
      newObject.description, 
      newObject.shortDescription, 
      newObject.purpose, 
      newObject.duration, 
      newObject.repeats, 
      newObject.dayTime, 
      newObject.isBasicCare, 
      newObject.imageUrl, 
      newObject.products, 
      newObject.notes, 
      newObject.steps, 
      newObject.suggestedNextCare
    )

    try {
      await ref.child(care.id).once("value", async snapshot => {
        if (snapshot.exists()) {
          console.log("exists!");

          logEvent(`Care ${care.title} with identifier: ${care.id} was uploaded`)
          
          await ref.child(care.id)
            .update(care);
        }
        else {
          const uniqueId = ref.push().key
          care.id = uniqueId

          logEvent(`Care ${care.title} with identifier: ${care.id} was uploaded`)
  
          await ref.child(care.id)
            .set(care);
        }
      });
    } catch (error) {
      const uniqueId = ref.push().key
      care.id = uniqueId

      logEvent(`Care ${care.title} with identifier: ${care.id} was uploaded`)
      
      await ref.child(care.id)
        .set(care);
    }
  }
}

const uploadUserPlan = async (object, ref, deviceToken) => {
  const newObject = object

  const plan = new HairCarePlan(
    newObject.id,
    newObject.name, 
    newObject.createdAt, 
    newObject.startsAt, 
    newObject.endsAt, 
    newObject.userId,
    newObject.expertId,
    newObject.notes,
    newObject.cares,
    newObject.productNotes,
    newObject.products,
    newObject.supplementNotes,
    newObject.supplements,
    newObject.treatments,
    newObject.countryCode,
    newObject.status
  )

  try {
    await ref.once("value", async snapshot => {
      if (snapshot.exists()) {
        console.log("exists!");
        logEvent(`Hair Care Plan ${plan.name} with identifier: ${plan.id} for user with identifier: ${plan.userId} was uploaded`)
        
        await ref.update(plan);
      }
      else {
        const uniqueId = ref.push().key
        plan.id = uniqueId

        logEvent(`Hair Care Plan ${plan.name} with identifier: ${plan.id} for user with identifier: ${plan.userId} was uploaded`)

        await ref.set(plan);
      }
    });
  } catch (error) {
    const uniqueId = ref.push().key
    plan.id = uniqueId

    logEvent(`Hair Care Plan ${plan.name} with identifier: ${plan.id} for user with identifier: ${plan.userId} was uploaded`)
    
    await ref.set(plan);
  }

  try {
    await firebase.database().ref()
      .child("users")
      .child(plan.userId)
      .child("hairCarePlanInfo")
      .update({ endDate: plan.endsAt })
    
    await sendHCPNotification(deviceToken, plan.countryCode)
  } catch (error) {
    console.error('Error:', error);
  }
  
}

// MARK: - FCM Notifications

const sendHCPNotification = async (deviceToken, countryCode) => {
  try {
    const fcmEndpoint = `https://fcm.googleapis.com/fcm/send`;
    const headers = {
      "Authorization": getServerKey(),
      "Content-Type": "application/json"
    };

    const body = {
      "to": deviceToken,
      "notification": {
        "body": `${(countryCode === "PL") ? "Przejdź do profilu i sprawdź, co dla Ciebie przygotowaliśmy." : "Go to the profile and check, what we have prepared for you."}`,
        "title": `${(countryCode === "PL") ? "Plan Pielęgnacyjny jest już dostępny!" : "Hair Care Plan is now available!"}`,
      }
    }

    const response = await axios.post(fcmEndpoint, body, { headers });
    console.log('Notification sent successfully:', response.data);
    logEvent(`HCP notification was send successfully`);
  } catch (error) {
    console.error('Error sending notification:', error);
    logEvent(`Unable to send HCP notification`);
  }
}

const sendAppointmentStatusNotification = async (deviceToken, countryCode, status) => {
  try {
    const fcmEndpoint = `https://fcm.googleapis.com/fcm/send`;
    const headers = {
      "Authorization": getServerKey(),
      "Content-Type": "application/json"
    };

    const body = {
      "to": deviceToken,
      "notification": {
        "body": `${(countryCode === "PL") ? `Status Twojej konsultacji został zmieniony na: ${translatedStatusPL(status)}` : `Your consultation status was changed to "${status}"`}`,
        "title": `${(countryCode === "PL") ? "Aktualizacja statusu konsultacji" : "Consultation status update"}`,
      }
    }

    const response = await axios.post(fcmEndpoint, body, { headers });
    console.log('Notification sent successfully:', response.data);
    logEvent(`HCP notification was send successfully`);
  } catch (error) {
    console.error('Error sending notification:', error);
    logEvent(`Unable to send HCP notification`);
  }
}

const getServerKey = () => {
  try {
    const jsonData = getValue(remoteConfig, "env");
    const jsonObject = JSON.parse(jsonData.asString());
    const environment = Object.assign(new Environment(), jsonObject);

    const serverKey = environment.serverKey;
    const debugServerKey = environment.serverKeyDebug;

    return `key=${(process.env.NODE_ENV === "production") ? serverKey : debugServerKey}`
  } catch(error) {
    console.error('Error sending notification:', error);
    return undefined
  }
}

const getGoogleApiKey = () => {
  try {
    const jsonData = getValue(remoteConfig, "env");
    const jsonObject = JSON.parse(jsonData.asString());
    const environment = Object.assign(new Environment(), jsonObject);
    return environment.googleApiKey;
  } catch(error) {
    console.error('Error sending notification:', error);
    return undefined
  }
}

// MARK: - Logs

const logEvent = async (message) => {
  try {
    const logMessage = `[DATE]${new Date()}[USER]${auth.currentUser.email}[MESSAGE]${message}`
    await firebase.database().ref()
      .child("logs")
      .push(logMessage)
  } catch (err) {
    console.error(err);
  }
};

export {
    signInWithEmailAndPassword,
    registerWithEmailAndPassword,
    sendPasswordResetEmail,
    saveCare,
    sendPlanToVerification,
    sendPlanToUser,
    saveArticle,
    updateUserRole,
    createUser,
    removeUser,
    updateUserLockedAccountState,
    getUsersCount,
    getUserToken,
    activateRemoteConfig,
    sendAppointmentStatusNotification,
    logEvent,
    getGoogleApiKey
};