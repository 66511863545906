import * as React from "react";
import { Grid, Link, Paper, Typography, Stack, Button, colors } from "@mui/material";
import dayjs from 'dayjs';
import Theme from "../../theme";
import { StatusLabel, StatusLabelType } from "../../views/StatusLabel"
import { db, auth, sendAppointmentStatusNotification, getUserToken, logEvent } from '../../services/firebase';
// import { createMeeting } from '../../services/google';
import { statusLabelType } from "../../utils/extensions";

export default function AppointmentsList({ items }) {

    const isAdmin = ["bgZECbaWftRRgvPmRFbStTNRjtv1", "gOQWiLWMO0bVeahm3iKmI0Vhvny1"].includes(auth.currentUser.uid)

    const handleChange = async ({ appointment, status }) => {
        const isCompletedStatus = status === StatusLabelType.COMPLETED.value.toLowerCase() 
        if (isCompletedStatus && dayjs(appointment.date).format() > dayjs(new Date()).format()) {
            alert("You cannot complete the appointment before it starts")
            return
        }

        const value = { 
            'status': status, 
            'expertApproved': isCompletedStatus
        }

        db.ref().child('experts')
            .child(auth.currentUser.uid)
            .child('appointments')
            .child(appointment.id)
            .update(value)

        db.ref().child('users')
            .child(appointment.userId)
            .child('appointments')
            .child(appointment.id)
            .update(value)

        db.ref().child('appointments')
            .child(appointment.userId)
            .child(appointment.id)
            .update(value)

        // if (status === StatusLabelType.CONFIRMED.value.toLowerCase()) {
        //     createMeeting(
        //         new Date(appointment.date), 
        //         parseInt(appointment.duration), 
        //         [appointment.email, auth.currentUser.email]
        //     )
        // }

        logEvent(`Appointment with identifier: ${appointment.id} for user with identifier: ${appointment.userId} was updated with status: ${status}`)

        if ([
            StatusLabelType.PENDING.value.toLowerCase(),
            StatusLabelType.CONFIRMED.value.toLowerCase(),
            StatusLabelType.CANCELED.value.toLowerCase()
        ].includes(status)) {
            getUserToken(appointment.userId)
                .then((token) => {
                    sendAppointmentStatusNotification(token, "PL", status)
                    logEvent(`Appointment status notification for user with identifier: ${appointment.userId} was send with status: ${status}`)
                })
                .catch((error) => {
                    alert(`Unable to get token with error: ${error}`)
                })
        }
    };

    return (
        <React.Fragment>
            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                {items.map((value, index) => (
                    <Grid item xs={3} sm={1.75} md={3} key={index}>
                        <Paper
                            sx={{
                                paddingX: 3,
                                paddingY: 2,
                                display: 'flex',
                                flexDirection: 'column',
                                opacity: (
                                    value.status === 'pending' || 
                                    value.status === 'confirmed' ||
                                    (value.status === 'completed' && !value.expertApproved && value.userApproved)
                                ) ? 1.0 : 0.5
                            }}
                        >
                            <Stack
                                direction="row"
                                justifyContent="space-between"
                                sx={{ mb: 2 }}
                            >
                                <StatusLabel 
                                    type={(value.status === 'completed' && value.expertApproved && value.userApproved) ? 
                                        StatusLabelType.APPROVED : 
                                        ((value.status === 'completed' && !value.expertApproved && value.userApproved) ? 
                                            StatusLabelType.PENDING_APPROVAL : 
                                            ((value.status === 'completed' && value.expertApproved && !value.userApproved) ? 
                                                StatusLabelType.PENDING_APPROVAL : 
                                                statusLabelType(value.status)
                                            )
                                        )
                                    } 
                                />

                                <Typography color="text.secondary">
                                    {value.duration}
                                </Typography>
                            </Stack>

                            <Typography component="p" variant="h5" fontWeight="medium" color={Theme.palette.darkBrown}>
                                {dayjs(value.date).format('HH:mm')}
                            </Typography>
                            
                            <Typography color="text.secondary" marginTop={1}>
                                {value.userName}
                            </Typography>

                            <Link href={`mailto:${value.email}`}>
                                <Typography color="text.secondary">
                                    {value.email}
                                </Typography>
                            </Link>

                            {(value.status === 'pending' || 
                                value.status === 'confirmed' || 
                                (value.status === 'completed' && !value.expertApproved && value.userApproved)
                            ) ?
                                <Stack
                                    direction="row"
                                    justifyContent="flex-end"
                                    sx={{ mt: 3 }}
                                    spacing={1}
                                >
                                    {isAdmin ? 
                                        <Button
                                            variant="text"
                                            style={{
                                                backgroundColor: 'transparent',
                                                boxShadow: 'none',
                                                textTransform: 'none',
                                                color: colors.grey[500]
                                            }}
                                            onClick={() => handleChange({
                                                appointment: value,  
                                                status: 'pending'
                                            })}
                                        >
                                            PENDING
                                        </Button>
                                        : null
                                    }

                                    <Button
                                        variant="text"
                                        style={{
                                            backgroundColor: 'transparent',
                                            boxShadow: 'none',
                                            textTransform: 'none',
                                            color: value.status === 'pending' ? colors.green[700] : colors.common.black
                                        }}
                                        onClick={() => handleChange({
                                            appointment: value,
                                            status: value.status === 'pending' ? 'confirmed' : 'completed'
                                        })}
                                    >
                                        {value.status === 'pending' ? "CONFIRM" : "COMPLETE"}
                                    </Button>

                                    <Button
                                        variant="text"
                                        style={{
                                            backgroundColor: 'transparent',
                                            boxShadow: 'none',
                                            textTransform: 'none',
                                            color: Theme.palette.error.main
                                        }}
                                        onClick={() => handleChange({
                                            appointment: value,
                                            status: 'canceled'
                                        })}
                                    >
                                        CANCEL
                                    </Button>
                                </Stack>
                            : null
                            }
                        </Paper>
                    </Grid>
                ))}
            </Grid>
        </React.Fragment>
    );
}
