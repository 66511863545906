import * as React from 'react';
import Theme from '../../theme'
import { Grid, Paper, Typography, Stack, Button } from '@mui/material';
import { Box } from '@mui/system';
import RecentItems from '../../modules/dashboard/RecentItems';
import Stats from '../../modules/dashboard/Stats';
import CaresList from './CaresList';
import Add from '@mui/icons-material/Add';
import { db } from '../../services/firebase';
import AlertDialog from '../../views/AlertDialog';

export default function Cares({ cares, user, onClick }) {

  // MARK: - Remove Care Dialog

  const [openDialog, setOpenDialog] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState("");

  const handleOpenDialog = (care) => {
    setSelectedValue(care);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleRemoveCare = () => {
    setOpenDialog(false);
    removeCare();
  };

  const removeCare = () => {
    if (user.role == "Admin") {
      const pathElement = selectedValue.isBasicCare ? 'basic' : 'premium';
      db.ref().child('cares').child(pathElement).child(selectedValue.id).remove()
    } else if (user.role == "Expert") {
      db.ref().child('experts').child(user.id).child('cares').child(selectedValue.id).remove()
    } else {
      alert("You don't have a permission to remove this care. Please contact the administrator.")
    }
  }

  return (
    <Box sx={{ backgroundColor: Theme.palette.background.lightGray }}>
        <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ mx: 14, mt: 10 }}
        >
            <Typography variant="h4" fontWeight="medium">
                Cares
            </Typography>

            <Button
                type="create"
                variant="contained"
                startIcon={<Add />}
                onClick={() => {
                    onClick()
                }}
            >
                New Care
            </Button>
        </Stack>

        <Box 
          display="flex" 
          sx={{
            flexWrap: 'wrap',
            mb: 4, 
            mt: 6,
            mx: 14,
            backgroundColor: Theme.palette.background.lightGray
          }}
        >
          <AlertDialog
            selectedValue={selectedValue}
            open={openDialog}
            onClose={handleCloseDialog}
            onAction={handleRemoveCare}
            title="Confirm care deletion"
            message={`Are you sure you want to delete care "${selectedValue.title}"?`}
            buttonTitle="Yes, delete"
            isDestructive={true}
          />

          <Grid container spacing={3}>
            <Grid item xs={12} md={8} lg={9}>
              <Paper
                sx={{
                  p: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  height: '100%'
                }}
              >
                <RecentItems 
                  items={cares} 
                  title={'Recent Cares'}
                />
              </Paper>
            </Grid>

            <Grid item xs={12} md={4} lg={3}>
              <Paper
                sx={{
                  p: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  height: '100%'
                }}
              >
                <Stats 
                  items={cares} 
                  title="Summary"
                  description="cares in database"
                />
              </Paper>
            </Grid>

            <Grid item xs={12}>
              <Paper 
                sx={{ 
                  p: 2, 
                  display: 'flex', 
                  flexDirection: 'column' 
                  }}
              >
                <CaresList 
                  items={cares} 
                  title={'Cares list'}
                  sendOnEdit={ onClick } 
                  sendOnRemove={ handleOpenDialog }
                />
              </Paper>
            </Grid>
        </Grid>
      </Box>
    </Box>
  );

}