import * as React from "react";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Theme from "../../../theme";
import { Grid, Stack, TextField, colors } from "@mui/material";
import NewPlanCares from "./NewPlanCares";
import NewPlanDetails from "./NewPlanDetails";
import LoadingButton from "@mui/lab/LoadingButton";
import UploadIcon from "@mui/icons-material/Upload";
import { useState } from "react";
import { db, auth, sendPlanToUser, sendPlanToVerification, getUserToken, logEvent } from "../../../services/firebase";
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import NewPlanSupplementation from "./NewPlanSupplementation";
import HairCarePlan from "../../../models/plan/HairCarePlan";
import User from "../../../models/User";
import { StatusLabelType } from "../../../views/StatusLabel";

const steps = ['Basics', 'Cares', 'Supplementation'];

export default function NewPlanContainer({ editedPlan, cares, user, onClose }) {

  const [activeStep, setActiveStep] = useState(0)
  const [plan, setPlan] = useState(editedPlan ?? new HairCarePlan());
  const [uploading, setUploading] = useState(false);
  const [uploadable, setUploadable] = useState(false);
  const [uploaded, setUploaded] = useState(false);
  const [selectedUser, setSelectedUser] = useState(new User());

  function detailsCallback(childData) {
    const newPlan = plan;
    newPlan.name = childData.name;
    newPlan.startsAt = childData.startsAt;
    newPlan.endsAt = childData.endsAt;
    newPlan.userId = childData.userId;
    newPlan.notes = childData.notes;
    setPlan(newPlan);
    checkUploadable();
  }

  function caresCallback(cares) {
    const newPlan = plan;
    newPlan.cares = cares;
    setPlan(newPlan);
    checkUploadable();
  }

  function supplementsCallback(childData) {
    const newPlan = plan;
    newPlan.products = childData.products;
    newPlan.productNotes = childData.productNotes;
    newPlan.supplements = childData.supplements;
    newPlan.supplementNotes = childData.supplementNotes;
    newPlan.treatments = childData.treatments;
    setPlan(newPlan);
    checkUploadable();
  }

  function onUserUpdate(user) {
    setSelectedUser(user)
  }

  const checkUploadable = () => {
    const isUploadable =
      plan.name != "" &&
      plan.startsAt != "" &&
      plan.endsAt != "" &&
      plan.userId != "" &&
      plan.cares.length != 0;
    setUploadable(isUploadable);
  };

  const showMissingValuesAlert = () => {
    let values = [["Name", plan.name], ["Starts at", plan.startsAt], ["Ends at", plan.endsAt], ["User ID", plan.userId]]
    let value = values.filter((value) => 
      (value[1] === "" || value[1] === null || value[1] === undefined)
    ).map((value) => `• ${value[0]}`).join(",\n")
    alert(`All required values should be filled out:\n\n${value}`)
  }

  const handleNext = () => {
    if (activeStep === steps.length - 1) {
      if (plan.cares.length > 0) {
        checkUploadable()
        if (uploadable == false) {
          showMissingValuesAlert()
        } else {
          setUploading(true);

          plan.createdAt = (new Date()).toISOString().replace(/.\d+Z$/g, "Z");
          plan.expertId = (editedPlan.expertId === "" || editedPlan.expertId === null || editedPlan.expertId === undefined) ? user.id : editedPlan.expertId;
          plan.startsAt = plan.startsAt.toISOString().replace(/.\d+Z$/g, "Z");
          plan.endsAt = plan.endsAt.toISOString().replace(/.\d+Z$/g, "Z");
  
          setTimeout(() => {
            setUploaded(true);
            sendPlanToVerification(plan, user);
            logEvent(`HCP for user with identifier: ${plan.userId} by expert: ${user.id} was send to verification.`)
          }, 10);
        }
      } else {
        alert("You should add at least one care to upload a plan.")
      }
    } else {
      setActiveStep(activeStep + 1);
    }
  };

  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep(activeStep - 1);
    }
  };

  const handleCancel = () => {
      if (selectedUser.id !== "" && selectedUser.id !== null && selectedUser.id !== undefined) {
        alert(selectedUser.id)
        db.ref()
          .child(`hairCarePlanInfo/${selectedUser.id}`)
          .set(selectedUser.id)
      }
      
      onClose()
  };

  const handleVerify = () => {
    plan.expertId = (editedPlan.expertId === "" || editedPlan.expertId === null || editedPlan.expertId === undefined) ? user.id : editedPlan.expertId;
    plan.status = StatusLabelType.CONFIRMED.value.toLowerCase();

    checkUploadable()
    if (uploadable == false) {
      showMissingValuesAlert()
    } else {
      setTimeout(() => {
        setUploaded(true);
        getUserToken(plan.userId)
          .then((token) => {
            sendPlanToUser(plan, token);
            logEvent(`HCP for user with identifier: ${plan.userId} was send verified and send to user.`)
          })
          .catch((error) => {
              alert(`Unable to get token with error: ${error}`)
          })
      }, 10);
    }
  };

  // MARK: - Views

  return (
    <ThemeProvider theme={Theme}>
      <CssBaseline />
      {uploaded ? (
        <Container component="main" maxWidth="sm" sx={{ mt: 4, mb: 4 }}>
          <React.Fragment>
            <Typography variant="h6" gutterBottom fontWeight="bold">
              Plan was created succesfully!
            </Typography>
            <Typography variant="subtitle1">
              You can check its status in the main panel and preview all the
              statistics there.
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <Button
                variant="contained"
                sx={{ mt: 4 }}
                onClick={() => {
                  onClose();
                }}
              >
                Close
              </Button>
            </Box>
          </React.Fragment>
        </Container>
      ) : (
        <Container component="main" maxWidth="sm" sx={{ mt: 4, mb: 4 }}>
          <HeaderView activeStep={activeStep}/>

          <React.Fragment>
            {activeStep == 0 ?
              <NewPlanDetails 
                plan={plan} 
                parentCallback={detailsCallback} 
                onUserUpdate={onUserUpdate}
              /> :
              activeStep == 1 ?
                <NewPlanCares
                  cares={cares}
                  caresInPlan={plan.cares}
                  user={user}
                  onCaresChange={caresCallback}
                /> :
                <NewPlanSupplementation 
                  plan={plan}
                  parentCallback={supplementsCallback} 
                />
            }

            <FooterView
              activeStep={activeStep}
              handleNext={handleNext}
              handleBack={handleBack}
              handleCancel={handleCancel}
              handleVerify={handleVerify}
              uploadable={uploadable}
              uploading={uploading}
            />
          </React.Fragment>
        </Container>
      )}
    </ThemeProvider>
  );
}

export function HeaderView({ activeStep }) {
  return (
    <React.Fragment>
      <Typography component="h2" variant="h5" align="center" fontWeight="bold">
        NEW PLAN
      </Typography>

      <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5, mx: 12 }}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </React.Fragment>
  )
}

export function FooterView({ activeStep, handleNext, handleBack, handleCancel, handleVerify, uploadable, uploading }) {

  const isEditor = ["bgZECbaWftRRgvPmRFbStTNRjtv1", "gOQWiLWMO0bVeahm3iKmI0Vhvny1"].includes(auth.currentUser.uid)

  return (
    <React.Fragment>
      <Stack
        display="flex"
        direction="row"
        justifyContent="space-between"
      >
        <Button variant="outlined" color="error" onClick={handleCancel} sx={{ mt: 3 }}>
          Cancel
        </Button>

        <Stack
          display="flex"
          direction="row"
          justifyContent="flex-end"
        >
          {activeStep !== 0 && (
            <Button variant="outlined" onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
              Back
            </Button>
          )}

          {activeStep < steps.length - 1 ?
            <Button
              variant={isEditor ? "outlined" : "contained"}
              onClick={handleNext}
              sx={{ mt: 3, ml: 1 }}
            >
              Next
            </Button> :
            (isEditor ? null : 
              <LoadingButton
                variant="contained"
                onClick={handleNext}
                sx={{ mt: 3, ml: 1 }}
                disabled={!uploadable}
                loading={uploading}
                loadingPosition="start"
                startIcon={<UploadIcon />}
              >
                Upload
              </LoadingButton>
            )
          }

          {isEditor ?
            <Button 
              variant="contained" 
              color="primary" 
              onClick={handleVerify} 
              sx={{ mt: 3, ml: 1 }}
            >
              Approve
            </Button>
            : null
          }
        </Stack>
      </Stack>
    </React.Fragment>
  )
}